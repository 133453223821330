<template>
    <div class="homes">
        <div class="oneTitleBox">
            <div class="oneTitle">
                <div class="icon">
                    <img class="titleImg" src="../assets/icon/area.png" />
                </div>
                城市经济
            </div>
        </div>
        <!-- 图表区域 -->
        <div class="gdpBox">
            <div id="gpd" style="width: 100%; height: 250px"></div>
        </div>
        <div class="industry">
            <div class="industry_title">三产结构</div>
            <div class="industryBox">
                <div class="box-head">
                    <div class="pocket">
                        <div class="pocketb_box">
                            <img
                                src="../assets/economy_icon/gdp.png"
                                style="width: 100%; height: 100%"
                            />
                            <div
                                style="
                                    font-size: 12px;
                                    position: relative;
                                    top: -35px;
                                    left: 43px;
                                    color: #ffffff;
                                "
                            >
                                {{ this.indurstry.latest_Q_GDP_value }}亿元
                            </div>
                            <div
                                style="
                                    width: 41px;
                                    text-align: center;
                                    font-size: 12px;
                                    color: #29283b;
                                    position: relative;
                                    top: -95px;
                                    left: 101px;
                                "
                                :class="[
                                    this.indurstry.latest_Q_GDP_rate > 0
                                        ? 'red'
                                        : 'green',
                                ]"
                            >
                                {{
                                    this.indurstry.latest_Q_GDP_rate > 0
                                        ? this.indurstry.latest_Q_GDP_rate +
                                          "%↑"
                                        : this.indurstry.latest_Q_GDP_rate +
                                          "%↓"
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="property">
                        <div class="property_box">
                            <div class="property_img">
                                <img
                                    src="../assets/economy_icon/first.png"
                                    style="width: 22.5px; height: 22.5px"
                                />
                            </div>
                            <div class="property_text">
                                <div
                                    style="
                                        font-size: 12px;
                                        color: #9593b2;
                                        padding-top: 3px;
                                    "
                                >
                                    第一产业
                                </div>
                                <div
                                    style="
                                        display: flex;
                                        margin-top: 2.5px;
                                        margin-bottom: 3px;
                                        justify-content: space-between;
                                    "
                                >
                                    <div
                                        style="font-size: 12px; color: #ffffff"
                                    >
                                        {{
                                            this.indurstry
                                                .fisrt_indurstry_value
                                        }}亿元
                                    </div>
                                    <div
                                        style="
                                            font-size: 13px;
                                            color: #ffffff;
                                            margin-right: 10px;
                                        "
                                        :class="[
                                            this.indurstry
                                                .fisrt_indurstry_rate > 0
                                                ? 'red'
                                                : 'green',
                                        ]"
                                    >
                                        {{
                                            this.indurstry
                                                .fisrt_indurstry_rate > 0
                                                ? this.indurstry
                                                      .fisrt_indurstry_rate +
                                                  "%↑"
                                                : this.indurstry
                                                      .fisrt_indurstry_rate +
                                                  "%↓"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="property_box2">
                            <div class="property_img">
                                <img
                                    src="../assets/economy_icon/second.png"
                                    style="width: 22.5px; height: 22.5px"
                                />
                            </div>
                            <div class="property_text">
                                <div
                                    style="
                                        font-size: 12px;
                                        color: #9593b2;
                                        padding-top: 3px;
                                    "
                                >
                                    第二产业
                                </div>
                                <div
                                    style="
                                        display: flex;
                                        margin-top: 2.5px;
                                        margin-bottom: 3px;
                                        justify-content: space-between;
                                    "
                                >
                                    <div
                                        style="font-size: 12px; color: #ffffff"
                                    >
                                        {{
                                            this.indurstry
                                                .second_indurstry_value
                                        }}亿元
                                    </div>
                                    <div
                                        style="
                                            font-size: 13px;
                                            color: #ffffff;
                                            margin-right: 10px;
                                        "
                                        :class="[
                                            this.indurstry
                                                .second_indurstry_rate > 0
                                                ? 'red'
                                                : 'green',
                                        ]"
                                    >
                                        {{
                                            this.indurstry
                                                .second_indurstry_rate > 0
                                                ? this.indurstry
                                                      .second_indurstry_rate +
                                                  "%↑"
                                                : this.indurstry
                                                      .second_indurstry_rate +
                                                  "%↓"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="property_box2">
                            <div class="property_img">
                                <img
                                    src="../assets/economy_icon/third.png"
                                    style="width: 22.5px; height: 22.5px"
                                />
                            </div>
                            <div class="property_text">
                                <div
                                    style="
                                        font-size: 12px;
                                        color: #9593b2;
                                        padding-top: 3px;
                                    "
                                >
                                    第三产业
                                </div>
                                <div
                                    style="
                                        display: flex;
                                        margin-top: 2.5px;
                                        margin-bottom: 3px;
                                        justify-content: space-between;
                                    "
                                >
                                    <div
                                        style="font-size: 12px; color: #ffffff"
                                    >
                                        {{
                                            this.indurstry
                                                .third_indurstry_value
                                        }}亿元
                                    </div>
                                    <div
                                        style="
                                            font-size: 13px;
                                            color: #ffffff;
                                            margin-right: 10px;
                                        "
                                        :class="[
                                            this.indurstry
                                                .third_indurstry_rate > 0
                                                ? 'red'
                                                : 'green',
                                        ]"
                                    >
                                        {{
                                            this.indurstry
                                                .third_indurstry_rate > 0
                                                ? this.indurstry
                                                      .third_indurstry_rate +
                                                  "%↑"
                                                : this.indurstry
                                                      .third_indurstry_rate +
                                                  "%↓"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-body">
                    <div class="income-box">
                        <div class="icome-img">
                            <img
                                src="../assets/economy_icon/income.png"
                                style="width: 32px; height: 32px"
                            />
                        </div>
                        <div
                            style="
                                flex: 1;
                                display: flex;
                                justify-content: space-between;
                                height: 65px;
                            "
                        >
                            <div class="income-text">
                                <div
                                    style="
                                        font-size: 12px;
                                        color: #ffffff;
                                        margin-top: 16px;
                                        line-height: 12px;
                                    "
                                >
                                    城镇居民人均可支配收入
                                </div>
                                <div style="display: flex; margin-top: 10px">
                                    <div
                                        style="
                                            font-size: 15px;
                                            color: #ffffff;
                                            line-height: 15px;
                                        "
                                    >
                                        {{ this.indurstry.towner_value }}元
                                    </div>
                                    <div
                                        style="
                                            font-size: 12px;
                                            color: rgba(210, 209, 223, 0.5);
                                            margin-top: 3px;
                                            margin-left: 10px;
                                            line-height: 12px;
                                        "
                                    >
                                        {{ this.indurstry.quarterName }}
                                    </div>
                                </div>
                            </div>
                            <div
                                style="
                                    margin-right: 14px;
                                    align-items: center;
                                    display: flex;
                                    flex-direction: column;
                                "
                            >
                                <div
                                    style="
                                        font-size: 13px;
                                        color: #ffffff;
                                        margin-top: 20px;
                                    "
                                    :class="[
                                        this.indurstry.towner_rate > 0
                                            ? 'red'
                                            : 'green',
                                    ]"
                                >
                                    {{
                                        this.indurstry.towner_rate > 0
                                            ? this.indurstry.towner_rate + "%↑"
                                            : this.indurstry.towner_rate + "%↓"
                                    }}
                                </div>
                                <div style="font-size: 12px; color: #d2d1df">
                                    同比
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="income-box2">
                        <div class="icome-img">
                            <img
                                src="../assets/economy_icon/countryside.png"
                                style="width: 32px; height: 32px"
                            />
                        </div>
                        <div
                            style="
                                flex: 1;
                                display: flex;
                                justify-content: space-between;
                                height: 65px;
                            "
                        >
                            <div class="income-text">
                                <div
                                    style="
                                        font-size: 12px;
                                        color: #ffffff;
                                        margin-top: 16px;
                                        line-height: 12px;
                                    "
                                >
                                    农村居民人均可支配收入
                                </div>
                                <div style="display: flex; margin-top: 10px">
                                    <div
                                        style="font-size: 15px; color: #ffffff"
                                    >
                                        {{
                                            this.indurstry.rural_resident_value
                                        }}元
                                    </div>
                                    <div
                                        style="
                                            font-size: 12px;
                                            color: rgba(210, 209, 223, 0.5);
                                            align-self: flex-end;
                                            margin-left: 10px;
                                        "
                                    >
                                        {{ this.indurstry.quarterName }}
                                    </div>
                                </div>
                            </div>
                            <div
                                style="
                                    margin-right: 14px;
                                    align-items: center;
                                    display: flex;
                                    flex-direction: column;
                                "
                            >
                                <div
                                    style="
                                        font-size: 13px;
                                        color: #ffffff;
                                        margin-top: 20px;
                                    "
                                    :class="[
                                        this.indurstry.rural_resident_rate > 0
                                            ? 'red'
                                            : 'green',
                                    ]"
                                >
                                    {{
                                        this.indurstry.rural_resident_rate > 0
                                            ? this.indurstry
                                                  .rural_resident_rate + "%↑"
                                            : this.indurstry
                                                  .rural_resident_rate + "%↓"
                                    }}
                                </div>
                                <div style="font-size: 12px; color: #d2d1df">
                                    同比
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="scatter">
            <div id="gdpScatter" style="width: 100%; height: 260px"></div>
        </div>
        <div class="investmentes">
            <div id="investments" style="width: 100%; height: 260px"></div>
        </div>
        <div class="retail">
            <div id="retails" style="width: 100%; height: 220px"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import { color } from "echarts";
import { post } from "../config/request.js";
export default {
    data() {
        return {
            analysisId: "1",
            //固定资产图数据
            fixedAssetAndEstate: [],
            //gdp图表数据
            gdp: [],

            //三产结构数据
            indurstry: {},

            //重点城市散点图表数据
            imCityList: [], //重点城市散点图数据
            sumYear: "", //图表数据时间单位年
            importantCityGDP: "", //X轴数据[x，y]
            cdGDP: "", //Y轴数据
            cityList: [], //过度数组
            importantList: [], //最后的数据二维数组
            currentCity: {
                value: [],
                itemStyle: {
                    color: "#E42AB2",
                },
                label: {
                    show: true,
                    position: "top",
                    color: "#C2CBFF",
                    formatter: "",
                },
            },

            //社会消费品零售总额图标数据
            retailSalesData: [],
        };
    },
    mounted() {
        this.analysisId = window.GetAreaInfo("id");
        setTimeout((e) => {
            document.getElementById("economyBox").style.height = "auto";
        }, 500);
        var that = this;
        post("/content/city_economy", { analysisId: this.analysisId }).then(
            (res) => {
                console.log(res);
                //获取gpd图表数组                
                that.gdp = res.data.GDP;

                //获取三产结构图表数据
                that.indurstry = res.data.indurstry;

                //获取重点城市散点图表数据
                that.imCityList = res.data.importantCityGDPandPersonalGDP;
                // for (
                //   var i = 0;
                //   i < res.data.importantCityGDPandPersonalGDP.length;
                //   i++
                // ) {
                //   if (res.data.importantCityGDPandPersonalGDP[i].isCurrentCity == 0) {
                //     that.cityList.push(res.data.importantCityGDPandPersonalGDP[i].gdp);
                //     that.cityList.push(
                //       res.data.importantCityGDPandPersonalGDP[i].perGDP
                //     );
                //     that.importantList.push(that.cityList);
                //     that.cityList = [];
                //   } else {
                //     that.currentCity.value.push(
                //       res.data.importantCityGDPandPersonalGDP[i].gdp
                //     );
                //     that.currentCity.value.push(
                //       res.data.importantCityGDPandPersonalGDP[i].perGDP
                //     );
                //     that.currentCity.label.formatter =
                //       res.data.importantCityGDPandPersonalGDP[i].name;
                //     that.importantList.push(that.currentCity);
                //   }
                // }
                //重点城市散点图
                that.sumYear =
                    "(" +
                    res.data.importantCityGDPandPersonalGDP[0].yearName +
                    ")"; //标题年份

                //获取固定资产投资与房地产投资
                that.fixedAssetAndEstate = res.data.fixedAssetAndEstate;

                //获取社会消费品零售总额
                that.retailSalesData = res.data.retailSales;

                //调用图表方法
                that.getGdpChart();
                that.getScatterCharts();
                that.getInvestmentChart();
                that.getRetailChart();
            }
        );
    },
    methods: {
        //获取GDP图表
        getGdpChart() {
            var that = this;
            var chartDom = document.getElementById("gpd");
            var myChart = echarts.init(chartDom);
            // that.gdpValue.push(res.data.gdp[i].GDP_value);
            //     that.gdpRate.push(res.data.gdp[i].GDP_rate);
            var leftY = window.GetInterval(that.gdp.map((i) => i.GDP_value));
            var rightY = window.GetInterval(that.gdp.map((i) => i.GDP_rate));
            var option;
            option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#999",
                        },
                    },
                },
                title: {
                    text: "GDP及增速",
                    textStyle: {
                        color: "#FEFEFF",
                        fontSize: 14,
                        fontWeight: "bolder",
                    },
                    y: "10",
                    x: "10",
                },
                // backgroundColor: '#3D3194',
                grid: {
                    top: "25%",
                    left: "15%",
                    right: "8%",
                    bottom: "29",
                    containLable: true,
                },
                legend: {
                    data: ["GDP(亿元)", "增速(%)"],
                    right: "9",
                    top: "20",
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        color: "#C2CBFF",
                        fontSize: "9",
                    },
                },
                xAxis: [
                    {
                        type: "category",
                        // data: ["2012年", "2013年", "2014年", "2015年", "2016年"],
                        data: that.gdp.map((i) => i.GDP_sum_date),
                        axisLine: {
                            lineStyle: {
                                color: "#3B2183",
                            },
                        },
                        axisPointer: {
                            type: "shadow",
                        },
                        axisLabel: {
                            // color:'#FEFEFF',
                            color: "#423C9B",
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "",
                        min: leftY.min,
                        max: leftY.max,
                        interval: leftY.interval,
                        axisLabel: {
                            // margin: 40,
                            formatter: "{value} ",
                            // align: "left",
                            // baseline: "middle",
                            color: "#423C9B",
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ["#3B2183"], //网格线颜色
                                width: 1,
                            },
                        },
                    },
                    {
                        type: "value",
                        name: "",
                        min: rightY.min,
                        max: rightY.max,
                        interval: rightY.interval,
                        axisLabel: {
                            formatter: "{value}",
                            color: "#423C9B",
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: ["#3B2183"], //网格线颜色
                                width: 1,
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "GDP(亿元)",
                        type: "bar",
                        // data: [11000, 9114.8, 9840.3, 9925.8, 12114.8],
                        data: that.gdp.map((i) => i.GDP_value),
                        itemStyle: {
                            color: "#2C33B3", //柱子颜色
                        },
                        label: {
                            show: true,
                            position: "top", //头上显示数据
                            color: "#C2CBFF", //显示文字颜色
                        },
                    },
                    {
                        name: "增速(%)",
                        type: "line",
                        yAxisIndex: 1,
                        // data: [8.7, 8.0, 8.9, 8.1, 8.1],
                        data: that.gdp.map((i) => i.GDP_rate),
                        itemStyle: {
                            color: "#7C35D5", //柱子颜色
                        },
                        // label: {
                        //   show: true,
                        //   position: "top", //头上显示数据
                        //   color: "#C2CBFF", //显示文字颜色
                        // },
                    },
                ],
            };

            option && myChart.setOption(option);
        },
        //获取固定投资图表
        getInvestmentChart() {
            var that = this;
            var chartDom = document.getElementById("investments");
            var myChart = echarts.init(chartDom);
            var leftY = window.GetInterval(that.fixedAssetAndEstate, [
                "fixedAsset",
                "estate",
            ]);
            var investment;
            investment = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#999",
                        },
                    },
                },
                // backgroundColor: '#3D3194',
                title: {
                    text: "固定资产投资与房地产投资同比累计增幅",
                    textStyle: {
                        color: "#FEFEFF",
                        fontSize: 14,
                        fontWeight: "bolder",
                    },
                    y: "15",
                    x: "10",
                },
                grid: {
                    top: "31%",
                    left: "40",
                    right: "29",
                    bottom: "29",
                },
                legend: {
                    data: ["固定资产投资(%)", "房地产开发投资(%)"],
                    y: "50",
                    right: "5",
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        color: "#C2CBFF",
                        fontSize: "9",
                    },
                },
                xAxis: [
                    {
                        type: "category",
                        data: that.fixedAssetAndEstate.map((i) => i.monthName),
                        axisLine: {
                            lineStyle: {
                                color: "#3B2183",
                            },
                        },
                        axisPointer: {
                            type: "shadow",
                        },
                        axisLabel: {
                            color: "#423C9B", //X轴上字体颜色
                            // color:'#423C9B'
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "",
                        min: leftY.min,
                        max: leftY.max,
                        interval: leftY.interval,
                        axisLabel: {
                            formatter: "{value} ",
                            color: "#423C9B",
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ["#3B2183"], //网格线颜色
                                width: 1,
                            },
                        },
                    },
                    {
                        type: "value",
                        name: "",

                        // interval: 1,
                        axisLabel: {
                            formatter: "{value} ",
                            color: "#423C9B",
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ["#3B2183"], //网格线颜色
                                width: 1,
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "固定资产投资(%)",
                        type: "line",
                        data: that.fixedAssetAndEstate.map((i) => i.fixedAsset),
                        itemStyle: {
                            color: "#2C33B3", //柱子颜色
                        },
                    },
                    {
                        name: "房地产开发投资(%)",
                        type: "line",
                        data: that.fixedAssetAndEstate.map((i) => i.estate),
                        itemStyle: {
                            color: "#6815C1", //柱子颜色
                        },
                    },
                ],
            };
            investment && myChart.setOption(investment);
        },
        //获取零售总额图表
        getRetailChart() {
            var that = this;
            var chartDom = document.getElementById("retails");
            var myChart = echarts.init(chartDom);
            var leftY = window.GetInterval(
                that.retailSalesData.map((i) => i.total_retail_sales)
            );
            var rightY = window.GetInterval(
                that.retailSalesData.map((i) => i.total_retail_sales_rate)
            );
            var retail;

            retail = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#999",
                        },
                    },
                },
                // backgroundColor: '#3D3194',
                title: {
                    text: "社会消费品零售总额",
                    textStyle: {
                        color: "#FEFEFF",
                        fontSize: 14,
                        fontWeight: "bolder",
                    },
                    y: "15",
                    x: "10",
                },
                grid: {
                    top: "80",
                    left: "63",
                    right: "30",
                    bottom: "29",
                },
                legend: {
                    data: ["总额(亿元)", "增速(%)"],
                    y: "38",
                    right: "5",
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        color: "#C2CBFF",
                        fontSize: "9",
                    },
                },
                xAxis: [
                    {
                        type: "category",
                        data: that.retailSalesData.map(
                            (i) => i.retail_sales_sum_date
                        ),
                        axisLine: {
                            lineStyle: {
                                color: "#3B2183",
                            },
                        },
                        axisPointer: {
                            type: "shadow",
                        },
                        axisLabel: {
                            color: "#423C9B", //X轴上字体颜色
                            // color:'#423C9B'
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "",
                        min: leftY.min,
                        max: leftY.max,
                        interval: leftY.interval,
                        axisLabel: {
                            formatter: "{value} ",
                            color: "#423C9B",
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ["#3B2183"], //网格线颜色
                                width: 1,
                            },
                        },
                    },
                    {
                        type: "value",
                        name: "",
                        // min: 3,
                        // max: this.maxRetailSalesRate,
                        // interval: 1,
                        min: rightY.min,
                        max: rightY.max,
                        interval: rightY.interval,
                        axisLabel: {
                            formatter: "{value} ",
                            color: "#423C9B",
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: ["#3B2183"], //网格线颜色
                                width: 1,
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "总额(亿元)",
                        type: "bar",
                        data: that.retailSalesData.map(
                            (i) => i.total_retail_sales
                        ),
                        itemStyle: {
                            color: "#2C33B3", //柱子颜色
                        },
                        label: {
                            show: false,
                            position: "top", //头上显示数据
                            color: "#C2CBFF", //显示文字颜色
                        },
                    },
                    {
                        name: "增速(%)",
                        type: "line",
                        yAxisIndex: 1,
                        data: that.retailSalesData.map(
                            (i) => i.total_retail_sales_rate
                        ),
                        itemStyle: {
                            color: "#7C35D5", //柱子颜色
                        },
                        label: {
                            show: true,
                            position: "top", //头上显示数据
                            color: "#C2CBFF", //显示文字颜色
                        },
                    },
                ],
            };

            retail && myChart.setOption(retail);
        },
        //获取重点城市GDP散点图
        getScatterCharts() {
            var chartDom = document.getElementById("gdpScatter");
            var myChart = echarts.init(chartDom);
            var gdpScatter;
            var gdpData = this.imCityList.map((i) => {
                return [i.perGDP, i.gdp, i.name];
            });
            var city = { name: "成都市" };
            var cityIndex = (this.imCityList || []).findIndex(
                (cityNum) => cityNum.name == city.name
            );
            gdpData[cityIndex] = {
                value: gdpData[cityIndex],
                itemStyle: {
                    color: "#E42AB2",
                },
            };
            console.log(gdpData);

            gdpScatter = {
                title: [
                    {
                        text: "重点城市GDP及人均GDP",
                        textStyle: {
                            color: "#FEFEFF",
                            fontSize: 14,
                            fontWeight: "bolder",
                        },
                        y: "15",
                        x: "10",
                    },
                    {
                        text: this.sumYear,
                        left: 177,
                        textStyle: {
                            color: "#56507A",
                            fontSize: 12,
                            fontWeight: "bolder",
                        },
                        y: "15",
                        x: "10",
                    },
                ],
                grid: {
                    top: "28%",
                    left: "65",
                    right: "30",
                    bottom: "40",
                },
                xAxis: {
                    type: "value",
                    min: 0,
                    name: "GDP(亿元)", //x轴的名称,
                    nameLocation: "center",
                    nameGap: "20",
                    nameTextStyle: {
                        color: "#423C9B",
                        fontSize: 9,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#3B2183",
                        },
                    },
                    axisLabel: {
                        color: "#423C9B",
                        // color:'#423C9B'
                    },
                },
                yAxis: {
                    type: "value",
                    min: 0,
                    name: "人均GDP (元)", //x轴的名称
                    nameTextStyle: {
                        color: "#423C9B",
                        fontSize: 9,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#3B2183",
                        },
                    },
                    axisLabel: {
                        color: "#423C9B",
                        // color:'#423C9B'
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#3B2183",
                        },
                    },
                },
                tooltip: {
                    // position: "top",
                    // trigger: "axis",
                    // axisPointer: {
                    //   type: "shadow",
                    // },
                    showDelay: 0,
                    formatter: function (params) {
                        return (
                            params.value[2] +
                            " :<br/>" +
                            "人均GDP(元) : " +
                            params.value[0] +
                            "<br/>" +
                            "城市GDP(亿元) :  " +
                            params.value[1]
                        );
                    },
                    axisPointer: {
                        show: true,
                        type: "cross",
                        lineStyle: {
                            type: "dashed",
                            width: 1,
                        },
                    },
                },
                series: [
                    {
                        symbolSize: 7,
                        data: gdpData,
                        type: "effectScatter",
                    },
                ],
            };

            gdpScatter && myChart.setOption(gdpScatter);
            setTimeout(function () {
                myChart.dispatchAction({
                    type: "showTip",
                    seriesIndex: 0, // 显示第几个series
                    dataIndex: cityIndex, // 显示第几个数据
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.homes {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #0f0240;
    align-items: center;
    .gdpBox {
        width: 92%;
        height: 250px;
        background: rgba(61, 49, 148, 0.3);
        box-shadow: -2px 6px 9px 0px rgba(23, 30, 74, 0.06);
        border-radius: 4px;
    }
    .industry {
        width: 92%;
        height: 388.5px;
        background: rgba(61, 49, 148, 0.3);
        box-shadow: -2px 6px 9px 0px rgba(23, 30, 74, 0.06);
        border-radius: 4px;
        margin-top: 10px;
        .industry_title {
            font-size: 14px;
            font-weight: bold;
            color: #fefeff;
            margin-top: 19.5px;
            margin-left: 14.5px;
            line-height: 14px;
        }
        .industryBox {
            margin-top: 14px;
            width: 100%;
            .box-head {
                width: 100%;
                height: 162px;
                display: flex;
                // justify-content: space-between;
                .pocket {
                    width: 162px;
                    height: 162px;
                    margin-left: 1.5px;
                }
                .property {
                    // width: 152.5px;
                    flex: 1;
                    margin-right: 14.5px;
                }
                .property_box {
                    width: 90%;
                    height: 41.5px;
                    display: flex;
                    align-items: center;
                    margin-left: 17.5px;
                    margin-top: 7px;
                    // padding-right: 17px;
                    .property_img {
                        margin-top: 4px;
                    }
                    .property_text {
                        margin-left: 10px;
                        background: linear-gradient(
                            to right,
                            rgba(44, 51, 179, 0),
                            #2b2da1
                        );
                        flex: 1;
                    }
                }
                .property_box2 {
                    width: 90%;
                    height: 41.5px;
                    display: flex;
                    align-items: center;
                    margin-left: 17.5px;
                    margin-top: 7px;
                    // padding-right: 17px;
                    .property_img {
                        margin-top: 4px;
                    }
                    .property_text {
                        margin-left: 10px;
                        background: linear-gradient(
                            to right,
                            rgba(44, 51, 179, 0),
                            #2b2da1
                        );
                        flex: 1;
                    }
                }
            }
            .box-body {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-top: 7.5px;
                .income-box {
                    // width: 315.8px;
                    width: 92%;
                    height: 65px;
                    background: #3f3896;
                    align-items: center;
                    display: flex;
                    .icome-img {
                        margin-left: 11px;
                        margin-right: 14px;
                        // margin-top: 19px;
                    }
                    .icome-text {
                        display: flex;
                    }
                }
                .income-box2 {
                    // width: 315.8px;
                    width: 92%;
                    height: 65px;
                    background: #3f3896;
                    align-items: center;
                    display: flex;
                    margin-top: 10px;
                    margin-bottom: 31px;
                    .icome-img {
                        margin-left: 11px;
                        margin-right: 14px;
                        // margin-top: 19px;
                    }
                    .icome-text {
                        display: flex;
                    }
                }
            }
        }
    }
    .investmentes {
        width: 92%;
        height: 260px;
        border-radius: 4px;
        background: rgba(61, 49, 148, 0.3);
        box-shadow: -2px 6px 9px 0px rgba(23, 30, 74, 0.06);
        margin-bottom: 10px;
    }
    .retail {
        width: 92%;
        height: 220px;
        border-radius: 4px;
        background: rgba(61, 49, 148, 0.3);
        box-shadow: -2px 6px 9px 0px rgba(23, 30, 74, 0.06);
        margin-bottom: 10px;
    }
    .scatter {
        width: 92%;
        height: 260px;
        border-radius: 4px;
        background: rgba(61, 49, 148, 0.3);
        box-shadow: -2px 6px 9px 0px rgba(23, 30, 74, 0.06);
        margin-bottom: 10px;
        margin-top: 10px;
    }
}
.oneTitleBox {
    width: 100%;
    height: 80px;
    .oneTitle {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        background-color: #0f0240;
        padding-left: 20px;
        color: #fff;
        font-size: 22px;
        .icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: linear-gradient(180deg, #7920da, #0d78fd);
            margin-right: 20px;
            .titleImg {
                // width: 70px;
                // height: 70px;
                margin-left: -15px;
                margin-top: -15px;
            }
        }
    }
}
</style>
