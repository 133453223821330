<template>
  <div class="vip-center">
    <div class="title-img">
      <img
        src="../assets/user/vipTitle.png"
        style="width: 100%; height: 100%"
      />
    </div>
    <div class="privilege f18 cFFF">
      VIP<span class="f20" style="color: #d8b482"> 3 </span>大特权
    </div>
    <div class="jurisdiction">
      <div class="jurisdictionBox">
        <div class="jurisdiction-img">
          <img
            src="../assets/user/newest.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="jurisdiction-text f12 jtext1">最新数据</div>
      </div>
      <div class="jurisdictionBox">
        <div class="jurisdiction-img">
          <img
            src="../assets/user/report.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="jurisdiction-text f12">下载报告</div>
      </div>
      <div class="jurisdictionBox">
        <div class="jurisdiction-img">
          <img
            src="../assets/user/select.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="jurisdiction-text f12">精选文档</div>
      </div>
    </div>
    <div class="money-panel">
      <div
        class="moneyBox"
        v-for="(item, index) in buyVip"
        :key="index"
        :class="{ activevip: isindex == index }"
        @click="changevip(index)"
      >
        <div class="f18 c2c buynmb">{{ item.value }}</div>
        <div class="f11 buyTime">{{ item.time }}</div>
      </div>
    </div>
    <div class="buynow">立即充值</div>
    <div class="tips">温馨提示：充值成功后，不可退款</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      buyVip: [
        {
          value: "￥10",
          time: "包月",
        },
        {
          value: "￥328",
          time: "包季",
        },
        {
          value: "￥648",
          time: "包年",
        },
      ],
      isindex: "", //充值金额索引
    };
  },
  methods: {
    changevip(i) {
      var that = this;
      that.isindex = i;
      console.log(i);
    },
  },
};
</script>

<style lang="scss" scoped>
.vip-center {
  width: 100%;
  min-height: 100vh;
  background: #0f0240;
  display: flex;
  flex-direction: column;
  .title-img {
    width: 100%;
    height: 248px;
    margin-top: 23px;
  }
  .privilege {
    margin-left: 17px;
    margin-top: 35px;
  }
  .jurisdiction {
    height: 98px;
    margin: 20px 15px 31px 15px;
    background: rgba(23, 69, 115, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-around;
    .jurisdictionBox {
      width: 60px;
      .jurisdiction-img {
        width: 52px;
        height: 50px;
      }
      .jurisdiction-text {
        color: #c2cbff;
        margin-top: 9px;
      }
      .jtext1 {
        margin-left: 6px;
      }
    }
  }
  .money-panel {
    height: 65px;
    margin: 0 15px;
    display: flex;
    justify-content: space-between;
    .moneyBox {
      width: 107px;
      height: 65px;
      background-color: rgba(44, 51, 179, 0.26);
      border-radius: 4px;
      padding-top: 21px;
      .buynmb {
        text-align: center;
      }
    }
    .activevip {
      background-color: rgba(44, 51, 179, 0.58);
    }
  }
  .buynow {
    height: 55px;
    margin: 28px 15px 14px 15px;
    text-align: center;
    line-height: 55px;
    font-size: 21px;
    color: #c2cbff;
    background: linear-gradient(13deg, #7920da, #0d78fd);
    border-radius: 3px;
  }
  .tips{
      font-size: 12px;
      color: #423C9B;
      margin-left: 15px;
  }
}
</style>