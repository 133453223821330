<template>
  <div class="viprecord">
    <div class="recordBox" v-for="(item, index) in records" :key="index">
      <div class="box-text">
        <div class="f15 cFFF btext1">VIP充值</div>
        <div style="color: #c2cbff" class="f12">{{ item.time }}</div>
      </div>
      <div class="bmoney cFFF">
        {{ item.number
        }}<span class="f12" style="margin-left: 8px; color: #c2cbff">元</span>
      </div>
    </div>
  </div>
</template>

<script>
import { number } from "echarts";
export default {
  data() {
    return {
      records: [
        {
          time: "2021-07-04 13:04",
          number: "20",
        },
        {
          time: "2021-07-05 13:04",
          number: "30",
        },
        {
          time: "2021-07-06 13:04",
          number: "40",
        },
      ],
    };
  },
  created() {
    document.title = "充值记录";
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.viprecord {
  width: 100%;
  height: 100vh;
  background: #0f0240;
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  .recordBox {
    height: 88px;
    background: rgba(23, 69, 115, 0.36);
    margin: 0px 15px 15px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box-text {
      margin-left: 25px;
      .btext1 {
        margin-bottom: 13px;
      }
    }
    .bmoney {
      margin-top: 13px;
      font-size: 24px;
      margin-right: 18px;
    }
  }
}
</style>