import  Vue  from  'vue'
import  App  from  './App.vue'
import  router  from  './router'
import  ElementUI  from  'element-ui';
import  'element-ui/lib/theme-chalk/index.css';
import VueLazyComponent from '@xunlei/vue-lazy-component';
import './assets/css/global.css';
import './assets/js/global.js';
import VueLazyLoad from 'vue-lazyload'

Vue.use(VueLazyLoad)
Vue.use(VueLazyComponent)
Vue.config.productionTip  =  false
Vue.use(ElementUI,   {  size:   'small',  zIndex:  3000  });
window.Vue = Vue;

new  Vue({    
    router,
        render:  h  =>  h(App)
}).$mount('#app')