<template>
  <div class="containers">
    <div class="oneTitleBox">
      <div class="oneTitle">
        <div class="icon">
          <img class="titleImg" src="../assets/icon/resources.png" />
        </div>
        区域资源
      </div>
    </div>
    <div v-for="(item, index) in pOITypeList" :key="index">
      <div class="threeTitle2">
        <div class="titleL"></div>
        {{ item.Name }}
        <div class="titleR"></div>
      </div>
      <div class="mapBox">
        <div
          :id="'container' + index"
          style="width: 100vw; height: 322px"
        ></div>
      </div>
      <div class="form2">
        <table class="table-form">
          <thead style="background: rgba(59, 33, 131, 0.3)">
            <tr>
              <th>类型</th>
              <th>个数</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item1, index1) in item.List" v-bind:key="index1">
              <td>
                <div class="sanJia" :style="'background:' + item1.color"></div>
                <div class="tdBox">
                  <div>{{ item1.name }}</div>
                </div>
              </td>
              <td>{{ item1.count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// import * as echarts from 'echarts';
import { post } from "../config/request";
import { EyesMap } from "../config/showMap.js"; // 引入地图
export default {
  data() {
    return {
      colors: [
        "#FF6393",
        "#2B61FF",
        "#5EDFFF",
        "#FFF65F",
        "#9023C8",
        "#8FFFC3",
        "#FFAA89",
        "#A38BFF",
        "#D75A38",
      ],
      pOITypeList: [
        {
          Name: "学校",
          DCode: [100700],
          Map: null,
          List: [],
          MapList: [],
        },
        {
          Name: "医院",
          DCode: [100500],
          Map: null,
          List: [],
          MapList: [],
        },
        {
          Name: "商业",
          DCode: [100400],
          Map: null,
          List: [],
          MapList: [],
        },
        {
          Name: "交通",
          DCode: [100100, 101100, 100800, 100300, 101200],
          Map: null,
          List: [],
          MapList: [],
        },
        {
          Name: "公园景点",
          DCode: [100200],
          Map: null,
          List: [],
          MapList: [],
        },
        {
          Name: "负面因素",
          DCode: [100600],
          Map: null,
          List: [],
          MapList: [],
        },
      ],
      analysisId: "",
      isShow: "",
    };
  },
  mounted() {
    this.getPOIData();
    setTimeout((e) => {
      document.getElementById("urbanResourcesBox").style.height = "auto";
    }, 500);
  },
  methods: {
    getPOIData() {
      var that = this;
      that.analysisId = window.GetAreaInfo("id");
      post("/content/regional_resource", { analysisId: that.analysisId }).then(
        (res) => {
          console.log(res);
          if (res.code == 0) {
            that.initData(res.data);
          } else {
            return (res.data = undefined);
          }
        }
      );
    },
    initData(res) {
      var that = this;
      var mapList = res;
      //处理大类
      for (var i = that.pOITypeList.length - 1; i >= 0; i--) {
        var dCodes = that.pOITypeList[i].DCode;
        that.pOITypeList[i]["List"] = [];
        that.pOITypeList[i]["MapList"] = [];
        //处理大类下的小分类，比如交通
        var list = {};
        for (var n = 0; n < dCodes.length; n++) {
          var dcode = dCodes[n];
          //处理地图和列表数据
          for (var m = 0; m < mapList.length; m++) {
            if (parseInt(mapList[m].dcode / 100) * 100 == dcode) {
              that.pOITypeList[i]["MapList"].push(mapList[m]);
              var item = list[mapList[m].dcode];
              console.log(list[mapList[m].dcode]);
              if (item) {
                item["count"] += 1;
              } else {
                item = {
                  dcode: mapList[m].dcode,
                  name: mapList[m].dCodeName,
                  sortidx: mapList[m].sortIdx,
                  color: "#ffffff",
                  count: 1,
                };
                list[mapList[m].dcode] = item;
              }
            }
          }
        }

        //处理列表
        var arrList = [];
        for (var key in list) {
          arrList.push(list[key]);
        }
        //列表排序
        arrList.sort((a, b) => {
          return a.sortidx - b.sortidx;
        });
        for (var m = 0; m < arrList.length; m++) {
          arrList[m].color = that.colors[m];
        }
        //如果列表不存在就默认一条数据
        if (arrList.length == 0) {
          // that.pOITypeList.splice(i, 1);
          that.pOITypeList[i]["List"] = [];
        } else {
          that.pOITypeList[i]["List"] = arrList;
        }
      }
      that.initMap();
    },
    initMap() {
      var that = this;
      var areaInfo = window.GetAreaInfo();
      for (var i = 0; i < that.pOITypeList.length; i++) {
        var colorList = {};
        var list = that.pOITypeList[i]["List"];
        for (var n = 0; n < list.length; n++) {
          colorList[list[n]["dcode"]] = list[n]["color"];
        }
        var mapList = that.pOITypeList[i]["MapList"];
        //处理标签颜色
        mapList.map((item, idx) => {
          mapList[idx]["color"] = colorList[item["dcode"]];
        });
        EyesMap.InitMap("container" + i, (map) => {
          EyesMap.ShowArea(map, areaInfo);
          EyesMap.ShowCircleMarker(map, mapList);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.containers {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #0f0240;
  .mapBox {
    width: 100%;
    height: 322px;
  }
}
.oneTitleBox {
  width: 100%;
  height: 80px;
  .oneTitle {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    background-color: #0f0240;
    padding-left: 20px;
    color: #fff;
    font-size: 22px;
    .icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: linear-gradient(180deg, #7920da, #0d78fd);
      margin-right: 20px;
      .titleImg {
        // width: 70px;
        // height: 70px;
        margin-left: -15px;
        margin-top: -15px;
      }
    }
  }
}
.threeTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 18px;
  margin: 20px 0;
  padding: 0 25px;
  .titleL,
  .titleR {
    flex: 1;
    height: 3px;
  }
  .titleL {
    background: linear-gradient(
      90deg,
      rgba(110, 98, 228, 0),
      rgba(110, 98, 228, 1)
    );
    margin-right: 10px;
  }
  .titleR {
    background: linear-gradient(
      90deg,
      rgba(110, 98, 228, 1),
      rgba(110, 98, 228, 0)
    );
    margin-left: 10px;
  }
}
.threeTitle2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 18px;
  margin-bottom: 30px;
  padding: 0 25px;
  .titleL,
  .titleR {
    flex: 1;
    height: 3px;
  }
  .titleL {
    background: linear-gradient(
      90deg,
      rgba(110, 98, 228, 0),
      rgba(110, 98, 228, 1)
    );
    margin-right: 10px;
  }
  .titleR {
    background: linear-gradient(
      90deg,
      rgba(110, 98, 228, 1),
      rgba(110, 98, 228, 0)
    );
    margin-left: 10px;
  }
}
.form2 {
  margin-top: 16px;
  padding: 0 17px 30px 17px;
  height: 100%;
  table {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
      border: 2px solid #3b2183;
      text-align: center;
      color: #ffffff;
      font-size: 15px;
      height: 40px;
      width: 50%;
      position: relative;
      .sanJia {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 15px;
        margin-right: 17px;
        position: absolute;
        top: 14px;
      }
    }
    .th-head {
      display: flex;
      padding-left: 42px;
    }
    .tdBox {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
