<template>
  <div class="homeBox1">
    <!-- 目录按钮 -->
    <div class="catalogueBox" @click="showCatalogue">
      <img src="../assets/icon/catalogue.png" />
    </div>
    <!-- 目录弹窗 -->
    <el-drawer
      direction="ltr"
      size="55%"
      :visible.sync="drawer"
      :with-header="false"
    >
      <div class="catalogue">
        <img src="../assets/document/catalogue.png" />
        <div class="cataloguecontentBox">
          <div class="catalogueTitle" @click="Refresh">目录</div>
          <div class="catalogues" @click="clickCatalogue('locationBox')">
            1&nbsp;/&nbsp;区域界定
          </div>
          <div class="catalogues" @click="clickCatalogue('fundamentalsBox')">
            2&nbsp;/&nbsp;城市概况
          </div>
          <div class="catalogues" @click="clickCatalogue('urbanResourcesBox')">
            3&nbsp;/&nbsp;区域资源
          </div>
          <div
            class="catalogues"
            @click="clickCatalogue('peripheralMarketBox')"
          >
            4&nbsp;/&nbsp;区域市场
          </div>
          <div class="catalogues" @click="clickCatalogue('populationBox')">
            5&nbsp;/&nbsp;区域人口
          </div>
          <div class="catalogues" @click="clickCatalogue('summaryBox')">
            6&nbsp;/&nbsp;区域小结
          </div>
        </div>
        <div class="download" @click="showDownload">
          <div class="load-img"></div>
          <div class="load-text">报告下载</div>
          <el-dialog
            :visible.sync="download"
            :append-to-body="true"
            :show-close="false"
            title="下载报告"
          >
            <el-input v-model="email" placeholder="请输入内容"></el-input>
            <div class="dialog-text">
              <div class="dialog-t1">
                报告下载的链接将发送至您的邮箱，请及时查收。
              </div>
              <div>链接有效期:2010-07-07</div>
            </div>
            <span slot="footer" @click="reportCommit">发送</span>
          </el-dialog>
        </div>
      </div>
    </el-drawer>
    <div id="locationBox" style="height: 500px">
      <vue-lazy-component>
        <location></location>
      </vue-lazy-component>
    </div>
    <div id="fundamentalsBox" style="height: 800px">
      <vue-lazy-component>
        <fundamentals></fundamentals>
      </vue-lazy-component>
    </div>
    <div id="economyBox" style="height: 800px">
      <vue-lazy-component>
        <economy></economy>
      </vue-lazy-component>
    </div>
    <div id="policyBox" style="height: 800px">
      <vue-lazy-component>
        <policy></policy>
      </vue-lazy-component>
    </div>
    <div id="cityPlanBox" style="height: 800px">
      <vue-lazy-component>
        <cityPlan></cityPlan>
      </vue-lazy-component>
    </div>
    <div id="urbanResourcesBox" style="height: 800px">
      <vue-lazy-component>
        <urbanResources></urbanResources>
      </vue-lazy-component>
    </div>
    <div id="peripheralMarketBox" style="height: 800px">
      <vue-lazy-component>
        <peripheralMarket></peripheralMarket>
      </vue-lazy-component>
    </div>
    <div id="commercialMarketBox" style="height: 800px">
      <vue-lazy-component>
        <commercial-market></commercial-market>
      </vue-lazy-component>
    </div>
    <div id="officeMarketBox" style="height: 800px">
      <vue-lazy-component>
        <office></office>
      </vue-lazy-component>
    </div>
    <div id="populationBox" style="height: 800px">
      <vue-lazy-component>
        <population></population>
      </vue-lazy-component>
    </div>
    <div id="summaryBox" style="height: 800px">
      <vue-lazy-component>
        <Summary></Summary>
      </vue-lazy-component>
    </div>
  </div>
</template>

<script>
import location from "./location.vue";
import fundamentals from "./fundamentals.vue";
import Economy from "./economy.vue";
import Policy from "./policy.vue";
import CityPlan from "./cityPlan.vue";
import UrbanResources from "./urbanResources.vue";
import peripheralMarket from "./peripheralMarket.vue";
import commercialMarket from "./commercialMarket.vue";
import Population from "./population.vue";
import Summary from "./summary.vue";
import Office from "./office.vue";
export default {
  components: {
    location,
    fundamentals,
    Economy,
    Policy,
    CityPlan,
    UrbanResources,
    peripheralMarket,
    commercialMarket,
    Population,
    Summary,
    Office,
  },
  data() {
    return {
      report: [],
      drawer: false,
      index: "",
      download: false,
      email: "",
    };
  },
  mounted() {
    this.goIndex();
    // this.listenerFunction();
  },
  created() {
    document.title = "区域分析";
    this.index = window.GetParam("index");
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    //侧边弹出目录
    showCatalogue() {
      this.drawer = true;
    },
    //显示下载页面
    showDownload() {
      this.download = true;
    },
    Refresh() {
      this.$router.go(0);
      this.drawer = false;
    },
    goIndex() {
      var anchor = document.getElementById(this.index);
      anchor && anchor.scrollIntoView();
    },
    clickCatalogue(e) {
      var anchor = document.getElementById(e);
      anchor && anchor.scrollIntoView();
      this.drawer = false;
    },
    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop);
    },
    //发送邮箱下载报告
    reportCommit() {
      if (this.email == "") {
        window.ShowMsg("请输入正确的邮箱地址！");
        return;
      }
      var emailPage =
        /^[a-z0-9](\w|\.|-)*@([a-z0-9]+-?[a-z0-9]+\.){1,3}[a-z]{2,4}$/i;
      if (!emailPage.test(this.email))
        return window.ShowMsg("邮箱格式错误，请重新输入！");
      window.ShowMsg("发送成功，请前往邮箱查看！", "success");
      this.download = false;
    },
  },
};
</script>

<style lang="scss">
.homeBox1 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #0f0240;
  overflow-y: auto;
  .catalogueBox {
    position: fixed;
    right: 30px;
    bottom: 60px;
    width: 35px;
    height: 35px;
    background: linear-gradient(49deg, #3995fc, #00cffd);
    border-radius: 5px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 30px;
      height: 30px;
    }
  }
}
.catalogue {
  width: 100%;
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .cataloguecontentBox {
    position: absolute;
    .catalogueTitle {
      font-size: 30px;
      color: rgba(121, 132, 160, 1);
      padding: 57px 0 38px 31px;
    }
    .catalogues {
      color: #fff;
      font-size: 16px;
      margin-bottom: 30px;
      margin-left: 30px;
    }
  }
  .download {
    width: 135px;
    height: 41px;
    position: absolute;
    bottom: 58px;
    left: 21px;
    background: linear-gradient(49deg, #3995fc, #00cffd);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    .load-img {
      width: 19px;
      height: 20px;
      background-image: url("../assets/document/download.png");
      background-size: cover;
      margin-right: 10px;
    }
    .load-text {
      font-size: 16px;
      color: #ffffff;
    }
  }
}
</style>