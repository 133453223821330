<template>
  <div class="detailsBox">
    <div class="detailsContent">
      <div class="detailsTop" v-if="showBar">
        <img @click="goBack" src="../assets/document/back.png" /><span>{{
          documentTitle
        }}</span>
      </div>
      <div class="detailsRigth" v-if="showBar">
        <div class="collection">
          <img
            v-if="collection"
            @click="getCollection"
            src="../assets/document/collection01.png"
          />
          <img
            v-else
            @click="getCollection"
            src="../assets/document/collection02.png"
          />
        </div>
        <div class="loadBox">
          <img @click="downLoadBtn" src="../assets/document/load.png" />
        </div>
        <div class="pagesBox">{{ num }}/{{ total }}</div>
      </div>
      <el-carousel
        ref="carousel"
        arrow="never"
        :autoplay="false"
        @change="eleChange"
      >
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <img class="element-img" v-lazy="item" :key="item" />
        </el-carousel-item>
      </el-carousel>
      <el-dialog
        :visible.sync="download"
        :append-to-body="true"
        :show-close="false"
        title="下载报告"
      >
        <el-input v-model.trim="email" placeholder="请输入邮箱地址"></el-input>
        <div class="dialog-text">
          <div class="dialog-t1">
            报告下载的链接将发送至您的邮箱，请及时查收。
          </div>
          <div>链接有效期:{{ endTime }}</div>
        </div>
        <span slot="footer" @click="sendOut">发送</span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { post } from "../config/request";
export default {
  data() {
    return {
      collection: null, //是否收藏
      showBar: true, //隐藏/显示控件
      download: false,
      baiDuDocId: null,
      documentTitle: null,
      documentId: null,
      list: [],
      total: null, //总页数
      num: 1, //当前页
      email: "",
      downloadUrl: "", //下载链接
      endTime: "", //过期时间
    };
  },
  created() {},
  mounted() {
    debugger
    this.baiDuDocId = window.GetParam("baiDuDocId");
    this.documentTitle = window.GetParam("documentTitle");
    this.documentId = window.GetParam("documentId");
    console.log(this.documentId);
    this.getList();
    this.slideBanner();
  },
  methods: {
    // 获取详情数据
    async getList() {
      console.log(this.baiDuDocId);
      let res = await post("content/choiceness_detail", {
        baiDuDocId: this.baiDuDocId,
        documentId: this.documentId,
      });
      console.log(res);
      if (res.code == 0) {
        this.list = res.data.urlList;
        this.total = res.data.urlList.length;
        this.downloadUrl = res.data.downloadUrl;
        this.collection = res.data.collectionFlag;
        this.endTime = res.data.expireTime;
      } else {
        window.ShowMsg("获取文档失败，请检查网络或稍后再试！", "warning");
      }
    },
    // 添加收藏
    getCollection() {
      if (!this.collection) {
        post("content/collection", {
          documentId: this.documentId,
        }).then((res) => {
          if (res.code == 0) {
            window.ShowMsg("收藏成功！", "success");
            this.collection = 1;
          } else {
            window.ShowMsg("收藏失败，请检查网络或稍后再试！", "warning");
          }
        });
      } else {
        post("content/delete_collection", {
          documentId: this.documentId,
        }).then((res) => {
          if (res.code == 0) {
            window.ShowMsg("已取消收藏！", "success");
            this.collection = 0;
          } else {
            window.ShowMsg("取消失败，请检查网络或稍后再试！", "success");
          }
        });
      }
    },
    // 发送邮件
    sendOut() {
      if (this.email == "") {
        window.ShowMsg("请输入正确的邮箱地址！");
        return;
      }
      var emailPage =
        /^[a-z0-9](\w|\.|-)*@([a-z0-9]+-?[a-z0-9]+\.){1,3}[a-z]{2,4}$/i;
      if (!emailPage.test(this.email))
        return window.ShowMsg("邮箱格式错误，请重新输入！");
      // 临时方案，等邮件接口出来后修改
      Email.send({
        Host: "smtp.qq.com",
        Username: "591612759@qq.com",
        Password: "rqpctlpccheqbdhi",
        To: this.email,
        From: "591612759@qq.com",
        Subject: "锐理慧眼报告下载",
        Body: this.downloadUrl,
      }).then((message) => {
        console.log(message);
      });
      window.ShowMsg("发送成功，请前往邮箱查看！", "success");
      this.download = false;
    },
    eleChange(e) {
      this.num = e + 1;
    },
    downLoadBtn() {
      if (window.IsVIP() || this.downloadUrl != "") {
        this.download = true;
      } else {
        alert("该文档下载需VIP，请到会员中心去开通！");
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    clickBar() {
      if (this.showBar == true) {
        this.showBar = false;
      } else {
        this.showBar = true;
      }
    },
    slideBanner() {
      //选中item的盒子
      var box = document.querySelector(".el-carousel__container");
      //手指起点X坐标
      var startPoint = 0;
      //手指滑动重点X坐标
      var stopPoint = 0;
      //手指起点Y坐标
      var startPointY = 0;
      //手指滑动重点Y坐标
      var stopPointY = 0;
      var pathWidth = window.screen.width;
      var pathHeight = window.screen.height;

      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
        startPointY = 0;
        stopPointY = 0;
      };
      // 手指点击
      box.addEventListener("click", (e) => {
        if (this.showBar == true) {
          this.showBar = false;
        } else {
          this.showBar = true;
        }
      });
      //手指按下
      box.addEventListener("touchstart", (e) => {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
        //手指点击位置的Y坐标
        startPointY = e.changedTouches[0].pageY;
      });
      //手指滑动
      box.addEventListener("touchmove", (e) => {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
        //手指滑动后终点位置Y的坐标
        stopPointY = e.changedTouches[0].pageY;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      box.addEventListener("touchend", (e) => {
        if (pathWidth < pathHeight) {
          if (stopPointY == 0 || startPointY - stopPointY == 0) {
            resetPoint();
            return;
          }
          if (startPointY - stopPointY > 0) {
            resetPoint();
            this.$refs.carousel.next();
            return;
          }
          if (startPointY - stopPointY < 0) {
            resetPoint();
            this.$refs.carousel.prev();
            return;
          }
        } else {
          if (stopPoint == 0 || startPoint - stopPoint == 0) {
            resetPoint();
            return;
          }
          if (startPoint - stopPoint > 0) {
            resetPoint();
            this.$refs.carousel.next();
            return;
          }
          if (startPoint - stopPoint < 0) {
            resetPoint();
            this.$refs.carousel.prev();
            return;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ui-ppt__pagenum-box {
  display: none !important;
}
.detailsBox {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  .detailsContent {
    color: #fff;
    font-size: 18px;
    .detailsTop {
      img {
        width: 28.5px;
        height: 28.5px;
      }
    }
  }
}
@media screen and (orientation: portrait) {
  //竖屏状态下
  .detailsContent {
    position: absolute;
    width: 100vh;
    height: 100vw;
    top: 0;
    left: 100vw;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    transform-origin: 0% 0%;
    .detailsTop {
      z-index: 1000;
      position: absolute;
      top: 0px;
      right: 0px;
      width: 100vh;
      height: 49px;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
      );
      img {
        margin-left: 20px;
        margin-right: 16px;
        margin-top: 10px;
      }
    }
    .detailsRigth {
      z-index: 1000;
      height: 100vw;
      width: 98px;
      position: absolute;
      bottom: 0px;
      right: 0px;
      padding-top: 50vw;
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
      );
      text-align: center;
      img {
        width: 20px;
        height: 20px;
        margin-bottom: 25px;
      }
      .pagesBox {
        width: 90px;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 0 0 20px;
        background: #1a2052;
        margin-left: 8px;
      }
    }
    .el-carousel {
      width: 100vh !important;
      height: 100vw !important;
      /deep/.el-carousel__container {
        width: 100vh !important;
        height: 100vw !important;
      }
    }
    .el-carousel__item,
    .element-img {
      width: 100vh !important;
      height: 100vw !important;
      color: #475669;
      font-size: 18px;
      margin: 0;
      padding: 0;
      z-index: 100;
    }
  }
}
@media screen and (orientation: landscape) {
  //横屏状态下
  .detailsContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    .detailsTop {
      z-index: 1000;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100vw;
      height: 49px;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
      );
      img {
        margin-left: 20px;
        margin-right: 16px;
        margin-top: 10px;
      }
    }
    .detailsRigth {
      z-index: 1000;
      height: 100vw;
      width: 98px;
      position: absolute;
      top: 0px;
      right: 0px;
      padding-top: 50vh;
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
      );
      text-align: center;
      img {
        width: 20px;
        height: 20px;
        margin-bottom: 25px;
      }
      .pagesBox {
        width: 90px;
        height: 40px;
        line-height: 40px;
        border-radius: 20px 0 0 20px;
        background: #1a2052;
        margin-left: 8px;
      }
    }
    .el-carousel {
      width: 100vw !important;
      height: 100vh !important;
      /deep/.el-carousel__container {
        width: 100vw !important;
        height: 100vh !important;
      }
    }
    .el-carousel__item,
    .element-img {
      width: 100vw !important;
      height: 100vh !important;
      color: #475669;
      font-size: 18px;
      margin: 0;
      padding: 0;
      z-index: 100;
    }
  }
}
</style>