import axios from 'axios'
//创建一个axios对象  
const instance = axios.create({
    //http://192.168.0.117:8088/swagger/index.html
    //baseURL: 'http://192.168.0.117:8088/api/', //baseURL会在发起请求的时候自动拼接在url前面
    baseURL: 'https://test.reallyinfo.com/huiyan/api/', //baseURL会在发起请求的时候自动拼接在url前面
    // baseURL: 'https://api.reallyinfo.com/huiyan/api/', //baseURL会在发起请求的时候自动拼接在url前面
    timeout: 60000
})
//请求拦截
instance.interceptors.request.use(
    config => {
        // 携带上token
        let localUrl = window.location.hash
        let userInfo = window.GetUserInfo();
        //登录界面不需要token
        if (localUrl.indexOf("#/index") < 0) {
            config.headers['x-api-token'] = userInfo.token;
            config.headers['x-api-nodeid'] = userInfo.cityCode;
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)
// 响应拦截
//根据服务器状态码做响应处理
instance.interceptors.response.use(
    function (res) {
        return res.data
    },

    function (err) {
        let { response } = err
        if (response) {
            // 服务器返回结果
            switch (response.status) {
                case 401: //需要验证用户(一般是未登录)
                    // 跳转登录页
                    break;
                case 403: //服务器理解请求单拒绝(token过期)
                    break;
                case 404: //
                    break;
            }
        } else {
            // 服务器崩溃或者断网
            if (!window.navigator.onLine) {
                // 断网处理  可以跳转到断网页面
                return
            }
        }
        return Promise.reject(err)
    }
)

export function get(url, params) {
    return instance.get(url, {
        params
    })
}
export function post(url, data) {
    return instance.post(url, data)
}
export function put(url, data) {
    return instance.put(url, data)
}
export function del(url, data) {
    return instance.del(url, data)
}