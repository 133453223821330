<template>
  <div class="privacy">
    <div class="privacy-text">
      <div class="textP">
        <p>
          <span style="line-height: 1; font-size: 12px"
            >锐理慧眼尊重并保护所有使用服务用户的个人隐私权。</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >为了给您提供更准确、更有个性化的服务，锐理慧眼会按照本隐私权政策的规定使用和披露您的个人信息。但锐理慧眼将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征</span
          ><span style="line-height: 1; font-size: 12px"
            >得您事先许可的情况下，锐理慧眼不会将这些信息对外披露或向第三方提供。锐理慧眼会不时更新本隐私权政策。</span
          >
        </p>
        <p>
          <br /><span style="line-height: 1; font-size: 12px"
            >您在同意锐理慧眼服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于锐理慧眼服务使用协议不可分割的一部分。</span
          ><br /><span style="font-size: 12px">​</span><br /><span
            style="line-height: 1; font-size: 14px"
            >1、适用范围</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >a、在您注册锐理慧眼帐号时，您根据锐理慧眼要求提供的个人注册信息；</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >b、在您使用锐理慧眼网络服务，或访问锐理慧眼平台网页时，锐理慧眼自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >c、锐理慧眼通过合法途径从商业伙伴处取得的用户个人数据。</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >您了解并同意，以下信息不适用本隐私权政策：</span
          ><br /><span style="font-size: 12px">​</span><br /><span
            style="line-height: 1; font-size: 12px"
            >a、您在使用锐理慧眼平台提供的搜索服务时输入的关键字信息；</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >b、锐理慧眼收集到的您在锐理慧眼发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >c、违反法律规定或违反锐理慧眼规则行为及锐理慧眼已对您采取的措施。</span
          ><br /><br /><span style="line-height: 1; font-size: 14px"
            >2、信息使用</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >a、锐理慧眼不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和锐理慧眼（含锐理慧眼关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >b、锐理慧眼亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何锐理慧眼平台用户如从事上述活动，一经发现，锐理慧眼有权立即终止与该用户的服务协议。</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >c、为服务用户的目的，锐理慧眼可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与锐理慧眼合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。</span
          ><br /><br /><span style="line-height: 1; font-size: 14px"
            >3、信息披露</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >在如下情况下，锐理慧眼将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >a、经您事先同意，向第三方披露；</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >b、为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >c、根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >d、
            如您出现违反中国有关法律、法规或者锐理慧眼服务协议或相关规则的情况，需要向第三方披露；</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >e、
            如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >f、
            在锐理慧眼平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，锐理慧眼有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >g、 其它锐理慧眼根据法律、法规或者网站政策认为合适的披露。</span
          ><br /><br /><span style="line-height: 1; font-size: 14px"
            >4、信息存储和交换</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >锐理慧眼收集的有关您的信息和资料将保存在锐理慧眼及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或锐理慧眼收集信息和资料所在地的境外并在境外被访问、存储和展示。</span
          ><br /><br /><span style="line-height: 1; font-size: 14px"
            >5. Cookie的使用</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >a、在您未拒绝接受cookies的情况下，锐理慧眼会在您的计算机上设定或取用cookies</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >，以便您能登录或使用依赖于cookies的锐理慧眼平台服务或功能。锐理慧眼使用cookies可为您提供更加周到的个性化服务，包括推广服务。</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >b、您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的锐理慧眼网络服务或功能。</span
          ><br /><span style="line-height: 1; font-size: 12px">​</span
          ><br /><span style="line-height: 1; font-size: 12px"
            >c、通过锐理慧眼所设cookies所取得的有关信息，将适用本政策。</span
          ><br /><br /><span style="line-height: 1; font-size: 14px"
            >6. 信息安全</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >a、
            锐理慧眼帐号均有安全保护功能，请妥善保管您的用户名及密码信息。锐理慧眼将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。</span
          ><br /><br /><span style="line-height: 1; font-size: 12px"
            >b、在使用锐理慧眼网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是锐理慧眼用户名及密码发生泄露，请您立即联络锐理慧眼客服，以便锐理慧眼采取相应措施。</span
          ><br /><span style="line-height: 1; font-size: 12px">​</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    document.title = "隐私政策";
  },
};
</script>

<style lang="scss" scoped>
.privacy {
  width: 100%;
  background: #0f0240;
  display: flex;
  flex-direction: column;
  padding: 25px 15px 24px 15px;
  .privacy-text {
    padding: 25px 10px 0 10px;
    background: rgba(23, 69, 115, 0.36);
  }
}

.textP {
  color: #c2cbff;
}
</style>