<template>
  <div class="userinfo">
    <!-- <div class="userinfo-title">
      <div class="back">
        <div class="back-icon">
          <img
            src="../assets/user/back.png"
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
      <div class="back-title">个人信息</div>
    </div> -->
    <div class="infoBox">
      <div class="account">
        <div>账号</div>
        <div class="account-number cFFF">
          <div style="color: rgba(255, 255, 255, .7);">{{ userInfo.phone }}</div>
          <div class="account-icon"></div>
        </div>
      </div>
      <div class="account">
        <div>昵称</div>
        <div class="account-number" @click="changeNickname">
          <div>{{ userInfo.userName }}</div>
          <div class="account-icon">
            <img
              src="../assets/user/arrow.png"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
      </div>
      <div class="account">
        <div>邮箱</div>
        <div class="account-number" @click="changeEmail">
          <div>{{ userInfo.email }}</div>
          <div class="account-icon">
            <img
              src="../assets/user/arrow.png"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="sgin-out" @click="eliminate">退出登录</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo:{
        userName:"",
        email:"",
        phone:""
      }
    };
  },
  created() {
    document.title = "个人信息";
  },
  mounted() {
    var that = this;
    that.userInfo = window.GetUserInfo();
    // window.addEventListener("storage",function (e) {
		// 		window.location.reload();
		// });
    // 发送事件
    window.pageEvent.on('updateUserInfo', (data)=> {
      that.userInfo=window.GetUserInfo();
      that.isVip = window.IsVIP();
      console.log(that.userInfo);
      // debugger
    })
  },
  methods: {
    changeNickname() {
         this.$router.push("/revise");
    //   window.NavigateTo("revise");
    },
    changeEmail() {
         this.$router.push("/email");
    //   window.NavigateTo("email");
    },
    eliminate() {
      window.ClearUserInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
.userinfo {
  width: 100%;
  height: 100vh;
  background: #0f0240;
  // padding-top: 19px;
  display: flex;
  flex-direction: column;
  .userinfo-title {
    display: flex;
    color: #ffffff;
    align-items: center;
    .back {
      width: 50px;
      height: 21px;
      .back-icon {
        width: 12px;
        height: 21px;
        margin-left: 18px;
      }
    }
    .back-title {
      font-size: 17px;
    }
  }
  .infoBox {
    height: 214px;
    margin: 50px 23px 15px 30px;
    .account {
      display: flex;
      justify-content: space-between;
      color: #c2cbff;
      font-size: 14px;
      margin-bottom: 50px;
      .account-number {
        display: flex;
        align-items: center;
        .account-icon {
          width: 4px;
          margin-left: 26px;
        }
      }
    }
  }
  .sgin-out {
    width: 80%;
    height: 55px;
    background: #1a255a;
    opacity: 0.67;
    border-radius: 3px;
    position: fixed;
    bottom: 58px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    line-height: 55px;
    font-size: 15px;
    color: #c2cbff;
  }
}
</style>