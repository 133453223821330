//地图
var infoWindow = null;
var isShowCoverDiv = true;
export const EyesMap = {};

//设置隐藏地图遮罩层
EyesMap.HideCoverDiv = function() {
    isShowCoverDiv = false;
};

//初始化地图
EyesMap.InitMap = function(container, callback) {
    let map = new AMap.Map(container, {
        mapStyle: "amap://styles/darkblue",
        animateEnable: false,
        isHotspot: false,
        showIndoorMap: false,
        // dragEnable:false
    });
    //在地图上添加一个透明的DIV遮罩层
    if (isShowCoverDiv) {
        var mapDiv = document.getElementById(container);
        mapDiv.style.pointerEvents = 'none';
        var div = document.createElement('div');
        div.setAttribute("style", "background:#ffffff;position:absolute;opacity:0;width:" + mapDiv.style.width + ";height:" + mapDiv.style.height + ";z-index:10;");
        div.setAttribute("id", "cover_" + container);
        div.setAttribute("onclick", "javascript:this.style.display='none';this.nextElementSibling.style.pointerEvents = 'auto';");
        mapDiv.before(div);
        map.on('click', (e) => {
            var id = e.target.kv.id;
            document.getElementById("cover_" + id).style.display = "";
            document.getElementById(id).style.pointerEvents = "none";
        });
    };
    typeof(callback) === 'function' && callback(map);
};

//显示区域（根据半径确定是圆还是多边形） 
EyesMap.ShowArea = function(map, data) {
    if (!(data && data.center)) return;
    //判断半径来决定是花圆还是多边形
    if (data.radius > 0) {
        var circle = new AMap.Circle({
            center: data.center,
            radius: data.radius, //半径
            strokeColor: "#DB69B4",
            strokeWeight: 1,
            fillOpacity: 0,
            fillColor: 'transparent',
            zIndex: 9
        });
        circle.setMap(map);
        // 创建点覆盖物
        new AMap.Marker({
            map: map,
            position: data.center,
            icon: new AMap.Icon({
                size: new AMap.Size(55, 62),
                image: require("../assets/home/spot.png"),
                imageSize: new AMap.Size(55, 62),
                anchor: "center",
            }),
            offset: new AMap.Pixel(-27, -31.2),
            zIndex: 2
        });
    } else {
        var polygon = new AMap.Polygon({
            path: data.path,
            strokeColor: "#DB69B4",
            strokeWeight: 1,
            fillOpacity: 0,
            fillColor: 'transparent',
            zIndex: 1
        });
        polygon.setMap(map);
    };
    map.setFitView();
};

//显示圆点标签
EyesMap.ShowCircleMarker = function(map, list) {
    if (!map) return;
    if (!(list && list.length > 0)) return;
    for (var n = 0; n < list.length; n++) {
        var center = list[n]["geom"] || list[n]["point"];
        var marker = new AMap.CircleMarker({
            map: map,
            center: center,
            radius: 7,
            strokeWeight: 0,
            fillColor: list[n]["color"] || "#1EAFB6",
            fillOpacity: 0.9,
            zIndex: 10,
            cursor: "pointer",
            clickable: true,
            extData: list[n]
        });
        marker.on('click', (e) => {
            var item = e.target.getExtData();
            console.log(item);
            if (!infoWindow) infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(0, -10) });
            var name = item.name;
            if (item.buyIndex) name += "<br/>购买力指数：" + item.buyIndex;
            if (item.price) name += "<br/>" + item.price + " 元/㎡";
            infoWindow.setContent(name);
            infoWindow.open(map, e.target.getCenter());
        });
    }
};

//显示本案&区位
EyesMap.ShowBenAnMarker = function(map, data) {
    if (!data.center) return;
    var text = new AMap.Text({
        text: '<div class="benan">本案</div>',
        anchor: 'center',
        position: data.center
    });
    text.setMap(map);
};

//显示文本标签
EyesMap.ShowLabel = function(map, data) {
    if (!data) return;
    for (var i = 0; i < data.length; i++) {
        var item = data[i];
        var color = item.color || "#1EAFB6";
        var name = item.name || "";
        if (item.price) name += "<br/>" + item.price + " 元/㎡";
        if (item.geom) {
            var center = item.geom || item.point;
            var text = new AMap.Text({
                text: '<div class="biaoqian"><div class="title" style="background-color: ' + color + ';">' + name + '</div><div class="jiantou" style="border-top: 10px solid ' + color + ';"></div></div>',
                anchor: 'bottom-center',
                position: center
            });
            text.setMap(map);
        };
    };
    map.setFitView();
};

//显示连接线
EyesMap.ShowLine = function(map, center, data) {
    if (!center) return;
    if (!data) return;
    for (var i = 0; i < data.length; i++) {
        var item = data[i];
        if (item.geom || item.point) {
            var center2 = item.geom || item.point;
            var path = [
                center, center2
            ];
            var polyline = new AMap.Polyline({
                path: path,
                outlineColor: '#ffffff',
                strokeColor: "#6F62EA",
                strokeWeight: 1,
                zIndex: 10
            })
            polyline.setMap(map);
            var leblecenter = polyline.getBounds().getCenter();
            var text = new AMap.Text({
                text: '<div class="distance" style="color: #fff">' + (item.distance || "") + '</div>',
                anchor: 'center',
                position: leblecenter,
                zIndex: 11
            });
            text.setMap(map);
        };
    };
};

//用户定位
EyesMap.Geolocation = function(map, callback) {
    var options = {
        'showButton': true, //是否显示定位按钮
        'buttonPosition': 'RT', //定位按钮的位置 LT LB RT RB 
        'buttonOffset': new AMap.Pixel(10, 20), //定位按钮距离对应角落的距离
        'showMarker': false, //是否显示定位点
        'showCircle': true, //是否显示定位精度圈
        'circleOptions': { //定位精度圈的样式
            'strokeColor': '#0093FF',
            'noSelect': true,
            'strokeOpacity': 0.5,
            'strokeWeight': 1,
            'fillColor': '#02B0FF',
            'fillOpacity': 0.25
        }
    };
    AMap.plugin(["AMap.Geolocation"], (e) => {
        var geolocation = new AMap.Geolocation(options);
        map.addControl(geolocation);
        geolocation.getCurrentPosition((status, result) => {
            if (status == 'complete') {
                EyesMap.GetAddrByCenter(result.position, (e) => {
                    var adCode = e.addressComponent.adcode;
                    typeof(callback) === 'function' && callback(result.position, adCode);
                });
            } else {
                //定位失败
                // window.ShowMsg("定位失败！");
            };
        });
    });
};

//根据中心点查找位置和行政编码
EyesMap.GetAddrByCenter = function(lnglat, callback) {
    AMap.plugin(["AMap.Geocoder"], function() {
        var geocoder = new AMap.Geocoder({
            radius: 500 //范围，默认：500
        });
        geocoder.getAddress(lnglat, function(status, result) {
            if (status === 'complete' && result.regeocode) {
                typeof(callback) === 'function' && callback(result.regeocode);
            } else {
                window.ShowMsg("未获取到地址！");
            };
        });
    });
};

//创建圆(首页圆形)
EyesMap.CreateCircleGeom = function(map, item) {
    map.clearMap();
    //创建圆
    var circle = new AMap.Circle({
        center: item.center, // 圆心位置
        radius: item.radius, // 圆半径
        fillColor: "rgba(225, 14, 74, 0.15)", // 圆形填充颜色
        fillOpacity: "0.15",
        strokeColor: "#fff", // 描边颜色
        strokeWeight: 0, // 描边宽度
        bubble: true, // 是否将覆盖物的鼠标或touch等事件冒泡到地图上
    });
    // 创建点覆盖物
    var marker = new AMap.Marker({
        position: item.center,
        icon: new AMap.Icon({
            size: new AMap.Size(110, 125),
            image: require("../assets/home/spot.png"),
            imageSize: new AMap.Size(55, 62.5),
            anchor: "center",
        }),
        offset: new AMap.Pixel(-27, -31.2),
    });
    map.add(marker);
    map.add(circle);
    map.setFitView();
    EyesMap.GetAddrByCenter(item.center, (e) => {
        item.addr = e.formattedAddress;
        item.adCode = e.addressComponent.adcode;
    });
};

//获取多边形信息
EyesMap.GetPolygonInfo = function(poly, item) {
    //输出多边形的面积
    var area = poly.getArea() / 1000000;
    item.area = Math.round(area * 10) / 10;
    //输出多边形的坐标
    item.paths = poly.getPath().map((i) => {
        return [i.lng, i.lat];
    });
    item.paths.push(item.paths[0]);
    //输出多边形的中心点坐标
    item.center = [
        poly.getBounds().getCenter().lng,
        poly.getBounds().getCenter().lat,
    ];
    EyesMap.GetAddrByCenter(item.center, (e) => {
        item.addr = e.formattedAddress;
        item.adCode = e.addressComponent.adcode;
    });
};

//绘制多边形
EyesMap.DrawPolygon = function(map, geomInfo, callback) {
    //创建多边形
    map.add([]);
    var polyEditor = new AMap.PolygonEditor(map);
    polyEditor.on("add", function(data) {
        var polygon = data.target;
        polyEditor.addAdsorbPolygons(polygon);
        polygon.on("dblclick", () => {
            polyEditor.setTarget(polygon);
            polyEditor.open();
        });
    });
    polyEditor.setTarget();
    polyEditor.open();
    //移动坐标点时触发
    polyEditor.on("adjust", function(e) {
        EyesMap.GetPolygonInfo(e.target, geomInfo);
    });
    //添加坐标点时触发
    polyEditor.on("addnode", function(e) {
        EyesMap.GetPolygonInfo(e.target, geomInfo);
    });
    //删除坐标点时触发
    polyEditor.on("removenode", function(e) {
        EyesMap.GetPolygonInfo(e.target, geomInfo);
    });

    typeof(callback) === 'function' && callback(polyEditor);
};