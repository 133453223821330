<template>
  <div class="tabbar">
    <div class="tabbar-text" @click="toHome" :class="{ active: $route.path === '/home' }">
      慧眼
    </div>
    <div class="tabbar-text" @click="toMentals" :class="{ active: $route.path === '/strictSelection' }">严选</div>
    <div class="tabbar-text" @click="toUser" :class="{ active: $route.path === '/user' }">我的</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods:{
    toHome(){
      this.$router.push('/home');
    },
    toMentals(){
      this.$router.push('/strictSelection')
    },
    toUser(){
      this.$router.push('/user')
    }
  }
};
</script>

<style lang="scss" scoped>
.tabbar {
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0px;
  background: #191b30;
  display: flex;
  justify-content: space-around;
  z-index: 99999;
  .tabbar-text {
    height: 50px;
    line-height: 50px;
    width: 50px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    opacity: 0.5;
  }
  .active {
    color: #ffffff;
    opacity: 1;
  }
}
</style>