<template>
    <div class="fundamentalsCantainer">
        <div class="oneTitleBox">
            <div class="oneTitle">
                <div class="icon">
                    <img class="titleImg" src="../assets/icon/city.png" />
                </div>
                城市概况
            </div>
        </div>
        <div class="cityPortrait">
            <div class="subTitle">
                <div>
                    <div>1</div>
                    城市画像
                </div>
                City portrait
            </div>
            <div class="threeTitle">
                <div class="titleL"></div>
                城市概况
                <div class="titleR"></div>
            </div>
            <div class="labelAndimg">
                <div class="labelBox">
                    <div v-for="item in cityLabels.list" :key="item">
                        {{ item }}
                    </div>
                </div>
                <div class="cityImg">
                    <div class="leftImg">
                        <img src="../assets/icon/xing.png" />
                    </div>
                    <div class="topImg">
                        <img src="../assets/icon/xing.png" />
                    </div>
                    <div class="rightImg">
                        <img src="../assets/icon/xing.png" />
                    </div>
                    <div class="bottomImg">
                        <img src="../assets/icon/cityImg.png" />
                    </div>
                </div>
            </div>
            <div
                class="cityDetailBox"
                v-for="(item, index) in cityDetails"
                :key="index"
            >
                <div class="cityDetail">
                    <div class="left">
                        <img :src="require('../assets/icon/' + item.imgName)"/>
                    </div>
                    <div class="center" style="flex: 1; margin-left: 25px">
                        <div
                            class="top"
                            style="
                                color: rgba(189, 184, 255, 1);
                                font-size: 12px;
                            "
                        >
                            {{ item.description }}
                        </div>
                        <div class="bottom" style="display: flex">
                            <div
                                class="bottomL"
                                style="
                                    display: flex;
                                    font-size: 14px;
                                    color: #fff;
                                "
                            >
                                <div style="font-size: 16px; color: #ffffff">
                                    {{ item.value
                                    }}<span style="font-size: 14px">{{
                                        item.unit
                                    }}</span>
                                </div>
                            </div>
                            <div
                                class="bottomR"
                                style="
                                    font-size: 14px;
                                    color: rgba(70, 64, 159, 1);
                                    margin-top: 3px;
                                "
                            >
                                &nbsp;{{ `${item.sumDate.slice(0, 4)}年` }}
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div
                            v-show="item.rate > 0"
                            class="rightT"
                            style="color: #ff0000; font-size: 22px"
                        >
                            {{ item.rate }}% ↑
                        </div>
                        <div
                            v-show="item.rate < 0"
                            class="rightT"
                            style="color: #00ff00; font-size: 22px"
                        >
                            {{ item.rate }}% ↓
                        </div>
                        <div
                            v-show="item.rate == 0"
                            class="rightT"
                            style="color: #304ffe; font-size: 22px"
                        >
                            {{ item.rate }}%
                        </div>
                        <div
                            class="rightB"
                            style="
                                font-size: 14px;
                                color: rgba(70, 64, 159, 1);
                                text-align: center;
                            "
                        >
                            同比
                        </div>
                    </div>
                </div>
            </div>
            <div class="threeTitle">
                <div class="titleL"></div>
                城市人口
                <div class="titleR"></div>
            </div>
            <div id="urbanPopulationEcs" class="echartsMargin"></div>
            <div id="populationGrowthEcs" class="echartsMargin"></div>
            <div id="pupilNumGrowthRate" class="echartsMargin"></div>
            <div class="urbanVitalityBox">
                <div id="urbanVitalityEcs"></div>
                <div
                    class="cityTitle"
                    v-for="(items, indexs) in urbanVitalitys"
                    :key="indexs"
                >
                    <div class="cityTitleL">
                        <img :src="require('../assets/icon/' + item.imgName)"/>
                    </div>
                    <div class="cityTitleC">
                        <div
                            class="cityTitleCT"
                            style="
                                color: rgba(210, 209, 223, 1);
                                font-size: 12px;
                            "
                        >
                            {{ items.meo }}
                        </div>
                        <div
                            class="cityTitleCB"
                            style="
                                color: rgba(255, 255, 255, 1);
                                font-size: 18px;
                            "
                        >
                            {{ items.description }}
                        </div>
                    </div>
                    <div class="cityTitleR">
                        <div
                            style="
                                color: rgba(255, 255, 255, 1);
                                font-size: 18px;
                            "
                        >
                            {{ items.value }}
                        </div>
                        <div
                            style="
                                color: rgba(127, 122, 203, 1);
                                font-size: 12px;
                                margin: 5px 0 0 5px;
                            "
                        >
                            {{ items.unit }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from "echarts"; // 引入echarts
import { post } from "../config/request";
export default {
    data() {
        return {
            ecsTextColor: "rgba(66, 60, 155, 1)", //echarts图表文字颜色
            id: 0,
            cityLabels: {
                img: require("@/assets/icon/cityImg.png"),
                list: [
                    "国家中心城市",
                    "国际门户枢纽城市",
                    "公园城市",
                    "世界文化名城",
                ],
            }, //城市标签
            cityDetails: [
                {
                    dcode: 110301,
                    value: 2093.8,
                    rate: 26.28,
                    meo: "",
                    cityCode: 510100,
                    unit: "万人",
                    imgName: "cityPeople.png",
                    cityName: "成都市",
                    sumDate: "2020-01-01",
                    description: "常驻人口",
                },
                {
                    dcode: 110401,
                    value: 4552.84,
                    rate: 17.3,
                    meo: "",
                    cityCode: 510100, 
                    unit: "亿元",
                    imgName: "cityGDP.png",
                    cityName: "成都市",
                    sumDate: "2021-01-01",
                    description: "GDP",
                },
                {
                    dcode: 110104,
                    value: 74.41,
                    rate: 0,
                    meo: "2020年",
                    cityCode: 510100,
                    unit: "%",
                    imgName: "Urbanization.png",
                    cityName: "成都市",
                    sumDate: "2020-01-01",
                    description: "城镇化率",
                },
                {
                    dcode: 110406,
                    value: 13971,
                    rate: 8.7,
                    meo: "",
                    cityCode: 510100,
                    unit: "元",
                    imgName: "income.png",
                    cityName: "成都市",
                    sumDate: "2021-01-01",
                    description: "城镇居民可支配收入",
                },
            ],
            urbanVitalitys: [
                {
                    cityCode: 510100,
                    cityName: "成都市",
                    dcode: 110201,
                    description: "世界500强",
                    imgName: "world.png",
                    meo: "截止2020年",
                    rate: 0,
                    sumDate: "2020-12-31",
                    unit: "家",
                    value: 305,
                },
                {
                    cityCode: 510100,
                    cityName: "成都市",
                    dcode: 110202,
                    description: "领事馆",
                    imgName: "consulate.png",
                    meo: "截止2021年5月",
                    rate: 0,
                    sumDate: "2021-05-31",
                    unit: "家",
                    value: 14,
                },
                {
                    cityCode: 510100,
                    cityName: "成都市",
                    dcode: 110203,
                    description: "大型赛事",
                    imgName: "trophy.png",
                    meo: "2022~2025年",
                    rate: 0,
                    sumDate: "2021-01-01",
                    unit: "场",
                    value: 5,
                },
            ], //城市活力
            PermanentPopulation: [], //常住人口
            vitality: {}, //全市面积
            PopulationGrowth: [], //人口数量TOP10
            pupilNumGrowthRate: [], //小学生数量
        };
    },
    created() {
        this.id = window.GetAreaInfo("id");
        this.getCityPortrayal();
    },
    mounted() {
        setTimeout((e) => {
            document.getElementById("fundamentalsBox").style.height = "auto";
        }, 500);
    },
    methods: {       
        // 获取城市基本面城市画像数据
        getCityPortrayal() {
            var that = this;
            post("content/portrayal", {
                analysisId: that.id,
            }).then((res) => {
                console.log(res, that.id);
                if (res.code == 0) {
                    that.cityLabels = JSON.parse(
                        res.data.overview.fact.data.value
                    );
                    that.PermanentPopulation = res.data.population[110301].data;
                    that.vitality = res.data.vitality.area;
                    that.PopulationGrowth = res.data.population[110303].data;
                    that.pupilNumGrowthRate = res.data.population[110304].data;
                    that.urbanVitalitys = res.data.vitality.list.map((i) => {
                        i.data["description"] = i.description;
                        return i.data;
                    });
                    that.cityDetails = res.data.overview.list.map((i) => {
                        i.data["description"] = i.description;
                        return i.data;
                    });
                    that.echartsUrbanPopulation();
                    that.echartsPopulationGrowth();
                    that.pupilNumGrowthRateEcs();
                    that.urbanVitality();
                } else {
                    window.ShowMsg(
                        "获取数据失败，请检查网络或稍后再试！",
                        "warning"
                    );
                }
            });
        },
        // echarts城市人口柱状图
        echartsUrbanPopulation() {
            var chartDom = document.getElementById("urbanPopulationEcs");
            var myChart = echarts.init(chartDom);
            var option;
            var xData = this.PermanentPopulation.map((item) => {
                return [item.sumDate];
            });
            var Pvalue = this.PermanentPopulation.map((item) => {
                return item.value;
            });
            var Prate = this.PermanentPopulation.map((item) => {
                return item.rate;
            });
            option = {
                title: {
                    text: "常住人口及增速",
                    textStyle: {
                        //文字颜色
                        color: "#fff",
                        //字体大小
                        fontSize: 14,
                    },
                },
                grid: {
                    //调整图例位置
                    left: "1%",
                    right: "1%",
                    bottom: "1%",
                    // containLabel: true,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#fff",
                        },
                    },
                },
                legend: {
                    data: ["常住人口（万人）", "增速（%）"],
                    right: "1%",
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 10,
                        color: "rgba(194, 203, 255, 1)",
                    },
                },
                xAxis: [
                    //X轴数据
                    {
                        type: "category",
                        data: xData.reverse(),
                        axisPointer: {
                            type: "shadow",
                        },
                        axisLabel: {
                            color: this.ecsTextColor,
                        },
                        splitLine: {
                            show: false, //隐藏网格线
                        },
                        axisLine: {
                            lineStyle: {
                                color: this.ecsTextColor, //轴颜色
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        show: false,
                        // name: "人口(万人)",
                        splitLine: {
                            show: false, //隐藏网格线
                        },
                        // axisLabel: {
                        //   formatter: "{value} ",
                        //   color: this.ecsTextColor,
                        // },
                        axisLine: {
                            lineStyle: {
                                color: this.ecsTextColor, //轴颜色
                            },
                        },
                    },
                    {
                        type: "value",
                        show: false,
                        // name: "增速(%)",
                        splitLine: {
                            show: false, //隐藏网格线
                        },
                        axisLabel: {
                            formatter: "{value} ",
                            color: this.ecsTextColor,
                        },
                        axisLine: {
                            lineStyle: {
                                color: this.ecsTextColor, //轴颜色
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "常住人口（万人）",
                        type: "bar",
                        data: Pvalue.reverse(),
                        label: {
                            show: true,
                            position: "top", //柱子上方文字
                            color: "rgba(194, 203, 255, 1)",
                        },
                        itemStyle: {
                            color: "rgba(44, 51, 179, 1)", //柱子颜色
                        },
                    },
                    {
                        name: "增速（%）",
                        type: "line",
                        yAxisIndex: 1,
                        data: Prate.reverse(),
                        label: {
                            show: true,
                            position: "top", //折线上方文字
                            color: "rgba(194, 203, 255, 1)",
                        },
                        itemStyle: {
                            color: "rgba(124, 53, 213, 1)", //折线颜色
                        },
                    },
                ],
            };
            option && myChart.setOption(option);
        },
        //人口增量TOP10图
        echartsPopulationGrowth() {
            var chartDom = document.getElementById("populationGrowthEcs");
            var myChart = echarts.init(chartDom);
            if (!this.PopulationGrowth) return;
            var pValue = this.PopulationGrowth.map((item) => {
                return item.value;
            });
            var newValue = pValue.sort(function (a, b) {
                return a - b;
            });
            newValue[9] = {
                value: newValue[9],
                itemStyle: {
                    color: "rgba(225, 33, 90, 1)",
                },
            };
            newValue[8] = {
                value: newValue[8],
                itemStyle: {
                    color: "rgba(216, 160, 43, 1)",
                },
            };
            newValue[7] = {
                value: newValue[7],
                itemStyle: {
                    color: "rgba(124, 189, 61, 1)",
                },
            };
            var cityValue = this.PopulationGrowth.map((item) => {
                return item.cityName;
            });
            var option;
            option = {
                title: {
                    text: `${this.PopulationGrowth[0].sumDate.slice(
                        0,
                        4
                    )}年重点城市常住人口数量TOP10`,
                    textStyle: {
                        //文字颜色
                        color: "#fff",
                        //字体大小
                        fontSize: 14,
                    },
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                legend: {
                    data: [`(万人)`],
                    right: "1%",
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 10,
                        color: "rgba(194, 203, 255, 1)",
                    },
                },
                grid: {
                    left: "3%",
                    right: "6%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: {
                    type: "value",
                    boundaryGap: [0, 0.01],
                    splitLine: {
                        show: false, //隐藏网格线
                    },
                    axisLine: {
                        lineStyle: {
                            color: this.ecsTextColor, //轴颜色
                        },
                    },
                    axisLabel: {
                        show: false,
                    },
                },
                yAxis: {
                    type: "category",
                    axisLine: {
                        lineStyle: {
                            color: this.ecsTextColor, //轴颜色
                        },
                    },
                    data: cityValue.reverse(),
                },
                series: [
                    {
                        name: `(万人)`,
                        type: "bar",
                        data: newValue,
                        itemStyle: {
                            color: "rgba(44, 51, 179, 1)", //柱子颜色
                        },
                        label: {
                            show: true,
                            position: "right", //柱子上方文字
                            color: "rgba(194, 203, 255, 1)",
                        },
                    },
                ],
            };
            option && myChart.setOption(option);
        },
        //小学生数量及增幅散点图
        pupilNumGrowthRateEcs() {
            var chartDom = document.getElementById("pupilNumGrowthRate");
            var myChart = echarts.init(chartDom);
            var pValue = this.pupilNumGrowthRate.map((item) => {
                return [item.value, item.rate, item.cityName];
            });
            var city = { name: "成都市" };
            var cityIndex = (this.pupilNumGrowthRate || []).findIndex(
                (cityNum) => cityNum.cityName == city.name
            );
            pValue[cityIndex] = {
                value: pValue[cityIndex],
                itemStyle: {
                    color: "#E42AB2",
                },
            };
            console.log(pValue);
            var option;
            option = {
                title: {
                    text: `${this.pupilNumGrowthRate[1].sumDate.slice(
                        0,
                        4
                    )}年重点城市小学生数量及增幅`,
                    textStyle: {
                        //文字颜色
                        color: "#fff",
                        //字体大小
                        fontSize: 14,
                    },
                },
                grid: {
                    //调整图例位置
                    left: "5%",
                    right: "5%",
                    bottom: "5%",
                    containLabel: true,
                },
                tooltip: {
                    // trigger: 'axis',
                    showDelay: 0,
                    formatter: function (params) {
                        return (
                            params.value[2] +
                            " :<br/>" +
                            params.value[0] +
                            "万人 " +
                            params.value[1] +
                            "% "
                        );
                    },
                    axisPointer: {
                        show: true,
                        type: "cross",
                        lineStyle: {
                            type: "dashed",
                            width: 1,
                        },
                    },
                },
                xAxis: {
                    scale: true,
                    name: "(万人)", //x轴的名称
                    nameTextStyle: {
                        padding: [6, 0, 0, 0], // 四个数字分别为上右下左与原位置距离
                    },
                    nameLocation: "middle", //x轴名称的显示位置'middle'，'end'
                    axisLine: {
                        lineStyle: {
                            color: this.ecsTextColor, //轴颜色
                            width: 2,
                        },
                    },
                    axisLabel: {
                        //轴上文字
                        color: this.ecsTextColor, //颜色
                    },
                    splitLine: {
                        show: false, //隐藏网格线
                    },
                },
                yAxis: {
                    scale: true,
                    name: "增幅（%）", //Y轴的名称
                    axisLine: {
                        lineStyle: {
                            color: this.ecsTextColor, //轴颜色
                            width: 2,
                        },
                    },
                    axisLabel: {
                        //轴上文字
                        color: this.ecsTextColor, //颜色
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: [this.ecsTextColor], //网格线颜色
                            width: 2,
                        },
                    },
                },
                series: [
                    {
                        symbolSize: 7, //散点大小
                        data: pValue,
                        type: "effectScatter",
                    },
                ],
            };
            option && myChart.setOption(option);
            setTimeout(function () {
                myChart.dispatchAction({
                    type: "showTip",
                    seriesIndex: 0, // 显示第几个series
                    dataIndex: cityIndex, // 显示第几个数据
                });
            });
        },
        //城市活力饼状图
        urbanVitality() {
            var chartDom = document.getElementById("urbanVitalityEcs");
            var myChart = echarts.init(chartDom);
            var option;
            var option = {
                title: {
                    text: "城市活力",
                    subtext: "单位（平方公里）",
                    textStyle: {
                        //文字颜色
                        color: "#fff",
                        //字体大小
                        fontSize: 14,
                    },
                    subtextStyle: {
                        color: this.ecsTextColor,
                    },
                },
                angleAxis: {
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: this.ecsTextColor, //轴颜色
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                    },
                    splitLine: {
                        show: false,
                    },
                    clockwise: true,
                },
                radiusAxis: {
                    type: "category",
                    data: ["", "", "", ""],
                    z: 10,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                },
                polar: {
                    radius: "60%", //图表大小
                    center: ["40%", "55%"], //图表位置
                },
                tooltip: {},
                legend: {
                    show: true,
                    orient: "vertical",
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 10,
                        color: "rgba(66, 60, 155, 1)",
                    },
                    x: "right",
                    y: "top",
                    data: [
                        `建城区面积 ${(
                            (this.vitality[2].data.value /
                                this.vitality[0].data.value) *
                            100
                        ).toFixed(2)}%`,
                        `市区面积 ${(
                            (this.vitality[1].data.value /
                                this.vitality[0].data.value) *
                            100
                        ).toFixed(2)}%`,
                        `全市面积 ${(
                            (this.vitality[0].data.value /
                                this.vitality[0].data.value) *
                            100
                        ).toFixed(2)}%`,
                    ],
                },
                series: [
                    {
                        type: "bar",
                        data: [0, 0, 0, 0],
                        coordinateSystem: "polar",
                        name: "",
                        stack: "a",
                        emphasis: {
                            focus: "series",
                        },
                    },
                    {
                        type: "bar",
                        barWidth: "50%",
                        data: [0, this.vitality[2].data.value, 0, 0],
                        coordinateSystem: "polar",
                        name: `建城区面积 ${(
                            (this.vitality[2].data.value /
                                this.vitality[0].data.value) *
                            100
                        ).toFixed(2)}%`,
                        roundCap: true, //柱状图末端呈现圆角
                        stack: "a",
                        emphasis: {
                            focus: "series",
                        },
                        itemStyle: {
                            color: "#DE5280",
                        },
                    },
                    {
                        type: "bar",
                        data: [0, 0, this.vitality[1].data.value, 0],
                        coordinateSystem: "polar",
                        name: `市区面积 ${(
                            (this.vitality[1].data.value /
                                this.vitality[0].data.value) *
                            100
                        ).toFixed(2)}%`,
                        roundCap: true, //柱状图末端呈现圆角
                        stack: "a",
                        emphasis: {
                            focus: "series",
                        },
                        itemStyle: {
                            color: "#17C093",
                        },
                    },
                    {
                        type: "bar",
                        data: [0, 0, 0, this.vitality[0].data.value],
                        coordinateSystem: "polar",
                        name: `全市面积 ${(
                            (this.vitality[0].data.value /
                                this.vitality[0].data.value) *
                            100
                        ).toFixed(2)}%`,
                        roundCap: true, //柱状图末端呈现圆角
                        stack: "a",
                        emphasis: {
                            focus: "series",
                        },
                        itemStyle: {
                            color: "#0A70F5",
                        },
                    },
                ],
            };
            option && myChart.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
.fundamentalsCantainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #0f0240;
    overflow-y: auto;
    .cityPortrait {
        width: 100%;
        padding: 0 15px;
        .subTitle {
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: space-between;
            background-color: #140b51;
            color: rgba(255, 255, 255, 0.1);
            font-weight: 600;
            align-items: center;
            font-size: 22px;
            padding: 0 19px;
            div {
                color: #fff;
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight: 400;
                background: linear-gradient(
                    90deg,
                    rgba(44, 51, 179, 0),
                    rgba(44, 51, 179, 1)
                );
                border-radius: 4px;
                height: 32px;
                width: 110px;
                position: relative;
                justify-content: center;
                div {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background: linear-gradient(
                        90deg,
                        rgba(21, 79, 194, 0),
                        rgba(21, 79, 194, 1)
                    );
                    position: absolute;
                    left: -16px;
                }
            }
        }
        .survey {
            color: #fff;
            font-size: 18px;
            width: 100%;
            text-align: center;
            margin: 20px 0 15px 0;
        }
        .labelAndimg {
            display: flex;
            justify-content: space-between;
            .labelBox {
                flex: 1;
                color: #c2cbff;
                font-size: 14px;
                div {
                    margin-top: 20px;
                    display: inline-block;
                    border-bottom: 4px solid #592da0;
                }
            }
            .cityImg {
                width: 210px;
                height: 210px;
                border-radius: 50%;
                background-color: #6e62e4;
                background: linear-gradient(
                    rgba(110, 98, 228, 1),
                    rgba(110, 98, 228, 0)
                );
                position: relative;
                .leftImg,
                .topImg,
                .rightImg,
                .bottomImg {
                    position: absolute;
                }
                .leftImg {
                    top: 44px;
                    left: 28px;
                }
                .topImg {
                    width: 23px;
                    height: 35px;
                    top: 10px;
                    left: 103px;
                    opacity: 0.5;
                }
                .rightImg {
                    top: 42px;
                    left: 150px;
                }
                .bottomImg {
                    top: 42px;
                    left: 18px;
                    img {
                        width: 168px;
                        height: 139px;
                    }
                }
            }
        }
        .cityDetailBox {
            display: flex;
            flex-direction: column;
            background: #0f0240;
            .cityDetail {
                width: 100%;
                height: 87px;
                border-radius: 4px;
                background: rgba(41, 32, 126, 0.5);
                padding: 0 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                .right {
                    .rightT {
                        color: #00ff00;
                        font-size: 22px;
                    }
                }
            }
        }
        .threeTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            font-size: 18px;
            margin: 20px 0;
            .titleL,
            .titleR {
                flex: 1;
                height: 3px;
            }
            .titleL {
                background: linear-gradient(
                    90deg,
                    rgba(110, 98, 228, 0),
                    rgba(110, 98, 228, 1)
                );
                margin-right: 10px;
            }
            .titleR {
                background: linear-gradient(
                    90deg,
                    rgba(110, 98, 228, 1),
                    rgba(110, 98, 228, 0)
                );
                margin-left: 10px;
            }
        }
        #urbanPopulationEcs {
            height: 250px;
        }
        #populationGrowthEcs {
            height: 305px;
        }
        #pupilNumGrowthRate {
            height: 260px;
        }
        .urbanVitalityBox {
            background: rgba(61, 49, 148, 0.3);
            border-radius: 4px;
            padding: 15px;
            margin-bottom: 25px;
            #urbanVitalityEcs {
                height: 260px;
                width: 100%;
            }
            .cityTitle {
                width: 100%;
                background: rgba(66, 60, 155, 1);
                border-radius: 8px;
                height: 75px;
                display: flex;
                padding: 0 15px;
                margin-top: 10px;
                justify-content: center;
                align-items: center;
                .cityTitleL {
                    img {
                        width: 50px;
                        height: 50px;
                    }
                }
                .cityTitleC {
                    flex: 1;
                    margin-left: 20px;
                }
                .cityTitleR {
                    display: flex;
                }
            }
        }
    }
}
.echartsMargin {
    margin-bottom: 25px;
    width: 100%;
    background: rgba(61, 49, 148, 0.3);
    border-radius: 4px;
    padding: 15px;
}
.oneTitleBox {
    width: 100%;
    height: 80px;
    .oneTitle {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        background-color: #0f0240;
        padding-left: 20px;
        color: #fff;
        font-size: 22px;
        .icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: linear-gradient(180deg, #7920da, #0d78fd);
            margin-right: 20px;
            .titleImg {
                // width: 70px;
                // height: 70px;
                margin-left: -15px;
                margin-top: -15px;
            }
        }
    }
}
</style>