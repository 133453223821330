<template>
  <div class="strictBox">
    <div class="header">
      <div class="inputBox">
        <input
          type="text"
          @change="inputChange"
          v-model.trim="inputValue"
          placeholder="请输入文档关键字"
        />
        <img src="../assets/document/search.png" />
      </div>
      <div class="selectBox">
        当前选项 :
        <div class="cilckSelect" @click="showSelect">
          {{ type }} <img src="../assets/document/bottom.png" />
        </div>
      </div>
    </div>
    <div class="main">
      <div class="selectContent" v-if="select">
        <div
          class="selectContentBox"
          v-for="item in docTypeList"
          :key="item.id"
        >
          <div class="selectContentImg">
            <img :src="item.logo" />
          </div>
          <div class="selectContentR">
            <div
              class="selectContentTitle"
              @click="selectType(item.id + item.name)"
            >
              {{ item.name }}
            </div>
            <div class="selectContents">
              <span
                v-for="i in item.subTypeList"
                :key="i.id"
                @click="selectType(i.id + item.name + '-' + i.name)"
                >{{ i.name }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="contentBox" v-for="(x, y) in choicenessList" :key="y">
        <div class="imgBox">
          <img v-lazy="x.surfaceUrl" :key="x.surfaceUrl" />
          <div class="hotImg" v-show="x.hotFlag">
            <img src="../assets/document/hotImg.png" />
          </div>
        </div>
        <div class="content" @click="goDetail(x)">
          <div class="title">{{ x.title }}</div>
          <div class="labelBox">
            <span>{{ x.docTypeName }}</span>
          </div>
          <div class="contentB">
            <div class="contentBL">
              <img src="../assets/document/eyes.png" />
              {{ x.browseCount }}
            </div>
            <div class="contentBR">上传时间 {{ x.uploadDate }}</div>
          </div>
        </div>
      </div>
      <div class="bottomText">{{ bottomValue }}</div>
    </div>
    <tabbar></tabbar>
  </div>
</template>
    
<script>
import { post } from "../config/request";
import Tabbar from "../components/tabbar.vue";

export default {
  components: {
    Tabbar,
  },
  data() {
    return {
      select: false,
      type: "选择分类",
      typeId: Number,
      inputValue: "",
      page: 1, //第几页
      docTypeList: [
        {
          id: 1000,
          logo: "",
          name: "",
          subTypeList: [{ id: 1001, name: "", parentId: 1000 }],
        },
      ], //分类列表
      choicenessList: [], //内容列表
      bottomValue: "加载中...",
    };
  },
  created() {},
  mounted() {
    var that = this;
    that.getList();
    that.getTypeList();
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 获取内容列表
    getList() {
      var that = this;
      post("content/choiceness", {
        currentPage: that.page,
        subDocTypeId: that.typeId,
        docTitle: that.inputValue,
      }).then((res) => {
        console.log(res);
        that.listenerFunction();
        that.choicenessList = [...that.choicenessList, ...res.data];
        if (res.data.length < 10) {
          that.bottomValue = "没有更多了哟...";
          return;
        }
      });
    },
    getTypeList() {
      var that = this;
      post("content/document_type").then((res) => {
        if (res.data) {
          res.data[res.data.length - 1].subTypeList.push({
            id: 0,
            name: "全部",
            parentId: 0,
          });
        }
        that.docTypeList = res.data;
      });
    },
    // 展开分类列表
    showSelect() {
      if (this.select == false) {
        this.select = true;
      } else {
        this.select = false;
      }
    },
    // 点击分类
    selectType(e) {
      this.bottomValue = "加载中...";
      this.type = e.slice(4);
      this.typeId = Number(e.slice(0, 4));
      this.choicenessList = [];
      this.getList();
      this.select = false;
    },
    // 搜索
    inputChange() {
      this.page = 1;
      this.choicenessList = [];
      this.getList();
    },
    goDetail(e) {
      window.RedirectTo("details", {
        baiDuDocId: e.baiDuDocId,
        documentId: e.id,
        documentTitle: e.title,
      });
    },
    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    // 获取元素总高度减去窗口高度
    getBoxHeight() {
      var height = document.querySelector(".strictBox").offsetHeight;
      var winHeight = document.documentElement.clientHeight;
      return height - winHeight;
    },
    // 获取滚动条滚动高度
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.getBoxHeight() - scrollTop == 0) {
        this.page++;
        this.getList();
      }
      return this.getBoxHeight() - scrollTop;
    },
  },
};
</script>

<style lang="scss" scoped>
.strictBox {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: #15162e;
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    .inputBox {
      position: relative;
      input {
        width: 100%;
        height: 40px;
        background: #1d2341;
        border-radius: 4px;
        outline: none;
        border: 1px solid rgba(68, 102, 142, 1);
        color: rgba(154, 161, 195, 1);
        font-size: 12px;
        text-indent: 44px;
      }
      input::placeholder {
        color: rgba(154, 161, 195, 1);
      }
      img {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 13px;
        left: 19px;
      }
    }
    .selectBox {
      color: #a7aed2;
      font-size: 12px;
      margin-top: 17px;
      display: flex;
      align-items: center;
      .cilckSelect {
        margin-left: 5px;
        display: flex;
        align-items: center;
        img {
          width: 18px;
          height: 18px;
          margin-left: 5px;
        }
      }
    }
  }
  .main {
    background: #1b1d3a;
    width: 100%;
    min-height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
    .contentBox {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      padding: 10px 0 10px 17px;
      box-shadow: -2px 6px 9px 0px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      .imgBox {
        width: 70px;
        height: 100px;
        position: relative;
        img {
          width: 70px;
          height: 100px;
        }
        .hotImg {
          width: 24px;
          height: 24px;
          position: absolute;
          top: 4px;
          left: 4px;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        .title {
          width: 100%;
          font-size: 16px;
          color: #fff;
          display: block;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          margin-bottom: 14px;
        }
        .labelBox {
          span {
            font-size: 12px;
            color: #a7aed2;
            background: rgba(167, 174, 210, 0.3);
            padding: 2px 5px;
            margin-right: 11px;
          }
        }
        .contentB {
          margin-top: 25px;
          display: flex;
          font-size: 12px;
          color: #9498a2;
          justify-content: space-between;
          .contentBL {
            img {
              width: 15px;
              height: 10px;
              margin-right: 10px;
            }
          }
        }
      }
    }
    .selectContent {
      width: 100%;
      background: #282b52;
      padding: 23px 13px 5px 13px;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1000;
      color: #fff;
      .selectContentBox {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        .selectContentImg {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .selectContentR {
          flex: 1;
          .selectContentTitle {
            font-size: 14px;
            margin-bottom: 10px;
            margin-top: 2px;
          }
          .selectContents {
            span {
              display: inline-block;
              color: #a7aed2;
              font-size: 12px;
              margin: 0px 10px 10px 0px;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .bottomText {
      width: 100%;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 50px;
      color: rgba(167, 174, 210, 1);
    }
  }
}
</style>