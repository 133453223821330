<template>
    <div class="catalogueCantainer">
        <div class="header">
            <div class="titleBox">
                <div class="title">
                    <!-- <div class="address">{{ address }}</div> -->
                    <form action="javascript:void 0">
                        <textarea
                            v-model="address"
                            class="address-text"
                            rows="3"
                            @keydown.enter.exact="SendEvent"
                        ></textarea>
                    </form>
                    <div class="range">统计周边 {{ range }} KM区域数据</div>
                </div>
                <img src="../assets/icon/mapLocation.png" lazy-load />
            </div>
        </div>
        <div class="main">
            <div class="catalogue1 catalogueOne">
                <div class="hexagon">
                    <img src="../assets/icon/hexagon.png" />
                </div>
                <div class="hexagon clickBox">
                    <img class="bgImg" src="../assets/icon/bghexagon.png" />
                    <img class="clickImg" src="../assets/icon/hexagon.png" />
                    <div
                        class="clickBoxContent"
                        @click="goReport('locationBox')"
                    >
                        <img src="../assets/icon/area.png" />
                        <div>区域界定</div>
                    </div>
                </div>
                <div class="hexagon">
                    <img src="../assets/icon/hexagon.png" />
                </div>
            </div>
            <div class="catalogue1 catalogueTwo">
                <div class="hexagon">
                    <img src="../assets/icon/hexagon.png" />
                </div>
                <div class="hexagon clickBox">
                    <img class="bgImg" src="../assets/icon/bghexagon.png" />
                    <img class="clickImg" src="../assets/icon/hexagon.png" />
                    <div
                        class="clickBoxContent"
                        @click="goReport('fundamentalsBox')"
                    >
                        <img src="../assets/icon/city.png" />
                        <div>城市概况</div>
                    </div>
                </div>
                <div class="hexagon clickBox">
                    <img class="bgImg" src="../assets/icon/bghexagon.png" />
                    <img class="clickImg" src="../assets/icon/hexagon.png" />
                    <div
                        class="clickBoxContent"
                        @click="goReport('urbanResourcesBox')"
                    >
                        <img src="../assets/icon/resources.png" />
                        <div>区域资源</div>
                    </div>
                </div>
                <div class="hexagon">
                    <img src="../assets/icon/hexagon.png" />
                </div>
            </div>
            <div class="catalogue1 catalogueThree">
                <div class="hexagon">
                    <img src="../assets/icon/hexagon.png" />
                </div>
                <div class="hexagon clickBox">
                    <img class="bgImg" src="../assets/icon/bghexagon.png" />
                    <img class="clickImg" src="../assets/icon/hexagon.png" />
                    <div
                        class="clickBoxContent"
                        @click="goReport('peripheralMarketBox')"
                    >
                        <img src="../assets/icon/market.png" />
                        <div>区域市场</div>
                    </div>
                </div>
                <div class="hexagon">
                    <img src="../assets/icon/hexagon.png" />
                </div>
            </div>
            <div class="catalogue1 catalogueFour">
                <div class="hexagon">
                    <img src="../assets/icon/hexagon.png" />
                </div>
                <div class="hexagon clickBox">
                    <img class="bgImg" src="../assets/icon/bghexagon.png" />
                    <img class="clickImg" src="../assets/icon/hexagon.png" />
                    <div
                        class="clickBoxContent"
                        @click="goReport('populationBox')"
                    >
                        <img src="../assets/icon/customers.png" />
                        <div>区域人口</div>
                    </div>
                </div>
                <div class="hexagon clickBox">
                    <img class="bgImg" src="../assets/icon/bghexagon.png" />
                    <img class="clickImg" src="../assets/icon/hexagon.png" />
                    <div
                        class="clickBoxContent"
                        @click="goReport('summaryBox')"
                    >
                        <img src="../assets/icon/evaluate.png" />
                        <div>区域小结</div>
                    </div>
                </div>
                <div class="hexagon">
                    <img src="../assets/icon/hexagon.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { post } from "../config/request";
export default {
    data() {
        return {
            address: "高新区武侯大道1600号",
            range: "2",
            id: 0,
        };
    },
    created() {        
        this.id = this.$route.params.id;//window.GetParam("id");
        this.getCatalogue();
    },
    methods: {
        getCatalogue() {
            post("account/catalogue", {
                analysisId: this.id,
            }).then((res) => {
                this.address = res.data.addr;
                this.range =
                    res.data.area == 0 ? res.data.radius : res.data.area;
                window.SetAreaInfo(res.data);
            });
        },
        goReport(e) {
            window.RedirectTo("report", { id: this.id, index: e });
        },
        SendEvent(e) {
            if (window.event) {
                window.event.returnValue = false;
            } else {
                e.preventDefault();
            }
            this.sendtext();
        },
        sendtext() {
            post("account/update_analysis_addr", {
                analysisId: this.id,
                addr: this.address,
            }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: "修改成功",
                        type: "success",
                    });
                    window.pageEvent.send("updateUserInfo", "");
                } else {
                    this.$message.error("修改失败");
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.catalogueCantainer {
    width: 100vw;
    min-height: 100vh;
    background-color: #2f3745;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .header {
        z-index: 1000;
        width: 100vw;
        height: 30vh;
        background-color: #2f3745;
        .titleBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            color: #fff;
            margin-top: 20px;
            .title {
                display: flex;
                flex-direction: column;

                .address {
                    font-size: 18px;
                    margin-bottom: 14px;
                    display: block;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 3;
                }

                .address-text {
                    overflow: hidden;
                    width: 65vw;
                    font-size: 18px;
                    background: #2f3745;
                    color: #fff;
                    resize: none;
                }
                .range {
                    font-size: 14px;
                    color: #b4d7ff;
                }
            }

            img {
                width: 90px;
                height: 90px;
            }
        }
    }
    .main {
        flex: 1;
        z-index: 10000;
        width: 100vw;
        background-color: #33394d;
        .catalogue1 {
            display: flex;
            overflow-x: hidden;
            margin-top: -5.8vh;
            overflow-y: hidden;
            .hexagon {
                width: 33vw;
                height: 38vw;
                margin-left: 0.4rem;
                img {
                    width: 33vw;
                    height: 38vw;
                }
            }
            .clickBox {
                width: 36vw;
                height: 40vw;
                position: relative;
                .bgImg {
                    width: 36vw;
                    height: 40vw;
                }
                .clickImg {
                    width: 31.5vw;
                    height: 35vw;
                    position: absolute;
                    left: 2.25vw;
                    top: 2.4vw;
                }
                .clickBoxContent {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    z-index: 100;
                    color: #fff;
                    justify-content: center;
                    align-items: center;
                    top: 1rem;
                    left: 5vw;
                    img {
                        width: 25.7vw;
                        height: 25.7vw;
                    }
                    div {
                        font-size: 14px;
                        margin-top: -4.4vw;
                    }
                }
            }
        }
        .catalogueOne {
            .hexagon {
                margin-top: 0.9vw;
            }
            .clickBox {
                margin-top: 0;
            }
        }
        .catalogueTwo {
            margin-left: -18vw;
            margin-top: -8.8vw;
            overflow-y: hidden;
            .hexagon {
                margin-top: 0.9vw;
            }
            .clickBox {
                margin-top: 0;
            }
        }
        .catalogueThree {
            margin-top: -8.8vw;
            .hexagon {
                margin-top: 0.9vw;
            }
            .clickBox {
                margin-top: 0;
            }
        }
        .catalogueFour {
            margin-left: -18vw;
            margin-top: -8.8vw;
            .hexagon {
                margin-top: 0.9vw;
            }
            .clickBox {
                margin-top: 0;
            }
        }
    }
}
</style>