<template>
  <div class="containerp">
    <div class="oneTitleBox">
      <div class="oneTitle">
        <div class="icon">
          <img class="titleImg" src="../assets/icon/customers.png" />
        </div>
        区域人口
      </div>
    </div>
    <div class="live">
      <div class="live-title">居住人口</div>
      <div class="map">
        <div id="livePople" style="width: 100vw; height: 297px"></div>
      </div>
      <div class="peopleForm">
        <div class="formLive">
          <table class="table-form">
            <thead style="background: rgba(59, 33, 131, 0.3)">
              <tr>
                <th>TOP3</th>
                <th>小区名称</th>
                <th>人数（人）</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in this.houseTop" :key="index" @click="clickTitle(item)">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.number }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="analysis">
        <div class="imga-box">
          <img
            src="../assets/population/people.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="analysis-text">
          <div>区域内小区数量</div>
          <div style="margin-top: 7px">区域内居住人口</div>
        </div>
        <div class="text2">
          <div>{{ this.houseList }}<span style="font-size: 15px">个</span></div>
          <div>
            {{ Math.round(this.areaList.house / 100) * 100 }}<span style="font-size: 15px">人</span>
          </div>
        </div>
      </div>
      <div class="gender">
        <div class="gender-text">居住人口性别人数</div>
        <div class="woman">
          <img
            src="../assets/population/woman.png"
            style="width: 112px; height: 105px"
          />
          <div class="gender-number">女性人数</div>
          <div class="woman-text">{{ this.female }}</div>
        </div>
        <div class="man">
          <img
            src="../assets/population/man.png"
            style="width: 107px; height: 113px"
          />
          <div class="gender-number">男性人数</div>
          <div class="man-text">{{ this.male}}</div>
        </div>
        <div class="vs">vs</div>
      </div>
      <div class="age">
        <div id="ages" style="width: 100%; height: 250px"></div>
      </div>
    </div>
    <div class="purchase">
      <div class="purchase-text">购买力指数</div>
      <div class="buy">
        <div id="buyIndex" style="width: 100vw; height: 298px"></div>
      </div>
      <div class="buybox">
        <div class="box-title">
          区域平均购买力指数：{{ Math.round(this.housePopBuyMeter.curr *100) /100 }}
        </div>
        <div id="buyCharts" style="width: 100%; height: 177px"></div>
        <div class="box-text">超过全市{{ exceed }}%</div>
        <div class="buyBox-line">
        </div>
      </div>
    </div>
    <div class="officep">
      <div class="officep-text">办公人口</div>
      <div class="map">
        <div id="officePople" style="width: 100vw; height: 297px"></div>
      </div>
      <div class="peopleForm">
        <div class="formLive">
          <table class="table-form">
            <thead style="background: rgba(59, 33, 131, 0.3)">
              <tr>
                <th>TOP3</th>
                <th>写字楼名称</th>
                <th>人数（人）</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in this.officeTop" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.number }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="analysis">
        <div class="imga-box">
          <img
            src="../assets/population/people2.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="analysis-text">
          <div>区域内办公数量</div>
          <div style="margin-top: 7px">区域内办公人口</div>
        </div>
        <div class="text2">
          <div>
            {{ this.officeList }}<span style="font-size: 15px">个</span>
          </div>
          <div>
            {{ Math.round(this.areaList.office / 100) * 100 }}<span style="font-size: 15px">人</span>
          </div>
        </div>
      </div>
      <div class="school">
        <div class="school-title">
          <div class="school-text">区域内高校人口</div>
        </div>
        <div class="school-box">
          <div class="school-img">
            <img
              src="../assets/population/people3.png"
              style="width: 100%; height: 100%"
            />
          </div>
          <div class="school-text">区域内高校人口</div>
          <div class="school-text2">
            {{ Math.round(this.areaList.school / 100) * 100 }}<span style="font-size: 15px">人</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { post } from "../config/request";
import { EyesMap } from "../config/showMap.js"; // 引入地图
export default {
  data() {
    return {
      areaList: {}, //区域人口数（住宅、办公、学校）
      male: null, //男性人数
      female:null,//女性人数
      ageData: [], //区域人口年龄分布数据
      houseTop: [], //居住人口小区Top3
      officeTop: [], //办公人口小区Top3
      housePopBuyMeter: {}, //区域购买指数数据
      proportion: "", //购买力指数占比
      houseList: "", //区域内小区个数
      officeList: "", //区域内办公个数
      housePopBuyList:[],//区域购买力指数
      buyMin:null,//购买指数最小值
      buyMax:null,//购买指数最大值
      buyInterval:null,//购买指数间值
      buyFixed1:null,//购买指数间值第一个值
      buyFixed1:null,//购买指数间值第二个值
      clickName:null,
      exceed:'',//超过全市占比
    };
  },
  mounted() {
    this.analysisId = window.GetAreaInfo("id");
    setTimeout((e) => {
      document.getElementById("populationBox").style.height = "auto";
    }, 500);
    var that = this;
    post("/content/population", { analysisId: that.analysisId }).then((res) => {
      console.log(res);
      if (res.code == 0) {
        that.areaList = res.data.areaPop
        that.houseList = res.data.housePopList.length;
        that.officeList = res.data.officePopList.length;
        that.female = Math.round(res.data.housePopTotal.female / 100) * 100 ;
        that.male = Math.round(res.data.housePopTotal.male / 100) * 100 ;
        that.ageData = res.data.housePopAgeSection.map((i) => Math.round(i.count /100) *100 );
        that.houseTop = res.data.housePopList.slice(0, 3);
        that.houseTop = that.houseTop.map((i) => {
          return {name:i.name,number:Math.round(i.number / 100) *100,point:i.point};
        });
        that.officeTop = res.data.officePopList.slice(0, 3);
        that.officeTop = that.officeTop.map((i) => {
          return {name:i.name,number:Math.round(i.number / 100) *100 };
        })
        that.housePopBuyMeter = res.data.housePopBuyMeter;
        that.housePopBuyList = res.data.housePopBuyList;
        //处理购买力指数数据
        that.buyMin = parseFloat(Math.round(res.data.housePopBuyMeter.min *100) /100);
        that.buyMax = parseFloat(Math.round(res.data.housePopBuyMeter.max *100) /100);
        that.buyInterval = parseFloat(Math.round((that.buyMax-that.buyMin)/3 *100)/100);
        that.buyFixed1 =  that.buyMin + parseFloat((that.buyMax-that.buyMin)/3 );
        that.buyFixed1 = parseFloat(that.buyFixed1.toFixed(10));
        that.buyFixed2 =  that.buyMax - parseFloat((that.buyMax-that.buyMin)/3 );
        that.buyFixed2 = parseFloat(that.buyFixed2.toFixed(10));
        that.housePopBuyList.forEach(tem => {
          if(that.buyMin <= parseFloat(tem.buyIndex) && parseFloat(tem.buyIndex) < that.buyMin + that.buyInterval){
            tem.color = "#17B5BB"
          }else if(that.buyMin + that.buyInterval <= parseFloat(tem.buyIndex) && parseFloat(tem.buyIndex) < that.buyMax - that.buyInterval ){
            tem.color = "#2947F4"
          }else if(that.buyMax - that.buyInterval <= parseFloat(tem.buyIndex) && parseFloat(tem.buyIndex) <= that.buyMax){
            tem.color = "#B81BD5"
          }
        });
        //处理超过全市占比数据
        that.exceed = Math.round((res.data.housePopBuyMeter.exceed *1000)/10)
        //初始化地图
        this.initMap(
          res.data.housePopList,
          res.data.officePopList,
          that.housePopBuyList
        );
        this.getAgechart();
        this.getBuyChart();
      } else {
        return (res.data = undefined);
      }
    });
  },
  methods: {
    initMap(data, data2, buy) {
      var that = this;
      var areaInfo = window.GetAreaInfo();
      EyesMap.InitMap("livePople", (map) => {
        EyesMap.ShowArea(map, areaInfo);
        EyesMap.ShowCircleMarker(map, data);
      });
      EyesMap.InitMap("officePople", (map) => {
        EyesMap.ShowArea(map, areaInfo);
        EyesMap.ShowCircleMarker(map, data2);
      });
      EyesMap.InitMap("buyIndex", (map) => {
        EyesMap.ShowArea(map, areaInfo);
        EyesMap.ShowCircleMarker(map, buy);
      });
    },
    clickTitle(t){
      if(this.clickName == t.name) return;
      this.clickName = t.name;
      // this.developmentMap();
    },
    getAgechart() {
      var that = this;
      var chartDom = document.getElementById("ages");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: "居住人口年龄分布",
          textStyle: {
            color: "#FEFEFF",
            fontSize: 14,
            fontWeight: "bolder",
          },
          y: 10,
          x: 10,
        },
        grid: {
          // top: "25%",
          left: "15%",
          // right: "8%",
          bottom: "15%",
          containLable: true,
        },
        color: "#2C33B3",
        legend: {
          data: ["人数（人）"],
          right: "9",
          top: "20",
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            color: "#C2CBFF",
            fontSize: "9",
          },
        },
        xAxis: {
          type: "category",
          data: ["0-14岁", "15-59岁", "60岁以上"],
          axisLine: {
            lineStyle: {
              color: "#3B2183",
            },
          },
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {
            // color:'#FEFEFF',
            color: "#423C9B",
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
            color: "#423C9B",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#3B2183"], //网格线颜色
              width: 1,
            },
          },
        },
        series: [
          {
            name: "人数（人）",
            data: that.ageData,
            type: "bar",
            barWidth: "40",
            itemStyle: {
              color: "#2C33B3", //柱子颜色
            },
            label: {
              show: true,
              position: "top", //头上显示数据
              color: "#C2CBFF", //显示文字颜色
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
    getBuyChart() {
      var that = this;
      var chartDom = document.getElementById("buyCharts");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },
        series: [
          {
            name: "Pressure",
            type: "gauge",
            min: that.buyMin,
            max: that.buyMax,
            splitNumber: 3,
            startAngle: 180,
            endAngle: 0,
            center: ["50%", "75%"],
            radius: "130%",
            progress: {
              show: true,
              width: 18,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 18,
                color: [
                  [0.3333, "#17B5BB"],
                  [0.6667, "#2947F4"],
                  [1, "#B81BD5"],
                ],
              },
            },
            progress: {
              show: false,
              width: 8,
              itemStyle: {
                color: "#1F36B3",
                borderWidth: 1,
              },
            },
            axisLabel: {
              show: true,
              color: '#FEFEFF',
              fontSize: 15,
              distance: -50,
              formatter: function (value) {
                const buymin = that.buyMin.toString(); 
                const buymax = that.buyMax.toString();
                const buyInterval1 = Math.round(that.buyFixed1 *100) / 100; 
                const buyInterval2 = Math.round(that.buyFixed2 *100) / 100; 
                if (value === that.buyMin) {
                    return buymin;
                }
                else if (value === that.buyMax) {
                    return buymax;
                }
                else if (value === that.buyFixed1) {
                    return buyInterval1;
                }
                else if (value === that.buyFixed2) {
                    return buyInterval2;
                }
              }
            },
            splitLine: {
              show: false,
            },
            pointer: {
              show: true,
              width: 3,
              length: "30%",
              offsetCenter: [0, "-50%"],
            },
            detail: {
              formatter: "{value}",
              color: "#FEFEFF",
              fontSize: 20,
              offsetCenter: [0, "-10%"],
            },
            data: [
              {
                value: Math.round(that.housePopBuyMeter.curr *100) /100 ,
                name: "购买力指数",
              },
            ],
            //仪表盘标题颜色
            title: {
              color: "#423C9B",
              fontSize: 12,
              offsetCenter: [0, "-30%"],
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.containerp {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #0f0240;
  .mapBox {
    width: 100%;
    height: 322px;
  }
}
.oneTitleBox {
  width: 100%;
  height: 80px;
  .oneTitle {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    background-color: #0f0240;
    padding-left: 20px;
    color: #fff;
    font-size: 22px;
    .icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: linear-gradient(180deg, #7920da, #0d78fd);
      margin-right: 20px;
      .titleImg {
        margin-left: -15px;
        margin-top: -15px;
      }
    }
  }
}
.live-title {
  font-size: 14px;
  color: #fefeff;
  margin-left: 30px;
  margin-top: 10px;
}
.map {
  width: 100%;
  height: 297px;
  margin-top: 21px;
}
.peopleForm {
  background: rgba(61, 49, 148, 0.3);
  margin: 0 13px 0px 13px;
  padding-top: 30px;
  .formLive {
    padding: 0 17px 11px 17px;
    height: 100%;
    table {
      width: 100%;
      border-collapse: collapse;
      th,
      td {
        border: 2px solid #3b2183;
        text-align: center;
        color: #ffffff;
        font-size: 15px;
        height: 40px;
        width: auto;
        position: relative;
        .sanJia {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-left: 15px;
          margin-right: 17px;
          position: absolute;
          top: 14px;
        }
      }
      .th-head {
        display: flex;
        padding-left: 42px;
      }
      .tdBox {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.analysis {
  background: rgba(97, 82, 208, 0.6);
  height: 72px;
  margin: 0 13px 10px 13px;
  border-radius: 0 0 4px 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .imga-box {
    width: 38px;
    height: 43px;
    margin-left: 10px;
  }
  .analysis-text {
    font-size: 15px;
    color: rgba(194, 203, 255, 1);
  }
  .text2 {
    min-width: 20%;
    font-size: 21px;
    color: #ffffff;
    margin-right: 15px;
  }
}
.school {
  background: rgba(61, 49, 148, 0.3);
  height: 124px;
  margin: 0 13px 0 13px;
  .school-title {
    width: 100%;
    padding-top: 20px;
    .school-text {
      margin-left: 16px;
      font-size: 14px;
      color: #fefeff;
    }
  }
  .school-box {
    background: rgba(97, 82, 208, 0.6);
    height: 72px;
    margin: 10px 0 0 0;
    border-radius: 0 0 4px 4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .school-img {
      width: 38px;
      height: 43px;
      margin-left: 10px;
    }
    .school-text {
      font-size: 15px;
      color: rgba(194, 203, 255, 1);
    }
    .school-text2 {
      font-size: 21px;
      color: #ffffff;
      margin-right: 45px;
    }
  }
}
.gender {
  height: 214px;
  background: rgba(61, 49, 148, 0.3);
  margin: 0 13px 25px 13px;
  padding-top: 19px;
  padding-left: 16px;
  position: relative;
  border-radius: 4px;
  .gender-text {
    color: #fefeff;
    font-size: 14px;
  }
  .woman {
    position: absolute;
    bottom: 25px;
    left: 35px;
    .woman-text {
      position: absolute;
      bottom: 76px;
      left: 47px;
      font-size: 15px;
      color: #ffffff;
      width: 54px;
      text-align: center;
    }
  }
  .man {
    position: absolute;
    bottom: 25px;
    right: 35px;
    .man-text {
      position: absolute;
      bottom: 78px;
      right: 42px;
      font-size: 15px;
      color: #ffffff;
      width: 54px;
      text-align: center;
    }
  }
  .gender-number {
    text-align: center;
    font-size: 12px;
    color: #ffffff;
    margin-top: 5px;
    line-height: 12px;
  }
  .vs {
    position: absolute;
    color: #ffffff;
    left: 48%;
    top: 90px;
    font-size: 20px;
  }
}
.age {
  height: 250px;
  background: rgba(61, 49, 148, 0.3);
  margin: 0 13px 17px 13px;
}
.purchase {
  .purchase-text {
    color: #fefeff;
    margin-left: 30px;
    font-size: 14px;
  }
  .buy {
    height: 298px;
    margin-top: 15px;
  }
  .buy-circular {
    width: 255px;
    height: 254px;
    background: rgba(219, 105, 180, 0.03);
    border: 2px solid rgba(219, 105, 180, 0.51);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .spot {
    width: 55px;
    height: 62px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .village {
    width: 89px;
    // height: 46px;
    position: absolute;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -70%);
    background: #e33b7b;
    font-size: 11px;
    color: #ffffff;
    text-align: center;
    align-items: center;
    padding: 2px 2px 5px 2px;
  }
  .bubbleTail {
    position: absolute;
    top: 100%;
    left: 42%;
    width: 0;
    height: 0;
    border-width: 7px;
    border-style: solid;
    border-color: transparent;
    border-top-width: 6px;
    border-top-color: currentColor;
    color: #e33b7b;
  }
  .buybox {
    height: 210px;
    background: rgba(61, 49, 148, 0.3);
    margin: 0 13px 0 13px;
    padding-top: 19px;
    position: relative;
    .box-title {
      color: #fefeff;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
    }
    .box-text {
      width: 100%;
      text-align: center;
      color: #ffffff;
      font-size: 12px;
      position: absolute;
      bottom: 11px;
    }
    .buyBox-line {
      width: 90%;
      height: 2px;
      background: #2c33b3;
      position: absolute;
      bottom: 35px;
      left: 50%;
      transform: translate(-50%, -50%);
      .scaleN {
        position: absolute;
        top: -19px;
        left: 8%;
        font-size: 12px;
        color: #fefeff;
        transform: translate(-50%, 0%);
      }
      .scaleN2 {
        position: absolute;
        top: -19px;
        left: 91%;
        font-size: 12px;
        color: #fefeff;
        transform: translate(-50%, 0%);
      }
    }
  }
}
.officep {
  margin-top: 20px;
  .officep-text {
    color: #fefeff;
    margin-left: 30px;
    font-size: 14px;
  }
}
</style>