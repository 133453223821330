<template>
    <div class="homeBox">
        <div class="oneTitleBox">
            <div class="oneTitle">
                <div class="icon">
                    <img class="titleImg" src="../assets/icon/area.png" />
                </div>
                区域界定
            </div>
        </div>
        <div class="mapBox" style="height: 400px; width: 100%">
            <div id="reportMap" style="height: 400px; width: 100%"></div>
        </div>
    </div>
</template>

<script>
import { EyesMap } from "../config/showMap.js"; // 引入地图
import { post } from "../config/request";
export default {
    data() {
        return {
            ecsTextColor: "rgba(66, 60, 155, 1)", //echarts图表文字颜色
            lng: 104.07163, //本案经度
            lat: 30.54638, //本案纬度104.07163,30.54638
            localHost: "高新区府城大道1600号", //本案地址
            radius: 5000, //查询半径
            city: "成都", //当前城市
            cityPoiArr: [], //本案区位坐标点数组
            id: 0,
        };
    },
    mounted() {
        setTimeout((e) => {
            document.getElementById("locationBox").style.height = "auto";
        }, 500);
    },
    created() {
        document.title = "区域分析";
        this.id = window.GetAreaInfo("id");        
        this.getPoi();
    },
    methods: {
        // 获取本案及区位POI数据
        getPoi() {
            var that = this;
            post("content/getPOI", {
                analysisId: that.id,
            }).then((res) => {
                var areaInfo = res.data.currentCenter[0];
                window.SetAreaInfo(areaInfo);
                that.cityPoiArr = res.data.POIList.map((item) => {
                    return item;
                });
                that.localHost = res.data.currentCenter[0].addr;
                that.lng = res.data.currentCenter[0].center[0];
                that.lat = res.data.currentCenter[0].center[1];
                that.initMap();
            });
        },
        // 本案区位地图
        initMap() {
            var that = this;
            var areaInfo = window.GetAreaInfo();
            EyesMap.InitMap("reportMap", (map) => {                
                EyesMap.ShowBenAnMarker(map, areaInfo);
                EyesMap.ShowLabel(map, that.cityPoiArr);
                EyesMap.ShowLine(map, areaInfo.center, that.cityPoiArr);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.homeBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #0f0240;
    overflow-y: auto;
    .catalogueBox {
        position: fixed;
        right: 30px;
        bottom: 60px;
        width: 35px;
        height: 35px;
        background: linear-gradient(49deg, #3995fc, #00cffd);
        border-radius: 5px;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 30px;
            height: 30px;
        }
    }
}
.amap-copyright {
    top: 3px;
}
.oneTitleBox {
    width: 100%;
    height: 80px;
    .oneTitle {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        background-color: #0f0240;
        padding-left: 20px;
        color: #fff;
        font-size: 22px;
        .icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: linear-gradient(180deg, #7920da, #0d78fd);
            margin-right: 20px;
            .titleImg {
                // width: 70px;
                // height: 70px;
                margin-left: -15px;
                margin-top: -15px;
            }
        }
    }
}
</style>

