<template>
  <div class="collectionBox">
    <div class="header">
      <div class="inputBox">
        <input
          type="text"
          @change="inputChange"
          v-model.trim="inputValue"
          placeholder="请输入文档关键字"
        />
        <img src="../assets/document/search.png" />
      </div>
    </div>
    <div class="main">
      <div
        name="contentBox"
        v-for="(item, index) in choicenessList"
        :key="index"
      >
        <div class="imgBox">
          <img :src="item.surfaceUrl" />
          <div class="hotImg" v-show="item.hotFlag">
            <img src="../assets/document/hotImg.png" />
          </div>
        </div>
        <div class="content" @click="toDetails(item)">
          <div class="title">{{ item.title }}</div>
          <div class="labelBox">
            <span>{{ item.docTypeName }}</span>
          </div>
          <div class="contentB">
            <div class="contentBL">
              <img src="../assets/document/eyes.png" />
              {{ item.browseCount }}
            </div>
            <div class="contentBR">上传时间 {{ item.uploadDate }}</div>
          </div>
        </div>
        <div class="deleteBtnBox" @click="deleteContent(item.id)">
          <div class="deleteBtn f16 cFFF">删除</div>
        </div>
      </div>
      <div class="bottomText">{{ bottomValue }}</div>
    </div>
  </div>
</template>
    
<script>
import { post } from "../config/request";
export default {
  data() {
    return {
      bottomValue: "加载中...",
      inputValue: "",
      page: 1, //第几页
      choicenessList: [], //内容列表
    };
  },
  created() {
    document.title = "我的收藏";
  },
  mounted() {
    this.getList();
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    getList() {
      post("content/my_collection", {
        currentPage: this.page,
        docTitle: this.inputValue,
      }).then((res) => {
        console.log(res);
        this.listenerFunction();
        this.choicenessList = [...this.choicenessList, ...res.data];
        setTimeout(() => {
          this.touchs();
        }, 1000);
        if (res.data.length < 10) {
          this.bottomValue = "没有更多了哟...";
        }
      });
    },
    toDetails(e) {
      window.RedirectTo("details", {
        baiDuDocId: e.baiDuDocId,
        documentId: e.id,
        documentTitle: e.title,
      });
    },
    deleteContent(e) {
      console.log(e);
      post("content/delete_collection", {
        documentId: e,
      }).then((res) => {
        if (res.code == 0) {
          this.choicenessList.some((item, i) => {
            if (item.id == e) {
              this.choicenessList.splice(i, 1);
              return true;
            }
          });
          var container = document.getElementsByName("contentBox");
          for (var i = 0; i < container.length; i++) {
            container[i].className = "";
          }
          window.ShowMsg("删除成功！", "success");
          window.pageEvent.send("updateUserInfo", "");
        } else {
          window.ShowMsg("删除失败，请检查网络或稍后再试！", "success");
        }
      });
    },
    inputChange() {
      this.page = 1;
      this.choicenessList = [];
      this.getList();
    },
    // 侧滑事件
    touchs() {
      //侧滑显示删除按钮
      var expansion = null; //是否存在展开的list
      var container = document.getElementsByName("contentBox");
      for (var i = 0; i < container.length; i++) {
        var x, y, X, Y, swipeX, swipeY;
        container[i].addEventListener("touchstart", function (event) {
          x = event.changedTouches[0].pageX;
          y = event.changedTouches[0].pageY;
          swipeX = true;
          swipeY = true;
        });
        container[i].addEventListener("touchmove", function (event) {
          X = event.changedTouches[0].pageX;
          Y = event.changedTouches[0].pageY;
          if (expansion) {
            //判断是否展开，如果展开则收起
            expansion.className = "";
          }
          // 左右滑动
          if (swipeX && Math.abs(X - x) - Math.abs(Y - y) > 0) {
            // 阻止事件冒泡
            event.stopPropagation();
            if (X - x > 20) {
              //右滑
              event.preventDefault();
              this.className = ""; //右滑收起
            }
            if (x - X > 20) {
              //左滑
              event.preventDefault();
              this.className = "swipeleft"; //左滑展开
              expansion = this;
            }
            swipeY = false;
          }
          // 上下滑动
          if (swipeY && Math.abs(X - x) - Math.abs(Y - y) < 20) {
            swipeX = false;
          }
        });
      }
    },
    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    // 获取元素总高度减去窗口高度
    getBoxHeight() {
      var height = document.querySelector(".collectionBox").offsetHeight;
      var winHeight = document.documentElement.clientHeight;
      return height - winHeight;
    },
    // 获取滚动条剩余高度
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.getBoxHeight() - scrollTop == 0) {
        this.page++;
        this.getList();
      }
      return this.getBoxHeight() - scrollTop;
    },
  },
};
</script>

<style lang="scss" scoped>
.collectionBox {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: #15162e;
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    .inputBox {
      position: relative;
      input {
        width: 100%;
        height: 40px;
        background: #1d2341;
        border-radius: 4px;
        outline: none;
        border: 1px solid rgba(68, 102, 142, 1);
        color: rgba(154, 161, 195, 1);
        font-size: 12px;
        text-indent: 44px;
      }
      input::placeholder {
        color: rgba(154, 161, 195, 1);
      }
      img {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 13px;
        left: 19px;
      }
    }
  }
  .main {
    background: #1b1d3a;
    width: 100%;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
    [name="contentBox"] {
      margin: 0 15px 15px 15px;
      display: flex;
      justify-content: space-between;
      padding-left: 17px;
      box-shadow: -2px 6px 9px 0px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      position: relative;
      background: rgba(61, 49, 148, 0.3);
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      .imgBox {
        width: 70px;
        height: 100px;
        position: relative;
        margin: 10px 0;
        img {
          width: 70px;
          height: 100px;
        }
        .hotImg {
          width: 24px;
          height: 24px;
          position: absolute;
          top: 4px;
          left: 4px;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        margin: 10px 15px 10px 15px;
        .title {
          width: 100%;
          font-size: 16px;
          color: #fff;
          display: block;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          margin-bottom: 14px;
        }
        .labelBox {
          span {
            font-size: 12px;
            color: #a7aed2;
            background: rgba(167, 174, 210, 0.3);
            padding: 2px 5px;
            margin-right: 11px;
          }
        }
        .contentB {
          margin-top: 25px;
          display: flex;
          font-size: 12px;
          color: #9498a2;
          justify-content: space-between;
          .contentBL {
            img {
              width: 15px;
              height: 10px;
              margin-right: 10px;
            }
          }
        }
      }
      .deleteBtnBox {
        height: 100%;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(44, 51, 179, 0.7);
        border-radius: 4px;
        position: absolute;
        top: 0;
        right: -75px;
      }
    }
    // .swiperight {
    //   transform: translate3d(0, 0, 0);
    //   .deleteBtnBox {
    //     right: -75px;
    //   }
    // }
    .swipeleft {
      transform: translate3d(-60px, 0, 0);
      .deleteBtnBox {
        right: -60px;
      }
    }
    .bottomText {
      width: 100%;
      margin-bottom: 50px;
      text-align: center;
      color: rgba(167, 174, 210, 1);
    }
  }
}
</style>