<template>
    <div class="containerBox">
        <div class="oneTitleBox">
            <div class="oneTitle">
                <div class="icon">
                    <img class="titleImg" src="../assets/icon/market.png" />
                </div>
                区域市场
            </div>
        </div>
        <div class="threeTitle">
            <div class="titleL"></div>
            土地市场
            <div class="titleR"></div>
        </div>
        <div class="explain">数据说明：统计招拍挂、商住用地</div>
        <div class="ecsBox">
            <div id="landSupply" class="divStyle"></div>
            <div class="divStyle topOne">
                <div class="topOneTitle titleStyle">
                    历史最高名义成交楼面地价
                </div>
                <div class="topOneNum">
                    <img src="../assets/icon/peripheral/topOneNum.png" />
                    <div class="num">
                        {{ parseInt(maxLandFloorPrice.maxLandFloorPrice) }}
                        <span style="font-size: 12px; color: #ffffff"
                            >元/㎡</span
                        >
                    </div>
                </div>
                <div class="explainBox">
                    数据说明：截止{{ maxLandFloorPrice.endTime }}
                </div>
            </div>
            <div class="uncultivated divStyle">
                <div class="titleStyle">区域未开发土地</div>
                <div id="uncultivatedBox">
                    <div class="left">
                        <img
                            src="../assets/icon/peripheral/uncultivatedOne.png"
                        />
                        <div class="num">
                            {{ unDevelopedLandCount.res.count }}<span> 宗</span>
                        </div>
                        <div class="bottomText">未开发土地宗数</div>
                    </div>
                    <div class="right">
                        <img
                            src="../assets/icon/peripheral/uncultivatedTwo.png"
                        />
                        <div class="num">
                            {{ unDevelopedLandArea }}<span> 亩</span>
                        </div>
                        <div class="bottomText">未开发土地面积</div>
                    </div>
                </div>
                <div class="explainBox">
                    数据说明：截止{{ unDevelopedLandCount.endTime }}
                </div>
            </div>
        </div>
        <div class="threeTitle">
            <div class="titleL"></div>
            住宅市场
            <div class="titleR"></div>
        </div>
        <div class="explain">数据说明：量价统计仅统计商品住宅+保障房</div>
        <div class="mapTitle">
            <div class="titleStyle">新房项目分布</div>
            <div class="mapLegend">
                <div class="mapLegendL">
                    <div></div>
                    在售
                </div>
                <div class="mapLegendR">
                    <div></div>
                    未售
                </div>
            </div>
        </div>
        <div class="mapBox" style="width: 100%; height: 297px">
            <div id="newHouse" style="width: 100%; height: 297px"></div>
        </div>
        <div class="mapTitle" style="margin: 30px 0 20px 0">
            <div class="titleStyle">区域品牌房企开发情况</div>
        </div>
        <div class="mapBox" style="width: 100%; height: 297px">
            <div id="development" style="width: 100%; height: 297px"></div>
        </div>
        <div class="ecsBox">
            <div class="divStyle developmentTable">
                <table width="100%">
                    <tr>
                        <td rowspan="2">企业</td>
                        <td>呈现项目</td>
                        <td colspan="2">储备土地</td>
                    </tr>
                    <tr class="tableHeader">
                        <td>(个)</td>
                        <td>(宗)</td>
                        <td>(亩)</td>
                    </tr>
                    <tr
                        v-for="(item, index) in devloperInfo.data"
                        :key="index"
                        @click="clickTitle(item)"
                    >
                        <td>{{ item.name }}</td>
                        <td>{{ item.totalProj }}</td>
                        <td>{{ item.landCount }}</td>
                        <td>{{ item.useAreaMu }}</td>
                    </tr>
                </table>
                <div class="explainBox">
                    数据说明：截止{{ devloperInfo.endTime }}
                </div>
            </div>
        </div>
        <!-- 量价走势 -->
        <div class="ecsBoxAndExplain">
            <div class="divStyle">
                <div id="quantityPrice"></div>
                <div class="explainBox">
                    数据说明：截止{{ areaQuantityAndPrice.endTime }}
                </div>
            </div>
        </div>
        <!-- 面积段分析 -->
        <div class="ecsBoxAndExplain">
            <div class="divStyle">
                <div id="quantityArea" style="height: 270px"></div>
                <div class="explainBox">
                    统计时间：{{ areaSaleTotalCount.startTime }}-{{
                        areaSaleTotalCount.endTime
                    }}
                </div>
            </div>
        </div>
        <!-- 单价段分析 -->
        <div class="ecsBoxAndExplain">
            <div class="divStyle">
                <div id="quantityUnitPrice" style="height: 270px"></div>
                <div class="explainBox">
                    统计时间：{{ areaPriceStatistics.startTime }}-{{
                        areaPriceStatistics.endTime
                    }}
                </div>
            </div>
        </div>
        <!-- 总价段分许 -->
        <div class="ecsBoxAndExplain">
            <div class="divStyle">
                <div id="quantityTotalPrice" style="height: 270px"></div>
                <div class="explainBox">
                    统计时间：{{ areaTotalPriceStatistics.startTime }}-{{
                        areaTotalPriceStatistics.endTime
                    }}
                </div>
            </div>
        </div>
        <!-- 面积段与总价段交叉分析 -->
        <div class="ecsBoxAndExplain">
            <div class="divStyle">
                <div id="areaAndTotalPrice" style="height: 260px"></div>
                <div class="explainBox">
                    统计时间：{{ areaAndTotalPriceIntersect.startTime }}-{{
                        areaAndTotalPriceIntersect.endTime
                    }}
                </div>
            </div>
        </div>
        <div class="ecsBoxAndExplain">
            <div class="divStyle rankingBox">
                <div class="ranking">
                    <div class="title">项目销售套数排名</div>
                    <div class="topthreeBox">
                        <div class="topBox"></div>
                        <div class="top1">
                            <span>{{
                                projSaleTotalCountRank.data[0].name
                                    ? projSaleTotalCountRank.data[0].name
                                    : "暂无"
                            }}</span>
                            <img src="../assets/icon/peripheral/topOne.png" />
                        </div>
                        <div class="top2">
                            <span>{{
                                projSaleTotalCountRank.data[1].name
                                    ? projSaleTotalCountRank.data[1].name
                                    : "暂无"
                            }}</span>
                            <img src="../assets/icon/peripheral/topTwo.png" />
                        </div>
                        <div class="top3">
                            <span>{{
                                projSaleTotalCountRank.data[2].name
                                    ? projSaleTotalCountRank.data[2].name
                                    : "暂无"
                            }}</span>
                            <img src="../assets/icon/peripheral/topThree.png" />
                        </div>
                        <div class="topBottom">
                            <img src="../assets/icon/peripheral/topBox.png" />
                        </div>
                    </div>
                </div>
                <div class="explainBox">
                    统计时间：{{ projSaleTotalCountRank.startTime }}-{{
                        projSaleTotalCountRank.endTime
                    }}
                </div>
            </div>
        </div>
        <div class="mapTitle" style="margin: 30px 0 20px 0">
            <div class="titleStyle">二手房价格</div>
        </div>
        <div class="mapBox" style="width: 100%; height: 297px">
            <div id="towHouse" style="width: 100%; height: 297px"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import { post } from "../config/request";
import { EyesMap } from "../config/showMap.js"; // 引入地图
export default {
    data() {
        return {
            ecsTextColor: "rgba(66, 60, 155, 1)", //echarts图表文字颜色
            id: 0,
            maxLandFloorPrice: {
                endTime: "",
                maxLandFloorPrice: "",
            }, //历史最高名义楼面地价
            landSupplyList: [], //近三年土地供应
            unDevelopedLandArea: 0, //未开发土地面积
            unDevelopedLandCount: { endTime: "", res: { count: 0 } }, //未开发土地宗数
            newResidenceProj: {}, //新房项目分部
            devAndResidenceProj: {}, //品牌房屋分部
            areaTotalPriceStatistics: {
                endTime: "",
                startTime: "",
            }, //区域总价
            devloperInfo: {
                data: [
                    {
                        id: 36,
                        landCount: 0,
                        name: "",
                        totalProj: 0,
                        useAreaMu: 0,
                    },
                ],
                endTime: "",
            }, //品牌房企项目数
            areaAndTotalPriceIntersect: {
                endTime: "",
                startTime: "",
            }, //面积与总价段交叉分析
            areaSaleTotalCount: { endTime: "", startTime: "" }, //区域面积段分析
            areaQuantityAndPrice: { endTime: "" }, //区域量价走势
            areaPriceStatistics: { endTime: "", startTime: "" }, //区域单价段分析
            projSaleTotalCountRank: {
                data: [{ name: "" }, { name: "" }, { name: "" }],
                endTime: "",
                startTime: "",
            }, //项目销售排名
            secondHousePrice: [],
            clickName: null,
            devloperInfoMap: null,
        };
    },
    created() {
        this.id = window.GetAreaInfo("id");
        this.getLand();
        debugger;
        this.getResidence();
    },                                                                                                           
    mounted() {
        setTimeout((e) => {
            document.getElementById("peripheralMarketBox").style.height =
                "auto";
        }, 500);
    },
    methods: {
        // 获取土地市场数据
        getLand() {
            var that = this;
            post("content/land_market", {
                analysisId: that.id,
            }).then((res) => {
                that.landSupplyList = res.data.landSupplyList;
                that.maxLandFloorPrice = res.data.maxLandFloorPrice;
                that.unDevelopedLandArea = res.data.unDevelopedLandArea;
                that.unDevelopedLandCount = res.data.unDevelopedLandCount;
                that.landSupplyEcs();
            });
        },
        // 获取住宅市场资源
        getResidence() {
            var that = this;
            post("content/residence_market", {
                analysisId: that.id,
            }).then((res) => {
                // console.log(res);
                that.newResidenceProj = res.data.newResidenceProj;
                that.devAndResidenceProj = res.data.devAndResidenceProj;
                // console.log(that.devAndResidenceProj);
                that.devloperInfo = res.data.devloperInfo;
                debugger;
                that.areaQuantityAndPrice = res.data.areaQuantityAndPrice;
                that.areaSaleTotalCount = res.data.areaSaleTotalCount;
                that.areaPriceStatistics = res.data.areaPriceStatistics;
                that.areaTotalPriceStatistics =
                    res.data.areaTotalPriceStatistics;
                that.areaAndTotalPriceIntersect =
                    res.data.areaAndTotalPriceIntersect;
                var baseInfoIdxList = res.data.projSaleTotalCountRank.data;
                for (var i = 0; i < 3; i++) {
                    if (!baseInfoIdxList[i])
                        baseInfoIdxList.push({ name: "暂无", saledCount: 0 });
                }
                that.projSaleTotalCountRank = res.data.projSaleTotalCountRank;
                that.secondHousePrice = res.data.secondHousePrice;
                that.newHouseMap();
                that.developmentMap();
                that.quantityPriceEcs();
                that.quantityAreaEcs();
                that.quantityUnitPriceEcs();
                that.quantityTotalPriceEcs();
                that.areaAndTotalPriceEcs();
                that.towHouseMap();
            });
        },
        landSupplyEcs() {
            var that = this;
            var chartDom = document.getElementById("landSupply");
            var myChart = echarts.init(chartDom);
            var option;
            var countData = that.landSupplyList.map((item) => {
                return item.announceCount;
            });
            var rateData = that.landSupplyList.map((item) => {
                return item.failedRate;
            });
            var timeData = that.landSupplyList.map((item) => {
                return item.quarterName;
            });
            option = {
                title: {
                    text: "近三年土地供应",
                    textStyle: {
                        //文字颜色
                        color: "#fff",
                        //字体大小
                        fontSize: 14,
                    },
                },
                grid: {
                    //调整图例位置
                    left: "1%",
                    right: "1%",
                    bottom: "1%",
                    containLabel: true,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#fff",
                        },
                    },
                },
                legend: {
                    data: ["供应(宗)", "流拍率(%)"],
                    right: "1%",
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 10,
                        color: "rgba(194, 203, 255, 1)",
                    },
                },
                xAxis: [
                    //X轴数据
                    {
                        type: "category",
                        data: timeData,
                        axisPointer: {
                            type: "shadow",
                        },
                        axisLabel: {
                            color: this.ecsTextColor,
                        },
                        splitLine: {
                            show: false, //隐藏网格线
                        },
                        axisLine: {
                            lineStyle: {
                                color: this.ecsTextColor, //轴颜色
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "",
                        splitLine: {
                            show: true, //隐藏网格线
                            lineStyle: {
                                color: [this.ecsTextColor], //网格线颜色
                                width: 2,
                            },
                        },
                        axisLabel: {
                            formatter: "{value} ",
                            color: this.ecsTextColor,
                        },
                        axisLine: {
                            lineStyle: {
                                color: this.ecsTextColor, //轴颜色
                            },
                        },
                    },
                    {
                        type: "value",
                        name: "",
                        splitLine: {
                            show: false, //隐藏网格线
                        },
                        axisLabel: {
                            formatter: "{value} ",
                            color: this.ecsTextColor,
                        },
                        axisLine: {
                            lineStyle: {
                                color: this.ecsTextColor, //轴颜色
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "供应(宗)",
                        type: "bar",
                        data: countData,
                        label: {
                            show: true,
                            position: "top", //柱子上方文字
                            color: "rgba(194, 203, 255, 1)",
                        },
                        itemStyle: {
                            color: "rgba(44, 51, 179, 1)", //柱子颜色
                        },
                    },
                    {
                        name: "流拍率(%)",
                        type: "line",
                        yAxisIndex: 1,
                        data: rateData,
                        label: {
                            show: true,
                            position: "top", //折线上方文字
                            color: "rgba(194, 203, 255, 1)",
                        },
                        itemStyle: {
                            color: "rgba(124, 53, 213, 1)", //折线颜色
                        },
                    },
                ],
            };
            option && myChart.setOption(option);
        },
        newHouseMap() {
            var that = this;
            var areaInfo = window.GetAreaInfo();
            var newHouse1 = [];
            var newHouse2 = [];
            if (that.newResidenceProj) {
                for (var i = 0; i < that.newResidenceProj.length; i++) {
                    if (that.newResidenceProj[i].saleTypeId == 1) {
                        newHouse1.push(that.newResidenceProj[i]);
                    }
                }
                newHouse1 = newHouse1.map((i) => {
                    i["color"] = "rgba(30, 175, 182, 1)";
                    return i;
                });
                for (var i = 0; i < that.newResidenceProj.length; i++) {
                    if (that.newResidenceProj[i].saleTypeId == 2) {
                        newHouse2.push(that.newResidenceProj[i]);
                    }
                }
                newHouse2 = newHouse2.map((i) => {
                    i["color"] = "rgba(227, 59, 123, 1)";
                    return i;
                });
            }
            EyesMap.InitMap("newHouse", (map) => {
                EyesMap.ShowArea(map, areaInfo);
                EyesMap.ShowCircleMarker(map, newHouse1);
                EyesMap.ShowCircleMarker(map, newHouse2);
            });
        },
        // 区域品牌房企开发情况
        developmentMap() {
            var that = this;
            var areaInfo = window.GetAreaInfo();
            if (that.devAndResidenceProj) {
                var newHouse = that.devAndResidenceProj.map((i) => {
                    return {
                        developerName: i.developerName,
                        price: i.price,
                        geom: i.geom,
                        name: i.projecName,
                    };
                });
            }
            EyesMap.InitMap("development", (map) => {
                that.devloperInfoMap = map;
                EyesMap.ShowArea(map, areaInfo);
                EyesMap.ShowCircleMarker(map, newHouse);
                let markerList = map.getAllOverlays();
                markerList.map((x) => {
                    let newMarkerArr = [];
                    if (x.getExtData().developerName == that.clickName) {
                        x.getExtData()["color"] = "#D33B85";
                        newMarkerArr.push(x.getExtData());
                        EyesMap.ShowLabel(map, newMarkerArr);
                        EyesMap.ShowCircleMarker(map, newMarkerArr);
                    }
                });
            });
        },
        // 点击表格对应标记点高亮
        clickTitle(e) {
            let map = this.devloperInfoMap;
            var newHouse = this.devAndResidenceProj.map((i) => {
                return {
                    developerName: i.developerName,
                    price: i.price,
                    geom: i.geom,
                    name: i.projecName,
                };
            });
            let markerList = map.getAllOverlays("marker");
            var asdc = map.getAllOverlays("text");
            map.remove(newHouse);
            map.remove(asdc);
            EyesMap.ShowCircleMarker(map, newHouse);
            if (this.clickName == e.name) return;
            this.clickName = e.name;
            markerList.map((x) => {
                let newMarkerArr = [];
                if (x.getExtData().developerName == this.clickName) {
                    x.getExtData()["color"] = "#D33B85";
                    newMarkerArr.push(x.getExtData());
                    EyesMap.ShowLabel(map, newMarkerArr);
                    EyesMap.ShowCircleMarker(map, newMarkerArr);
                }
            });
            this.clickName = null;
        },
        quantityPriceEcs() {
            var chartDom = document.getElementById("quantityPrice");
            var myChart = echarts.init(chartDom);
            var option;
            option = {
                title: {
                    text: "区域量价走势",
                    textStyle: {
                        //文字颜色
                        color: "#fff",
                        //字体大小
                        fontSize: 14,
                    },
                },
                grid: {
                    //调整图例位置
                    left: "1%",
                    right: "1%",
                    bottom: "1%",
                    containLabel: true,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#fff",
                        },
                    },
                },
                legend: {
                    data: [
                        "新增面积(万㎡)",
                        "销售面积(万㎡)",
                        "成交均价(元/㎡)",
                    ],
                    right: "1%",
                    top: "7%",
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 10,
                        color: "rgba(194, 203, 255, 1)",
                    },
                },
                xAxis: [
                    //X轴数据
                    {
                        type: "category",
                        data: this.areaQuantityAndPrice.data.map((i) => {
                            return i.monthName;
                        }),
                        axisPointer: {
                            type: "shadow",
                        },
                        axisLabel: {
                            color: this.ecsTextColor,
                        },
                        splitLine: {
                            show: false, //隐藏网格线
                        },
                        axisLine: {
                            lineStyle: {
                                color: this.ecsTextColor, //轴颜色
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "",
                        splitLine: {
                            show: true, //隐藏网格线
                            lineStyle: {
                                color: [this.ecsTextColor], //网格线颜色
                                width: 2,
                            },
                        },
                        axisLabel: {
                            formatter: "{value} ",
                            color: this.ecsTextColor,
                        },
                        axisLine: {
                            lineStyle: {
                                color: this.ecsTextColor, //轴颜色
                            },
                        },
                    },
                    {
                        type: "value",
                        name: "",
                        splitLine: {
                            show: false, //隐藏网格线
                            lineStyle: {
                                color: [this.ecsTextColor], //网格线颜色
                                width: 2,
                            },
                        },
                        axisLabel: {
                            formatter: "{value} ",
                            color: this.ecsTextColor,
                        },
                        axisLine: {
                            lineStyle: {
                                color: this.ecsTextColor, //轴颜色
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "新增面积(万㎡)",
                        type: "bar",
                        data: this.areaQuantityAndPrice.data.map((i) => {
                            return i.newSupplyArea;
                        }),
                        label: {
                            show: false,
                            position: "top", //柱子上方文字
                            color: "rgba(194, 203, 255, 1)",
                        },
                        itemStyle: {
                            color: "rgba(44, 51, 179, 1)", //柱子颜色
                        },
                    },
                    {
                        name: "销售面积(万㎡)",
                        type: "bar",
                        data: this.areaQuantityAndPrice.data.map((i) => {
                            return i.saledArea;
                        }),
                        label: {
                            show: false,
                            position: "top", //柱子上方文字
                            color: "rgba(194, 203, 255, 1)",
                        },
                        itemStyle: {
                            color: "rgba(104, 21, 193, 1)", //柱子颜色
                        },
                    },
                    {
                        name: "成交均价(元/㎡)",
                        type: "line",
                        yAxisIndex: 1,
                        data: this.areaQuantityAndPrice.data.map((i) => {
                            return i.price;
                        }),
                        label: {
                            show: true,
                            position: "top", //折线上方文字
                            color: "rgba(194, 203, 255, 1)",
                        },
                        itemStyle: {
                            color: "rgba(124, 53, 213, 1)", //折线颜色
                        },
                    },
                ],
            };
            option && myChart.setOption(option);
        },
        quantityAreaEcs() {
            var chartDom = document.getElementById("quantityArea");
            var myChart = echarts.init(chartDom);
            var option;
            option = {
                title: {
                    text: "区域面积段分析",
                    textStyle: {
                        //文字颜色
                        color: "#fff",
                        //字体大小
                        fontSize: 14,
                    },
                },
                grid: {
                    //调整图例位置
                    left: "1%",
                    right: "1%",
                    bottom: "1%",
                    containLabel: true,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#fff",
                        },
                    },
                },
                legend: {
                    data: ["成交(套)"],
                    right: "1%",
                    top: "7%",
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 10,
                        color: "rgba(194, 203, 255, 1)",
                    },
                },
                xAxis: [
                    //X轴数据
                    {
                        type: "category",
                        data: this.areaSaleTotalCount.data.map((i) => {
                            return i.name;
                        }),
                        axisPointer: {
                            type: "shadow",
                        },
                        axisLabel: {
                            color: this.ecsTextColor,
                        },
                        splitLine: {
                            show: false, //隐藏网格线
                        },
                        axisLine: {
                            lineStyle: {
                                color: this.ecsTextColor, //轴颜色
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "",
                        splitLine: {
                            show: true, //隐藏网格线
                            lineStyle: {
                                color: [this.ecsTextColor], //网格线颜色
                                width: 2,
                            },
                        },
                        axisLabel: {
                            formatter: "{value} ",
                            color: this.ecsTextColor,
                        },
                        axisLine: {
                            lineStyle: {
                                color: this.ecsTextColor, //轴颜色
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "成交(套)",
                        type: "bar",
                        data: this.areaSaleTotalCount.data.map((i) => {
                            return i.saledCount;
                        }),
                        label: {
                            show: true,
                            position: "top", //柱子上方文字
                            color: "rgba(194, 203, 255, 1)",
                        },
                        itemStyle: {
                            color: "rgba(44, 51, 179, 1)", //柱子颜色
                        },
                    },
                ],
            };
            option && myChart.setOption(option);
        },
        quantityUnitPriceEcs() {
            var chartDom = document.getElementById("quantityUnitPrice");
            var myChart = echarts.init(chartDom);
            var option;
            option = {
                title: {
                    text: "区域单价段分析",
                    textStyle: {
                        //文字颜色
                        color: "#fff",
                        //字体大小
                        fontSize: 14,
                    },
                },
                grid: {
                    //调整图例位置
                    left: "1%",
                    right: "1%",
                    bottom: "1%",
                    containLabel: true,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#fff",
                        },
                    },
                },
                legend: {
                    data: ["成交(套)"],
                    right: "1%",
                    top: "7%",
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 10,
                        color: "rgba(194, 203, 255, 1)",
                    },
                },
                xAxis: [
                    //X轴数据
                    {
                        type: "category",
                        data: this.areaPriceStatistics.data.map((i) => {
                            return i.name;
                        }),
                        axisPointer: {
                            type: "shadow",
                        },
                        axisLabel: {
                            color: this.ecsTextColor,
                        },
                        splitLine: {
                            show: false, //隐藏网格线
                        },
                        axisLine: {
                            lineStyle: {
                                color: this.ecsTextColor, //轴颜色
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "",
                        splitLine: {
                            show: true, //隐藏网格线
                            lineStyle: {
                                color: [this.ecsTextColor], //网格线颜色
                                width: 2,
                            },
                        },
                        axisLabel: {
                            formatter: "{value} ",
                            color: this.ecsTextColor,
                        },
                        axisLine: {
                            lineStyle: {
                                color: this.ecsTextColor, //轴颜色
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "成交(套)",
                        type: "bar",
                        data: this.areaPriceStatistics.data.map((i) => {
                            return i.saledCount;
                        }),
                        label: {
                            show: true,
                            position: "top", //柱子上方文字
                            color: "rgba(194, 203, 255, 1)",
                        },
                        itemStyle: {
                            color: "rgba(44, 51, 179, 1)", //柱子颜色
                        },
                    },
                ],
            };
            option && myChart.setOption(option);
        },
        quantityTotalPriceEcs() {
            var chartDom = document.getElementById("quantityTotalPrice");
            var myChart = echarts.init(chartDom);
            var option;
            option = {
                title: {
                    text: "区域总价段分析",
                    textStyle: {
                        //文字颜色
                        color: "#fff",
                        //字体大小
                        fontSize: 14,
                    },
                },
                grid: {
                    //调整图例位置
                    left: "1%",
                    right: "1%",
                    bottom: "1%",
                    containLabel: true,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#fff",
                        },
                    },
                },
                legend: {
                    data: ["成交(套)"],
                    right: "1%",
                    top: "7%",
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 10,
                        color: "rgba(194, 203, 255, 1)",
                    },
                },
                xAxis: [
                    //X轴数据
                    {
                        type: "category",
                        data: this.areaTotalPriceStatistics.data.map((i) => {
                            return i.name;
                        }),
                        axisPointer: {
                            type: "shadow",
                        },
                        splitLine: {
                            show: false, //隐藏网格线
                            lineStyle: {
                                color: [this.ecsTextColor], //网格线颜色
                                width: 2,
                            },
                        },
                        axisLabel: {
                            formatter: "{value} ",
                            color: this.ecsTextColor,
                        },
                        axisLine: {
                            lineStyle: {
                                color: this.ecsTextColor, //轴颜色
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "",
                        splitLine: {
                            show: true, //隐藏网格线
                            lineStyle: {
                                color: [this.ecsTextColor], //网格线颜色
                                width: 2,
                            },
                        },
                        axisLabel: {
                            formatter: "{value} ",
                            color: this.ecsTextColor,
                        },
                        axisLine: {
                            lineStyle: {
                                color: this.ecsTextColor, //轴颜色
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "成交(套)",
                        type: "bar",
                        data: this.areaTotalPriceStatistics.data.map((i) => {
                            return i.saledCount;
                        }),
                        label: {
                            show: true,
                            position: "top", //柱子上方文字
                            color: "rgba(194, 203, 255, 1)",
                        },
                        itemStyle: {
                            color: "rgba(44, 51, 179, 1)", //柱子颜色
                        },
                    },
                ],
            };
            option && myChart.setOption(option);
        },
        areaAndTotalPriceEcs() {
            var chartDom = document.getElementById("areaAndTotalPrice");
            var myChart = echarts.init(chartDom);
            var option;
            var data = [];
            var saledCountList = {};
            this.areaAndTotalPriceIntersect.saledCountList.map((i) => {
                saledCountList[i.areaGroupId + "_" + i.totalPriceGroupId] =
                    i.totalSaledCount;
            });
            var hours = this.areaAndTotalPriceIntersect.areaNameList.map(
                (i, index) => {
                    var areaGroupId = i.dcode;
                    var list = this.areaAndTotalPriceIntersect.priceList;
                    for (var n = 0; n < list.length; n++) {
                        var totalPriceGroupId = list[n].dcode;
                        var id = areaGroupId + "_" + totalPriceGroupId;
                        if (saledCountList[id]) {
                            data.push([index, n, saledCountList[id]]);
                        } else {
                            data.push([index, n, 0]);
                        }
                    }
                    return i.name;
                }
            );
            var days = this.areaAndTotalPriceIntersect.priceList.map((i) => {
                return i.name;
            });
            var Max = 0;
            for (let i = 0; i < data.length; i++) {
                Max = Max < data[i][2] ? data[i][2] : Max;
            }
            var interValue = Max / 5;
            option = {
                title: {
                    text: "区域面积与总价段交叉分析",
                    textStyle: {
                        //文字颜色
                        color: "#fff",
                        //字体大小
                        fontSize: 14,
                    },
                },
                grid: {
                    //调整图例位置
                    left: "1%",
                    right: "2%",
                    bottom: "1%",
                    containLabel: true,
                },
                tooltip: {
                    position: "top",
                },
                xAxis: {
                    type: "category",
                    data: hours,
                    splitArea: {
                        show: true,
                    },
                    splitLine: {
                        show: true, //隐藏网格线
                        lineStyle: {
                            color: [this.ecsTextColor], //网格线颜色
                            width: 1,
                        },
                    },
                    axisLabel: {
                        formatter: "{value} ",
                        color: this.ecsTextColor,
                    },
                    axisLine: {
                        lineStyle: {
                            color: this.ecsTextColor, //轴颜色
                        },
                    },
                },
                yAxis: {
                    type: "category",
                    data: days,
                    splitArea: {
                        show: true,
                    },
                    splitLine: {
                        show: true, //隐藏网格线
                        lineStyle: {
                            color: [this.ecsTextColor], //网格线颜色
                            width: 1,
                        },
                    },
                    axisLabel: {
                        formatter: "{value} ",
                        color: this.ecsTextColor,
                    },
                    axisLine: {
                        lineStyle: {
                            color: this.ecsTextColor, //轴颜色
                        },
                    },
                },
                visualMap: {
                    show: false,
                    pieces: [
                        // {min:0,max:0.00001,color:'#FFFFFF'},
                        { min: 0.001, max: interValue, color: "#CCFF00" },
                        {
                            min: interValue,
                            max: interValue * 2,
                            color: "#0099FF",
                        },
                        {
                            min: interValue * 2,
                            max: interValue * 3,
                            color: "#0033FF",
                        },
                        {
                            min: interValue * 3,
                            max: interValue * 4,
                            color: "#FF6600",
                        },
                        { min: interValue * 4, max: Max, color: "#FE0000" },
                    ],
                },
                series: [
                    {
                        name: "",
                        type: "heatmap",
                        data: data,
                        label: {
                            show: true,
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };
            option && myChart.setOption(option);
        },
        towHouseMap() {
            var that = this;
            debugger;
            var areaInfo = window.GetAreaInfo();
            EyesMap.InitMap("towHouse", (map) => {
                EyesMap.ShowArea(map, areaInfo);
                EyesMap.ShowCircleMarker(map, that.secondHousePrice);
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .amap-marker-label {
    background-color: rgba(227, 59, 123, 1);
    color: #ffffff;
    border: none;
    padding: 3px;
    border-radius: 4px;
    text-align: center;
    .MapInfo {
        position: relative;
        font-size: 12px;
        .minInfo {
            position: absolute;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid rgba(227, 59, 123, 1);
            left: 42%;
        }
    }
}
.developmentTable {
    table {
        border-collapse: collapse;
        td,
        tr {
            color: #fff;
            border: 2px solid rgba(59, 33, 131, 1);
            text-align: center;
        }
        .tableHeader {
            td {
                color: rgba(66, 60, 155, 1);
            }
        }
    }
}
.titleStyle {
    font-size: 14px;
    color: #ffffff;
    font-weight: 600;
}
.containerBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #0f0240;
    overflow-y: auto;
    align-items: center;
    .explain {
        color: rgba(66, 60, 155, 1);
        font-size: 14px;
        margin-top: 10px;
    }
    .ecsBox {
        width: 100%;
        padding: 0 15px 0 15px;
        .divStyle {
            width: 100%;
            margin-bottom: 15px;
            border-radius: 4px;
            background: rgba(61, 49, 148, 0.4);
            padding: 15px;
        }
        #landSupply {
            height: 260px;
        }
        .topOne {
            padding: 20px 15px 15px 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .topOneTitle {
                width: 100%;
                margin-bottom: 20px;
            }
            .topOneNum {
                position: relative;
                width: 189px;
                height: 163px;
                margin-bottom: 13px;
                display: flex;
                justify-content: center;
                img {
                    width: 189px;
                    height: 163px;
                }
                .num {
                    position: absolute;
                    top: 31px;
                    left: 53px;
                    font-size: 24px;
                    color: rgba(228, 42, 178, 1);
                    font-weight: bold;
                }
            }
        }
        .uncultivated {
            display: flex;
            flex-direction: column;
            #uncultivatedBox {
                width: 100%;
                margin-top: 40px;
                display: flex;
                align-items: flex-end;
                border-bottom: 1px solid rgba(59, 33, 131, 1);
                margin-bottom: 35px;
                .left,
                .right {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    div {
                        font-size: 12px;
                        color: #ffffff;
                    }
                    .num {
                        font-size: 18px;
                        color: #fff;
                        position: absolute;
                        span {
                            font-size: 12px;
                        }
                    }
                    .bottomText {
                        position: absolute;
                    }
                }
                .left {
                    width: 47%;
                    height: 54px;
                    margin-bottom: -2px;
                    .num {
                        top: 10px;
                        left: 40%;
                    }
                    img {
                        width: 100%;
                        height: 64px;
                    }
                    .bottomText {
                        bottom: -18px;
                    }
                }
                .right {
                    width: 66%;
                    height: 100px;
                    margin-left: -35px;
                    margin-bottom: -22px;
                    img {
                        width: 100%;
                        height: 90px;
                    }
                    .num {
                        top: 25px;
                        left: 35%;
                    }
                    .bottomText {
                        bottom: 2px;
                    }
                }
            }
        }
    }
    .mapTitle {
        width: 100%;
        padding: 0 15px 0 30px;
        display: flex;
        flex-direction: column;
        .mapLegend {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            .mapLegendL,
            .mapLegendR {
                display: flex;
                font-size: 10px;
                color: #fff;
                align-items: center;
                div {
                    width: 8px;
                    height: 8px;
                    margin-right: 4px;
                }
            }
            .mapLegendL {
                margin-right: 17px;
                div {
                    background: rgba(227, 59, 123, 1);
                }
            }
            .mapLegendR {
                div {
                    background: rgba(30, 175, 182, 1);
                }
            }
        }
    }
    .ecsBoxAndExplain {
        width: 100%;
        padding: 0 15px;
        .divStyle {
            width: 100%;
            margin-bottom: 15px;
            border-radius: 4px;
            background: rgba(61, 49, 148, 0.4);
            padding: 15px 15px 15px 15px;
            text-align: center;
            #quantityPrice {
                height: 285px;
            }
            .ecsExplain {
                color: rgba(66, 60, 155, 1);
                font-size: 14px;
                width: 100%;
            }
        }
    }
    .rankingBox {
        .ranking {
            display: flex;
            flex-direction: column;
            align-items: center;
            .title {
                width: 100%;
                color: #fff;
                font-size: 14px;
                font-weight: 600;
                text-align: left;
            }
            .topthreeBox {
                width: 285px;
                height: 195px;
                overflow: hidden;
                border-bottom: 2px solid rgba(59, 33, 131, 1);
                position: relative;
                .topBottom {
                    position: absolute;
                    bottom: -4px;
                    left: 40px;
                    img {
                        width: 204px;
                        height: 104px;
                    }
                }
                .top1,
                .top2,
                .top3 {
                    width: 70px;
                    height: 68.5px;
                    position: absolute;
                    color: rgba(26, 15, 86, 1);
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    img {
                        width: 70px;
                        height: 68.5px;
                    }
                    span {
                        position: absolute;
                        top: 20px;
                        font-weight: 600;
                    }
                }
                .top1 {
                    top: 16px;
                    left: 110px;
                }
                .top2 {
                    top: 48px;
                    left: 34px;
                }
                .top3 {
                    top: 62px;
                    left: 180px;
                }
                .topBox {
                    width: 238px;
                    height: 238px;
                    border-radius: 50%;
                    background: linear-gradient(
                        0deg,
                        rgba(61, 49, 148, 0),
                        rgba(61, 49, 148, 0),
                        rgba(61, 49, 148, 1)
                    );
                    margin-left: 23.5px;
                }
            }
        }
    }
}
.oneTitleBox {
    width: 100%;
    height: 80px;
    .oneTitle {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        background-color: #0f0240;
        padding-left: 20px;
        color: #fff;
        font-size: 22px;
        .icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: linear-gradient(180deg, #7920da, #0d78fd);
            margin-right: 20px;
            .titleImg {
                // width: 70px;
                // height: 70px;
                margin-left: -15px;
                margin-top: -15px;
            }
        }
    }
}
.threeTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 18px;
    margin: 20px 0;
    padding: 0 25px;
    .titleL,
    .titleR {
        flex: 1;
        height: 3px;
    }
    .titleL {
        background: linear-gradient(
            90deg,
            rgba(110, 98, 228, 0),
            rgba(110, 98, 228, 1)
        );
        margin-right: 10px;
    }
    .titleR {
        background: linear-gradient(
            90deg,
            rgba(110, 98, 228, 1),
            rgba(110, 98, 228, 0)
        );
        margin-left: 10px;
    }
}
</style>
