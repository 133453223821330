<template>
  <div class="userinfo">
    <!-- <div class="userinfo-title">
      <div class="back">
        <div class="back-icon">
          <img
            src="../assets/user/back.png"
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
      <div class="back-title">个人信息</div>
    </div> -->
    <div class="inputBox">
      <input type="text" v-model="username" class="uinput" maxlength="10" />
    </div>
    <div class="submit" @click="commit">保存修改</div>
  </div>
</template>

<script>
import { post } from "../config/request";
export default {
  data() {
    return {
      username: "",
    };
  },
  created() {
    document.title = "修改呢称";
  },
  mounted() {
    var that = this;
    that.username = window.GetUserInfo().userName;
    console.log(that.username);
  },
  methods: {
    commit() {
      if (this.username == "") {
        window.ShowMsg("请输入昵称");
        return;
      } else {
        post("/content/update_name", { name: this.username }).then((res) => {
          console.log(res);
          if (res.data == true) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            post("/account/userinfo", {}).then((res) => {
              if (res.code > 0) {
                window.SetUserInfo({ token: null, expiresIn: 0 });
                window.ShowMsg("获取信息失败，请重新登录！");
                window.RedirectTo("index");
              } else {
                console.log(res.data.email);
                var userInfo = {
                  userName: res["data"]["name"] || "",
                };
                window.SetUserInfo(userInfo);
                window.pageEvent.send("updateUserInfo", "");
              }
            });
          } else {
            this.$message.error("修改失败");
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.userinfo {
  width: 100%;
  height: 100vh;
  background: #0f0240;
  // padding-top: 19px;
  display: flex;
  flex-direction: column;
  .userinfo-title {
    display: flex;
    color: #ffffff;
    align-items: center;
    .back {
      width: 50px;
      height: 21px;
      .back-icon {
        width: 12px;
        height: 21px;
        margin-left: 18px;
      }
    }
    .back-title {
      font-size: 17px;
    }
  }
  .inputBox {
    margin: 47px auto 0px auto;
    .uinput {
      width: 315px;
      height: 55px;
      background: #1a255a;
      border: none;
      color: #c2cbff;
      outline: medium;
      padding-left: 17px;
    }
  }
  .submit {
    width: 80%;
    height: 55px;
    background: linear-gradient(49deg, #3995fc, #00cffd);
    border-radius: 3px;
    position: fixed;
    bottom: 58px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    line-height: 55px;
    font-size: 15px;
    color: #ffffff;
  }
}
</style>