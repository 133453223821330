<template>
  <div class="home">
    <div class="button-box">
      <div
        class="img-box"
        :class="{ activebox: isCircle == true }"
        @click="changebox(true)"
      >
        <img
          src="../assets/home/circular.png"
          style="width: 15px; height: 15px; margin-top: 3px; margin-bottom: 1px"
        />
        <div style="font-size: 9px">图形</div>
      </div>
      <div
        class="img-box"
        @click="changebox(false)"
        :class="{ activebox: isCircle == false }"
      >
        <img
          src="../assets/home/polygon.png"
          style="width: 15px; height: 15px; margin-top: 3px; margin-bottom: 1px"
        />
        <div style="font-size: 9px">自定义</div>
      </div>
    </div>
    <div
      class="layerBox"
      @click="weixingtu"
      :class="{ activelay: islay == true }"
      style="display: none"
    >
      <img src="../assets/home/layer.png" style="width: 16px; height: 12.5px" />
      <div>图层</div>
    </div>
    <div class="radius" v-show="isCircle">
      <div
        style="
          font-size: 9px;
          color: #ffffff;
          font-weight: 400;
          text-align: center;
          width: 35px;
          height: 25px;
          line-height: 25px;
        "
      >
        半径
      </div>
      <div
        style="
          width: 21px;
          height: 1px;
          background: #e6e7ea;
          opacity: 0.25;
          margin: 0 auto;
        "
      ></div>
      <div
        class="radius-number"
        v-for="(item, index) in CircleRadiusList"
        :key="item.value"
        :class="{ activeredius: isindex == index }"
        @click="changeRadius(index)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="mapHome">
      <div id="container" style="width: 100vw"></div>
    </div>
    <div
      style="width: 100%; height: 180px"
      v-show="geomInfo.center.length == 2"
    ></div>
    <div class="home_box" v-show="geomInfo.center.length == 2">
      <div class="box1">{{ geomInfo.addr }}</div>
      <div class="box2" v-show="isCircle">
        统计周边半径
        <div style="color: #ff1f6e">{{ geomInfo.radius }}</div>
        米的区域数据
      </div>
      <div class="box2" v-show="!isCircle">
        统计周边
        <div style="color: #ff1f6e">{{ geomInfo.area }}</div>
        平方公里的区域数据
      </div>
      <div class="box3" @click="analysis()">区域分析</div>
      <div class="house">
        <!-- <img src="../assets/house.png" style="width: 100%; height: 100%" /> -->
      </div>
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
import { post } from "../config/request";
import { EyesMap } from "../config/showMap.js"; // 引入地图
import Tabbar from "../components/tabbar.vue";
export default {
  components: {
    Tabbar,
  },
  data() {
    return {
      polyEditor: null,
      CircleRadiusList: [
        {
          value: 1000,
          name: "1KM",
        },
        {
          value: 2000,
          name: "2KM",
        },
        {
          value: 3000,
          name: "3KM",
        },
        {
          value: 4000,
          name: "4KM",
        },
        {
          value: 5000,
          name: "5KM",
        },
      ],
      islay: false, //判断卫星图层
      isCircle: true,
      isindex: 0, //判断半径的点击效果
      geomInfo: {
        paths: [], //多边形坐标点集合
        center: [], //中心点坐标
        addr: "", //POI查询出的地址信息
        radius: 1000, //圆半径，初始化为1000米
        area: 0, //多边形的面积
        adCode: 510107, //城市code
      },
      cityCode: 510100,
      cityName: "",
      map: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      document.title = "锐理慧眼";
      var that = this;
      post("/account/userinfo", {}).then((res) => {
        if (res.code > 0) {
          window.SetUserInfo({ token: null, expiresIn: 0 });
          window.ShowMsg("获取信息失败，请重新登录！");
          window.RedirectTo("index");
        } else {
          console.log(res.data.email);
          var userInfo = {
            userId: res["data"]["id"] || 0,
            userName: res["data"]["name"] || "",
            phone: res["data"]["mobile"] || "",
            email: res["data"]["email"],
            // email: res.data.email,
            analysisCount: res["data"]["statistics"]["analysisCount"] || 0,
            analysisDocCount: res["data"]["statistics"]["analysisDocCount"] || 0,
            likedCount: res["data"]["statistics"]["likedCount"] || 0,
            cityCode: res["data"]["cityInfo"]["cityCode"] || 510100,
            cityName: res["data"]["cityInfo"]["cityName"] || "成都",
            isVip: res["data"]["cityInfo"]["isVip"] || false,
            vipOverTime: res["data"]["cityInfo"]["vipOverTime"] || null,
            cityCenter: res["data"]["cityInfo"]["center"] || [],
            cityZoom: res["data"]["cityInfo"]["zoom"] || 11,
            cityLimitBounds: res["data"]["cityInfo"]["range"] || [],
          };
          window.SetUserInfo(userInfo);
          that.cityCode = userInfo.cityCode;
          that.cityName = userInfo.cityName;

          EyesMap.HideCoverDiv();
          EyesMap.InitMap("container", (map) => {
            that.map = map;
            map.setZoomAndCenter(userInfo["cityZoom"], userInfo["cityCenter"]);
            map.on("click", (e) => {
              if (!that.isCircle) return;
              that.geomInfo.center = [e.lnglat.getLng(), e.lnglat.getLat()];
              EyesMap.CreateCircleGeom(that.map, that.geomInfo);
            });
            EyesMap.Geolocation(map, (center, adcode) => {
              if (parseInt(adcode / 100) * 100 == that.cityCode) {
                // map.setZoomAndCenter(15, center);
                that.geomInfo.center = [center.lng, center.lat];
                EyesMap.CreateCircleGeom(that.map,that.geomInfo);
              } else {
                window.ShowMsg("您已经离开了" + that.cityName + ",无法定位！");
              }
            });
          });
        }
      });
    },
    //圆与多边形切换
    changebox(isCircle) {
      var that = this;
      that.isCircle = isCircle;
      that.geomInfo = {
        paths: [],
        center: [],
        addr: "",
        radius: 0,
        area: 0,
        adCode: 510100,
      };
      that.map.clearMap();
      if (isCircle) {
        that.geomInfo.radius = that.CircleRadiusList[that.isindex].value;
        if (that.polyEditor) that.polyEditor.close();
        // debugger
        EyesMap.Geolocation(that.map, (center, adcode) => {
          if (parseInt(adcode / 100) * 100 == that.cityCode) {
            // map.setZoomAndCenter(15, center);
            that.geomInfo.center = [center.lng, center.lat];
            EyesMap.CreateCircleGeom(that.map,that.geomInfo);
          } else {
            window.ShowMsg("您已经离开了" + that.cityName + ",无法定位！");
          }
        });
      } else {
        EyesMap.DrawPolygon(that.map, that.geomInfo, (edit) => {
          that.polyEditor = edit;
        });
      }
    },
    //切换图层
    weixingtu() {
      this.islay = !this.islay;
      if (this.islay == false) {
        //移除卫星路网图层
        this.map.remove(this.satellite, this.roadNet);
      } else {
        //添加卫星路网图层
        this.satellite = new AMap.TileLayer.Satellite();
        this.roadNet = new AMap.TileLayer.RoadNet();
        this.map.add(this.satellite, this.roadNet);
      }
    },
    //切换半径
    changeRadius(i) {
      var that = this;
      that.isindex = i;
      that.geomInfo.radius = that.CircleRadiusList[i].value;
      EyesMap.CreateCircleGeom(that.map, that.geomInfo);
    },
    //区域分析
    analysis() {
      var that = this;
      if (!that.isCircle && that.area > 78) {
        return window.ShowMsg("统计区域不能超过78平方公里！");
      }
      var param = window.cloneObj(that.geomInfo);
      if (that.isCircle) {
        param.area = 0;
        param.paths = [];
      } else {
        param.radius = 0;
      }
      post("/account/create_analysis", param).then((res) => {        
        if (res && res.data && res.data.id) {            
            this.$router.push({ name: 'Catalogue', params: { id: res.data.id }});
            //window.NavigateTo("catalogue", { id: res.data.id });
        } else {
          window.ShowMsg("分析失败！");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  width: 100%;
  height: 100%;
}
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.mapHome {
  flex: 1;
}

#container {
  width: 100%;
  height: 100%;
}
.home_box {
  width: 100%;
  height: 200px;
  background-image: url(../assets/home/home_box.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: fixed;
  bottom: 0;
  padding-top: 16px;
  padding-left: 20px;
}

.box1 {
  min-height: 48px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  z-index: 10000 !important;
  margin-right: 10px;
}

.box2 {
  font-size: 12px;
  font-weight: 400;
  color: #9498a2;
  display: flex;
  margin-top: 10px;
}

.box3 {
  width: 111px;
  height: 36.5px;
  border-radius: 14.5px;
  background: linear-gradient(78deg, #30abff, #00f7fe);
  line-height: 36.5px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 10px;
}

.home-text {
  display: flex;
  justify-content: space-around;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 15px;
}
.house {
  width: 193px;
  height: 180px;
  position: absolute;
  bottom: 11px;
  right: 0;
  background: url('../assets/home/house.png') no-repeat center center;
  background-size: 100%;
  img {
    z-index: 10;
  }
}
.button-box {
  display: flex;
  position: absolute;
  top: 29.5px;
  left: 21px;
  color: #b4d7ff;
  z-index: 99;
}
.img-box {
  width: 36px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #353d50;
  border-radius: 3px;
}
.activebox {
  background: linear-gradient(180deg, #7920da, #0d78fd);
}
.activelay {
  background: linear-gradient(180deg, #7920da, #0d78fd);
}
.layerBox {
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36.5px;
  top: 29.5px;
  right: 20px;
  background-color: #333b4f;
  z-index: 99;

  div {
    color: #b4d7ff;
    font-size: 12px;
  }
}
.radius {
  width: 35px;
  height: 151.5px;
  position: absolute;
  top: 94.5px;
  left: 21px;
  z-index: 99;
  background-color: #353d50;
  border-radius: 3px;
  box-shadow: -1px 3px 54px 0px rgba(23, 30, 74, 0.06);
  .radius-number {
    width: 35px;
    height: 25px;
    color: #ffffff;
    text-align: center;
    line-height: 25px;
    font-size: 9px;
    border-radius: 3px;
  }
  .activeredius {
    background-color: #0076fc;
  }
}
</style>

