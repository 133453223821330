<template>
  <div class="cantainer">
    <div class="threeTitle">
      <div class="titleL"></div>
      城市规划
      <div class="titleR"></div>
    </div>
    <div class="planBox">
      <div class="planTopImg">
        <img v-lazy="require('../assets/plan/planTopImg.png')" />
      </div>
      <div class="plans">
        <img class="topImg" v-lazy="require('../assets/plan/planTop.png')" />
        <img class="eastImg" v-lazy="require('../assets/plan/planE.png')" />
        <span class="eastText">东部地区</span>
        <span class="eastTextB">THE EASTERN REGION</span>
        <div class="planTitle">高起点规划东部区域，开辟城市永续发展新空间</div>
        <div class="planTitleBox">
          <span class="planTwoTitle">范围</span>
        </div>
        <div class="planContent">
          东部区域是都市功能新区,是全市经济社会发展“第二主战场”,总面积约3976平方公里,包含简阳市、金堂县全域,以及天府新区直管区、龙泉驿区和青白江区部分区域。
        </div>
        <div class="planContentImgBox">
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/east1.png')" />
          </div>
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/east2.png')" />
          </div>
        </div>
        <div class="planTitleBox">
          <span class="planTwoTitle">定位</span>
        </div>
        <div class="planContent">
          南部区域将重点抓好天府新区建设,天府新区是“一带一路”建设和长江经济带发展的重要节点,特别是要突出公园城市特点,把生态价值考虑进去,努力打造新的增长极,建设内陆开放经济高地。
        </div>
        <div class="planContentImgBox">
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/east3.png')" />
          </div>
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/east4.png')" />
          </div>
        </div>
        <div class="planTitleBox">
          <span class="planTwoTitle">发展策略</span>
        </div>
        <div class="planContent">
          东部区域将加快建设天府国际机场及高铁枢纽,融入国家高铁网络,强化国际门户枢纽功能。<br />
          &emsp;&emsp;重点发展大数据、人工智能、物联网、工业4.0等创新经济产业;航空制造、航空物流、飞机总装、航天装备等航空航天产业;新能源汽车、高端装备、节能环保和智能制造等先进制造业。<br />
          &emsp;&emsp;积极发展研发设计、检验检测、航空物流等生产性服务业,以及高端农产品等高端农业。<br />
          &emsp;&emsp;重点建设“四城一园”,包括天府国家空港新城、简州新城、淮州新城、简阳城区和龙泉山城市森林公园。加强与德阳、绵阳、眉山、资阳等城市产业协作升级,共同打造长240公里的龙泉山东侧产业走廊,形成成渝城市群新兴增长极。<br />
        </div>
        <div class="keynote">
          <div class="keynotetitleBox">
            <span class="keynotetitle">重点发展</span>
          </div>
          <div class="keynoteContent">
            <div class="keynoteContentL">
              <div class="keynoteContentLT">创新经济产业</div>
              <div class="keynoteContentLB">
                <span>大数据</span>
                <span>人工智能</span>
                <span>物联网</span>
                <span>工业4.0</span>
              </div>
            </div>
            <div class="keynoteContentC">
              <div class="keynoteContentLT">航空航天产业</div>
              <div class="keynoteContentCB">
                <span>航空制造</span>
                <span>航空物流</span>
                <span>飞机总装</span>
                <span>航空制造</span>
              </div>
            </div>
            <div class="keynoteContentR">
              <div class="keynoteContentLT">先进制造业</div>
              <div class="keynoteContentRB">
                <span>新能源汽车</span>
                <span>高端装备</span>
                <span>节能环保</span>
                <span>智能制造</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="plans planSout">
        <img class="topImg" v-lazy="require('../assets/plan/planTop.png')" />
        <img class="eastImg" v-lazy="require('../assets/plan/planS.png')" />
        <span class="eastText">南部地区</span>
        <span class="eastTextB">THE SOUTHERN REGION</span>
        <div class="planTitle">高水平发展南部区域，打造区域创新发展新引擎</div>
        <div class="planTitleBox">
          <span class="planTwoTitle">范围</span>
        </div>
        <div class="planContent">
          南部区域是都市功能拓展区,是成都高新技术产业策源地,是天府新区、国家自主创新示范区、国家自贸试验区的核心区,总面积1205平方公里,包含新津县全域和天府新区直管区、双流区、邛崃市部分区域。
        </div>
        <div class="planContentImgBox">
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/sout1.png')" />
          </div>
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/sout2.png')" />
          </div>
        </div>
        <div class="planTitleBox">
          <span class="planTwoTitle">定位</span>
        </div>
        <div class="planContent">
          南部区域将重点抓好天府新区建设,天府新区是“一带一路”建设和长江经济带发展的重要节点,特别是要突出公园城市特点,把生态价值考虑进去,努力打造新的增长极,建设内陆开放经济高地。
        </div>
        <div class="planContentImgBox">
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/sout3.png')" />
          </div>
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/sout4.png')" />
          </div>
        </div>
        <div class="planTitleBox">
          <span class="planTwoTitle">发展策略</span>
        </div>
        <div class="planContent">
          拓展新兴功能,加快集聚行政服务、科技创新、国际交往、总部办公、会展博览、文创旅游等功能。重点建设天府中心、成都科学城、天府国际生物城、鹿溪智谷、临空经济功能区、天府农博园等,强化科技创新中心、国际会展中心、区域性总部基地、高新技术产业服务基地、国际版权交易中心,形成高新技术聚集区和高新技术产业服务区,成为全市建设现代化经济体系的战略支撑。
        </div>
      </div>
      <div class="plans planWest">
        <img class="topImg" v-lazy="require('../assets/plan/planTop.png')" />
        <img class="eastImg" v-lazy="require('../assets/plan/planW.png')" />
        <span class="eastText">西部地区</span>
        <span class="eastTextB">THE WESTERN REGION</span>
        <div class="planTitle">高标准提升西部区域,探索城市绿色发展新模式</div>
        <div class="planTitleBox">
          <span class="planTwoTitle">范围</span>
        </div>
        <div class="planContent">
          西部区域是都市现代农业和生态涵养功能区,包括全市最重要的水源涵养地、都江堰灌区,是践行“绿水青山就是金山银山”的重要区域。总面积7185平方公里,包含都江堰市、崇州市、大邑县、蒲江县全域和温江区、郫都区、彭州市、邛崃市、高新西区部分区域。
        </div>
        <div class="planContentImgBox">
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/west1.png')" />
          </div>
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/west2.png')" />
          </div>
        </div>
        <div class="planTitleBox">
          <span class="planTwoTitle">定位</span>
        </div>
        <div class="planContent">
          西部区域定位为成都市最重要的生态功能区和蔬菜粮食生产功能区、西部绿色低碳科技产业示范区、国家生态宜居现代田园城市典范区、世界旅游目的地核心区和天府农耕文明重要展示区。
        </div>
        <div class="planContentImgBox">
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/west3.png')" />
          </div>
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/west4.png')" />
          </div>
        </div>
        <div class="planTitleBox">
          <span class="planTwoTitle">发展策略</span>
        </div>
        <div class="planContent">
          西部区域将以持续优化提升生态环境和发展绿色产业为重点,推进绿色发展。强化龙门山生态涵养区保护,严格保护基本农田,修复都江堰灌区。西部区域划定生态保护红线面积1120平方公里,占全市生态红线面积的99%。严格产业准入,重点发展生物医药、高端康养、生物农业、文化创意、绿色种养等绿色产业,建设电子信息产业功能区、航空动力产业园、成都健康产业功能区、大青城休闲旅游产业园、都市现代农业高科技产业园、川菜产业园、优质粮油产业园等绿色科技产业园区,构建绿色化产业体系。突出农业农村优先发展,建设乡村振兴和城乡融合发展示范区。严格规模控制。缩减城乡建设用地规模总量,建设用地在2020年规划规模的基础上,统一调减20%,共减少183平方公里,平坝与浅丘地区国土开发强度控制在23%以内;控制人口总量,规划总人口385万人,在现状人口规模的基础上减少59万人。
        </div>
        <div class="keynote">
          <div class="keynotetitleBox">
            <span class="keynotetitle">重点发展</span>
          </div>
          <div class="keynoteContent">
            <div class="content">
              <div class="contentImgBox">
                <img v-lazy="require('../assets/plan/westKey1.png')" />
              </div>
              <div>生物医药</div>
            </div>
            <div class="content">
              <div class="contentImgBox">
                <img v-lazy="require('../assets/plan/westKey2.png')" />
              </div>
              <div>高端养生</div>
            </div>
            <div class="content">
              <div class="contentImgBox">
                <img v-lazy="require('../assets/plan/westKey3.png')" />
              </div>
              <div>创意文化</div>
            </div>
            <div class="content">
              <div class="contentImgBox">
                <img v-lazy="require('../assets/plan/westKey4.png')" />
              </div>
              <div>绿色养殖</div>
            </div>
          </div>
        </div>
      </div>
      <div class="plans planNort">
        <img class="topImg" v-lazy="require('../assets/plan/planTop.png')" />
        <img class="eastImg" v-lazy="require('../assets/plan/planN.png')" />
        <span class="eastText">北部地区</span>
        <span class="eastTextB">THE NORTHERN REGION</span>
        <div class="planTitle">高质量改造北部区域,增强区域一体功能新支撑</div>
        <div class="planTitleBox">
          <span class="planTwoTitle">范围</span>
        </div>
        <div class="planContent">
          北部区域是都市功能优化区。总面积705平方公里,包含青白江区、新都区、彭州市部分区域。
        </div>
        <div class="planContentImgBox">
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/nort1.png')" />
          </div>
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/nort2.png')" />
          </div>
        </div>
        <div class="planTitleBox">
          <span class="planTwoTitle">定位</span>
        </div>
        <div class="planContent">
          北部区域定位为“一带一路”重要铁路门户枢纽、成德绵区域协同发展先导区、成都市北部生态屏障、产业转型发展示范区、城市有机更新示范区和彰显天府文化的和谐宜居家园。
        </div>
        <div class="planContentImgBox">
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/nort3.png')" />
          </div>
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/nort4.png')" />
          </div>
        </div>
        <div class="planTitleBox">
          <span class="planTwoTitle">发展策略</span>
        </div>
        <div class="planContent">
          北部区域重点加强通风廊道管控和区域绿廊、绿道等生态建设,划定一级通风廊道344平方公里,占北部区域49%,严控通风廊道内建设。加快国际铁路港能力提升。依托国际铁路港,建设国际铁路物流贸易中心、国际农产品交易中心,强化铁路港门户枢纽功能。加强与天府国际机场以及双流国际机场的铁空联运,加强与宜宾港、泸州港的铁水联运,接入高速公路网加强铁公联运,构建通达全球、功能强大、陆海内外联动的铁公空水多式联运中心。
        </div>
        <div class="strategy">
          <div class="strategyTitle">发展<br />策略</div>
          <div class="strategyContent">
            <div class="content1">
              <div class="content1L">1</div>
              <div class="content1R">
                打造跨区域高科技产业带和军民融合典范区,构建适应开放型经济要求的产业体系。
              </div>
            </div>
            <div class="content2">
              <div class="content1L">2</div>
              <div class="content1R">
                改造提升老工业基地、老旧居住区和老场慎。保护利用好历史性.标志性建筑,塑造城市条观特色
              </div>
            </div>
            <div class="content3">
              <div class="content1L">3</div>
              <div class="content1R">
                将北部区域援建设成为城市有机更新和产城融合示范区。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="plans planCenter">
        <img class="topImg" v-lazy="require('../assets/plan/planTop.png')" />
        <img class="eastImg" v-lazy="require('../assets/plan/planC.png')" />
        <span class="eastText">中部地区</span>
        <span class="eastTextB">THE CENTRAL REGION</span>
        <div class="planTitle">
          高品质优化中部区域,开创美丽宜居公园城市新路径
        </div>
        <div class="planTitleBox">
          <span class="planTwoTitle">范围</span>
        </div>
        <div class="planContent">
          中部区域是都市功能优化区,是成都2300年城市文脉起源地,总面积1264平方公里,包含高新南区、锦江区、青羊区、金牛区、武侯区、成华区全域以及高新西区、天府新区直管区、龙泉驿区、青白江区、新都区、温江区、双流区、郫都区部分区域。
        </div>
        <div class="planContentImgBox">
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/center1.png')" />
          </div>
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/center2.png')" />
          </div>
        </div>
        <div class="planTitleBox">
          <span class="planTwoTitle">定位</span>
        </div>
        <div class="planContent">
          中部区域定位为高端服务业集聚区、战略性新兴产业核心区、创新驱动引领区、国际交往核心区、天府文化集中展示区,城市极核和最能代表国家中心城市能级水平的高品质高能级生活城区。
        </div>
        <div class="planContentImgBox">
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/center3.png')" />
          </div>
          <div class="eastImgBox">
            <img v-lazy="require('../assets/plan/center4.png')" />
          </div>
        </div>
        <div class="planTitleBox">
          <span class="planTwoTitle">发展策略</span>
        </div>
        <div class="planContent">
          中部区域将重点实施“三降两提”。<br />
          &emsp;&emsp;降低开发强度,住宅和商业用地平均容积率降低三分之一;降低人口密度，调减规划人口185万人,规划人口密度从1.58万人/平方公里调减至1.36万人/平方公里;降低建筑尺度,加强对建筑体量和建筑形体布局的控制;提高产业层次,疏解一般性制造业、批发市场及仓储物流等非核心功能,集聚金融商务、总部办公、文化交往、创新创意、都市旅游等功能,发展工业与时尚设计、人工智能、虚拟现实、软件服务、生物医药、新一代信息技术等产业;提高产业层次,疏解一般性制造业、批发市场及仓储物流等非核心功能,集聚金融商务、总部办公、文化交往、创新创意、都市旅游等功能,发展工业与时尚设计、人工智能、虚拟现实、软件服务、生物医药、新一代信息技术等产业;提升城市品质,优化城市环境,完善配套,改善交通,保护好历史文化名城和特色风貌片区、特色风貌街道,彰显文化特色。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post } from "../config/request";
export default {
  data() {
    return {
      id: 0,
      cantainerArr: [],
    };
  },
  mounted() {
    this.id = window.GetAreaInfo("id");
    // document.getElementById("cityPlanBox").style.height = "auto";
    setTimeout((e) => {
      document.getElementById("cityPlanBox").style.height = "auto";
    }, 500);
    // this.getResource();
  },
  methods: {
    // 获取城市政策
    getResource() {
      var that = this;
      post("content/policy", {
        analysisId: that.id,
      }).then((res) => {
        that.cantainerArr = res.data.map((i) => {
          i.data["description"] = i.description;
          return i.data;
        });
        that.cantainerArr.map((item) => {
          item.value = JSON.parse(item.value);
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.cantainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #0f0240;
  overflow-y: auto;
  align-items: center;
  .planBox {
    width: 100%;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    .planTopImg {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .plans {
      width: 100%;
      border: 1px dashed rgba(48, 154, 181, 1);
      padding: 45px 15px 20px 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 74px;
      position: relative;
      margin-bottom: 20px;
      .topImg {
        width: 214px;
        height: 74px;
        position: absolute;
        right: 0;
        top: -74px;
      }
      .eastImg {
        width: 75px;
        height: 94px;
        position: absolute;
        left: 0;
        top: -57px;
      }
      .eastText,
      .eastTextB {
        position: absolute;
        color: #fff;
      }
      .eastText {
        font-size: 18px;
        height: 24px;
        top: -24px;
        left: 38px;
      }
      .eastTextB {
        font-size: 10px;
        top: 0;
        left: 38px;
      }
      .planTitle {
        font-size: 14px;
        color: #ffffff;
        display: inline-block;
        border-bottom: 4px solid rgba(255, 255, 255, 0.3);
        margin-bottom: 24px;
      }
      .planTitleBox {
        width: 100%;
        .planTwoTitle {
          background: rgba(41, 182, 180, 1);
          border-radius: 4px;
          padding: 3px 10px;
          color: #fff;
          font-size: 18px;
        }
      }
      .planContent {
        width: 100%;
        line-height: 24px;
        color: #fff;
        font-size: 12px;
        text-indent: 2em;
        margin-top: 10px;
      }
      .planContentImgBox {
        width: 100%;
        padding: 9px;
        display: flex;
        justify-content: space-between;
        border: 1px dashed rgba(41, 182, 180, 1);
        background: #11124a;
        margin-top: 10px;
        overflow: hidden;
        margin-bottom: 20px;
        .eastImgBox {
          width: 134px;
          height: 88px;
          border: 1px dashed rgba(255, 255, 255, 0.3);
          img {
            width: 132px;
            height: 86px;
          }
        }
      }
      .keynote {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        .keynotetitleBox {
          width: 70%;
          height: 30px;
          border: 1px solid rgba(32, 123, 142, 1);
          border-bottom: none;
          position: relative;
          .keynotetitle {
            color: rgba(254, 254, 255, 1);
            font-size: 18px;
            background: #0f0240;
            position: absolute;
            padding: 0 5px;
            top: -13px;
            left: 84px;
          }
        }
        .keynoteContent {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .keynoteContentL,
          .keynoteContentC,
          .keynoteContentR {
            display: flex;
            flex-direction: column;
            color: #ffffff;
            font-size: 16px;
            align-items: center;
            .keynoteContentLB,
            .keynoteContentCB,
            .keynoteContentRB {
              width: 75px;
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 12px;
              margin-top: 10px;
              padding: 10px 0;
              span {
                line-height: 24px;
              }
            }
            .keynoteContentLB {
              background: #1e2d5e;
              border: 1px solid #4fbec3;
            }
            .keynoteContentCB {
              background: #231a6a;
              border: 1px solid #1068a8;
            }
            .keynoteContentRB {
              background: #261e58;
              border: 1px solid #757ca7;
            }
          }
        }
      }
    }
    .planSout {
      .eastImg {
        width: 111px;
        height: 85px;
      }
    }
    .planWest {
      .eastImg {
        width: 68px;
        height: 85px;
      }
      .keynote {
        .keynotetitleBox {
          width: 86%;
        }
      }
      .keynoteContent {
        margin-top: 10px;
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 12px;
          color: #ffffff;
          .contentImgBox {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            border: 1px solid #52c7ca;
            background: #1a2357;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              height: 20px;
            }
          }
        }
      }
    }
    .planNort {
      .eastImg {
        width: 104px;
        height: 93px;
      }
      .strategy {
        width: 100%;
        height: 158px;
        border: 2px solid #1d2e5e;
        border-right: none;
        border-radius: 79px 0 0 79px;
        display: flex;
        justify-content: space-between;
        margin-left: -30px;
        color: #ffffff;
        font-size: 12px;
        align-items: center;
        margin-top: 25px;
        .strategyTitle {
          width: 55px;
          height: 55px;
          border: 1px solid rgba(32, 123, 142, 1);
          border-radius: 50%;
          background: #1a2357;
          margin: 0 10px;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .strategyContent {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: -20px;
          .content1,
          .content2,
          .content3 {
            display: flex;
            line-height: 18px;
            justify-content: space-between;
            align-items: center;
            border-radius: 100px;
            padding: 5px 0;
            .content1L {
              width: 27px;
              height: 27px;
              border-radius: 50%;
              font-size: 14px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 4px;
            }
            .content1R {
              flex: 1;
            }
          }
          .content1 {
            background: #207b8e;
            .content1L {
              background: #268a9e;
            }
          }
          .content2 {
            background: #4f31ae;
            margin: 7px 0;
            .content1L {
              background: #5d3ebf;
            }
          }
          .content3 {
            background: #2528b9;
            .content1L {
              background: #4143c9;
            }
          }
        }
      }
    }
  }
}
.threeTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 18px;
  margin: 20px 0;
  padding: 0 25px;
  .titleL,
  .titleR {
    flex: 1;
    height: 3px;
  }
  .titleL {
    background: linear-gradient(
      90deg,
      rgba(110, 98, 228, 0),
      rgba(110, 98, 228, 1)
    );
    margin-right: 10px;
  }
  .titleR {
    background: linear-gradient(
      90deg,
      rgba(110, 98, 228, 1),
      rgba(110, 98, 228, 0)
    );
    margin-left: 10px;
  }
}
</style>