<template>
  <div class="userinfo">
    <!-- <div class="userinfo-title">
      <div class="back">
        <div class="back-icon">
          <img
            src="../assets/user/back.png"
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
      <div class="back-title">个人信息</div>
    </div> -->
    <div class="inputBox">
      <input type="text" v-model="email" class="uinput" />
    </div>
    <div class="submit" @click="commit">保存修改</div>
  </div>
</template>

<script>
import { post } from "../config/request";
export default {
  data() {
    return {
      email: "",
    };
  },
  created() {
    document.title = "修改邮箱";
  },
  mounted() {
    var that = this;
    that.email = window.GetUserInfo().email;
  },
  methods: {
    commit() {
      if (this.email == "") {
        window.ShowMsg("请输入正确的邮箱地址！");
        return;
      }
      var emailPage =
        /^[a-z0-9](\w|\.|-)*@([a-z0-9]+-?[a-z0-9]+\.){1,3}[a-z]{2,4}$/i;
      if (!emailPage.test(this.email))
        return window.ShowMsg("邮箱格式错误，请重新输入！");
      post("/content/update_email", { email: this.email }).then((res) => {
        console.log(res);
        if (res.data == true) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          post("/account/userinfo", {}).then((res) => {
            if (res.code > 0) {
              window.SetUserInfo({ token: null, expiresIn: 0 });
              window.ShowMsg("获取信息失败，请重新登录！");
              window.RedirectTo("index");
            } else {
              console.log(res.data.email);
              var userInfo = {
                userId: res["data"]["id"] || 0,
                userName: res["data"]["name"] || "",
                phone: res["data"]["mobile"] || "",
                email: res["data"]["email"],
              };
              window.SetUserInfo(userInfo);
              window.pageEvent.send("updateUserInfo", "");
            }
          });
        } else {
          this.$message.error("修改失败");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.userinfo {
  width: 100%;
  height: 100vh;
  background: #0f0240;
  // padding-top: 19px;
  display: flex;
  flex-direction: column;
  .userinfo-title {
    display: flex;
    color: #ffffff;
    align-items: center;
    .back {
      width: 50px;
      height: 21px;
      .back-icon {
        width: 12px;
        height: 21px;
        margin-left: 18px;
      }
    }
    .back-title {
      font-size: 17px;
    }
  }
  .inputBox {
    margin: 47px auto 0px auto;
    .uinput {
      width: 315px;
      height: 55px;
      background: #1a255a;
      border: none;
      color: #c2cbff;
      outline: medium;
      padding-left: 17px;
    }
  }
  .submit {
    width: 80%;
    height: 55px;
    background: linear-gradient(49deg, #3995fc, #00cffd);
    border-radius: 3px;
    position: fixed;
    bottom: 58px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    line-height: 55px;
    font-size: 15px;
    color: #ffffff;
  }
}
</style>