import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Report from '../views/report.vue'
import Economy from '../views/economy.vue'
import Catalogue from '../views/catalogue.vue'
import Login from '../views/login.vue'
import UrbanResources from '../views/urbanResources.vue'
import Office from '../views/office.vue'
import policy from '../views/policy.vue'
import cityPlan from '../views/cityPlan.vue'
import peripheralMarket from '../views/peripheralMarket.vue'
import commercialMarket from '../views/commercialMarket.vue'
import Population from '../views/population.vue'
import Summary from '../views/summary.vue'
import location from '../views/location.vue'
import fundamentals from '../views/fundamentals.vue'
import strictSelection from '../views/strictSelection.vue'
import details from '../views/details.vue'
import userCollection from '../views/userCollection.vue'
import userReport from '../views/userReport.vue'
import userAnalysis from '../views/userAnalysis.vue'
import UserInfo from '../views/userInfo.vue'
import Revise from '../views/revise.vue'
import VipCenter from '../views/vipCenter.vue'
import Privacy from '../views/privacy.vue'
import Agreement from '../views/agreement.vue'
import VipRecord from '../views/vipRecord.vue'
import User from '../views/user.vue'
import Email from '../views/email.vue'
import AboutUs from '../views/aboutUs.vue'



Vue.use(VueRouter)

const routes = [{
        path: '/index',
        name: 'Login',
        component: Login
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/report',
        name: 'report',
        component: Report
    },
    {
        path: '/location',
        name: 'location',
        component: location
    },
    {
        path: '/fundamentals',
        name: 'fundamentals',
        component: fundamentals
    },
    {
        path: '/economy',
        name: 'Economy',
        component: Economy
    },
    {
        path: '/catalogue',
        name: 'Catalogue',
        component: Catalogue
    },
    {
        path: '/urbanResources',
        name: 'UrbanResources',
        component: UrbanResources
    },
    {
        path: '/office',
        name: 'Office',
        component: Office
    },
    {
        path: '/peripheralMarket',
        name: 'peripheralMarket',
        component: peripheralMarket
    },
    {
        path: '/commercialMarket',
        name: 'commercialMarket',
        component: commercialMarket
    },
    {
        path: '/policy',
        name: 'policy',
        component: policy
    },
    {
        path: '/cityPlan',
        name: 'cityPlan',
        component: cityPlan
    },
    {
        path: '/population',
        name: 'Population',
        component: Population
    },
    {
        path: '/summary',
        name: 'Summary',
        component: Summary
    },
    {
        path: '/strictSelection',
        name: 'strictSelection',
        component: strictSelection
    },
    {
        path: '/details',
        name: 'details',
        component: details
    },
    {
        path: '/userCollection',
        name: 'userCollection',
        component: userCollection
    },
    {
        path: '/userReport',
        name: 'userReport',
        component: userReport
    },
    {
        path: '/userAnalysis',
        name: 'userAnalysis',
        component: userAnalysis
    },
    {
        path: '/userinfo',
        name: 'UserInfo',
        component: UserInfo
    },
    {
        path: '/revise',
        name: 'Revise',
        component: Revise
    },
    {
        path: '/vipcenter',
        name: 'VipCenter',
        component: VipCenter
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy
    },
    {
        path: '/agreement',
        name: 'Agreement',
        component: Agreement
    },
    {
        path: '/record',
        name: 'VipRecord',
        component: VipRecord
    },
    {
        path: '/user',
        name: 'User',
        component: User
    },
    {
        path: '/email',
        name: 'Email',
        component: Email
    },
    {
        path: '/aboutus',
        name: 'AboutUs',
        component: AboutUs
    },
]

const router = new VueRouter({
    routes
})

export default router
window.router = router;

// 路由拦截器
router.beforeEach((to, from, next) => {
    if (window.GetUserInfo() || to.name == 'Login') {
        next();
    } else {
        next({ path: '/index' })
    }
    next();
})