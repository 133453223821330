<template>
  <div class="commercialMarket">
    <div class="threeTitle">
      <div class="titleL"></div>
      商业市场
      <div class="titleR"></div>
    </div>
    <div class="ecsBox">
      <div class="volume">
        <div class="titles">商业体量</div>
        <div class="volumeNumBox">
          <img src="../assets/market/marketTL.png" />
          <div class="volumeNum">{{ bizVolume[0].area }}万<span>㎡</span></div>
        </div>
        <div class="explainBox">
          数据说明：{{
            bizVolume[bizVolume.length - 1].year
          }}年至今，出让土地商业配比理论开发体量
        </div>
      </div>
    </div>
    <div class="mapBoxs">
      <div class="mapTitle">
        <div class="titleStyle">大型商业体</div>
        <div class="mapLegend">
          <div class="mapLegendL">
            <div></div>
            大型超市
          </div>
          <div class="mapLegendC">
            <div></div>
            步行街
          </div>
          <div class="mapLegendR">
            <div></div>
            集中式商业
          </div>
        </div>
      </div>
      <div id="bigCommercial"></div>
    </div>
    <div class="peripheralBusinessBox">
      <div class="ecsBoxs">
        <div id="peripheralBusiness"></div>
      </div>
    </div>
    <div class="rateOfReturnBox">
      <div class="ecsBoxs">
        <div class="titles">商业投资回报率</div>
        <div class="contentBox">
          <div class="content1">
            <div class="contentL">
              <div class="contentLtitle">区域平均挂牌月租金</div>
              <div class="contentLnum">{{ rentPrice }} <span>元/㎡</span></div>
            </div>
            <div class="contentR">
              <img src="../assets/market/zu.png" />
            </div>
          </div>
          <div class="content2">
            <div class="contentL">
              <div class="contentLtitle">区域平均挂牌售价</div>
              <div class="contentLnum">{{ sellPrice }} <span>元/㎡</span></div>
            </div>
            <div class="contentR">
              <img src="../assets/market/shou.png" />
            </div>
          </div>
          <div class="content3">
            <div class="contentL">
              <div class="contentLtitle">区域投资年回报率(静态)</div>
              <div class="contentLnum">{{ roi * 100 }} <span>%</span></div>
            </div>
            <div class="contentR">
              <img src="../assets/market/huibao.png" />
            </div>
          </div>
        </div>
        <div class="explainBox">统计时间：{{ sumDate }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { EyesMap } from "../config/showMap.js"; // 引入地图
import { post } from "../config/request";
export default {
  data() {
    return {
      centers: [], //中心点
      radius: 4000, //查询半径
      zoom: 12, //地图缩放1000-14 2000-13 3000-12.5 4000-12 ，5000-11.7
      pathArr: [], //多边形坐标数组
      id: 0,
      sumDate: "",
      rentPrice: "",
      roi: "",
      sellPrice: "",
      bizFormat: [], //商业构成
      bizBuildings: [], //大型商业体
      bizVolume: [
        { area: "", year: "" },
        { area: "", year: "" },
      ], //商业体量
    };
  },
  created() {
    this.id = window.GetAreaInfo("id");
    console.log(this.id);
    this.getBizMarket();
  },
  mounted() {
    setTimeout((e) => {
      document.getElementById("commercialMarketBox").style.height = "auto";
    }, 500);
  },
  methods: {
    getBizMarket() {
      var that = this;
      post("content/biz_market", {
        analysisId: that.id,
      }).then((res) => {
        console.log(res);
        that.sumDate = res.data.roiInfo.rent.sumDate;
        that.rentPrice = res.data.roiInfo.rent.avgPrice;
        that.roi = res.data.roiInfo.roi;
        that.roi = Number(that.roi).toFixed(2);
        that.sellPrice = res.data.roiInfo.sell.avgPrice;
        that.bizFormat = res.data.bizFormat;
        that.bizBuildings = res.data.bizBuildings;
        var bizVolumeList = res.data.bizVolume;
        for (var i = 0; i < 2; i++) {
          if (!bizVolumeList[i]) bizVolumeList.push({ area: "0", year: "2000" });
        }
        that.bizVolume = res.data.bizVolume;
        that.peripheralBusinessEcs();
        that.bigCommercialMap();
      });
    },
    bigCommercialMap() {
      var that = this;
      var ShoppingMallArr = [];
      var CommercialStreetArr = [];
      var CentralizedCommerceArr = [];
      var areaInfo = window.GetAreaInfo();
      if (that.bizBuildings.length != 0) {
        for (var i = 0; i < that.bizBuildings.length; i++) {
          if (that.bizBuildings[i].dname == "购物中心") {
            ShoppingMallArr.push(that.bizBuildings[i]);
          } else if (that.bizBuildings[i].dname == "商业街") {
            CommercialStreetArr.push(that.bizBuildings[i]);
          } else {
            CentralizedCommerceArr.push(that.bizBuildings[i]);
          }
        }
        ShoppingMallArr = ShoppingMallArr.map((i) => {
          return {
            name: i.name,
            area: i.area,
            geom: i.point,
            color: "rgba(227, 59, 123, 1)",
          };
        });
        CommercialStreetArr = CommercialStreetArr.map((i) => {
          return {
            name: i.name,
            area: i.area,
            geom: i.point,
            color: "rgba(30, 175, 182, 1)",
          };
        });
        CentralizedCommerceArr = CentralizedCommerceArr.map((i) => {
          return {
            name: i.name,
            area: i.area,
            geom: i.point,
            color: "rgba(66, 73, 188, 1)",
          };
        });
      }
      EyesMap.InitMap("bigCommercial", (map) => {
        EyesMap.ShowArea(map, areaInfo);
        EyesMap.ShowLabel(map, ShoppingMallArr);
        EyesMap.ShowLabel(map, CommercialStreetArr);
        EyesMap.ShowLabel(map, CentralizedCommerceArr);
      });
    },
    peripheralBusinessEcs() {
      var chartDom = document.getElementById("peripheralBusiness");
      var myChart = echarts.init(chartDom);
      var data = [];
      data = this.bizFormat.map((item) => {
        return {
          value: item.count,
          name: item.dname,
        };
      });
      var option;
      option = {
        title: {
          text: "周边商业业态构成",
          textStyle: {
            //文字颜色
            color: "#fff",
            //字体大小
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "业态构成",
            type: "pie",
            radius: ["30%", "55%"],
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              alignTo: "edge",
              formatter: "{name|{b}}\n{time|{d} %}", //"{name|{b}}\n{time|{c} %}"
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 15,
              color: "rgba(66, 60, 155, 1)",
              fontSize: 12,
              rich: {
                time: {
                  fontSize: 10,
                  color: "rgba(189, 184, 255, 1)",
                },
              },
            },
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 80,
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.commercialMarket {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #0f0240;
  overflow-y: auto;
  align-items: center;
  .ecsBox {
    width: 100%;
    color: #fff;
    padding: 0 15px;
    .volume {
      background: #231765;
      border-radius: 4px;
      width: 100%;
      height: 194px;
      display: flex;
      flex-direction: column;
      padding: 15px;
      .volumeNumBox {
        position: relative;
        border-bottom: 1px solid rgba(59, 33, 131, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 119px;
        img {
          position: absolute;
          width: 233px;
          height: 109px;
        }
        .volumeNum {
          font-size: 18px;
          z-index: 100;
        }
      }
    }
  }
  .mapBoxs {
    width: 100%;
    display: flex;
    flex-direction: column;
    .mapTitle {
      width: 100%;
      padding: 0 15px 0 30px;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      .mapLegend {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        .mapLegendL,
        .mapLegendC,
        .mapLegendR {
          display: flex;
          font-size: 10px;
          color: #fff;
          align-items: center;
          div {
            width: 8px;
            height: 8px;
            margin-right: 4px;
          }
        }
        .mapLegendL {
          margin-right: 17px;
          div {
            background: rgba(227, 59, 123, 1);
          }
        }
        .mapLegendC {
          margin-right: 17px;
          div {
            background: rgba(30, 175, 182, 1);
          }
        }
        .mapLegendR {
          div {
            background: rgba(66, 73, 188, 1);
          }
        }
      }
    }
    #bigCommercial {
      width: 100%;
      height: 297px;
    }
  }
  .peripheralBusinessBox {
    padding: 15px;
    width: 100%;
    .ecsBoxs {
      width: 100%;
      height: 254px;
      background: #231765;
      border-radius: 4px;
      padding: 15px;
      #peripheralBusiness {
        width: 100%;
        height: 254px;
      }
    }
  }
  .rateOfReturnBox {
    width: 100%;
    padding: 0 15px;
    .ecsBoxs {
      padding: 15px;
      display: flex;
      flex-direction: column;
      background: #231765;
      border-radius: 4px;
      .contentBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        color: #fff;
        .content1,
        .content2,
        .content3 {
          padding: 15px 24px 10px 12px;
          display: flex;
          justify-content: space-between;
          border-radius: 4px;
          .contentL {
            flex: 1;
            .contentLtitle {
              font-size: 12px;
              margin-bottom: 5px;
            }
            .contentLnum {
              font-size: 18px;
              span {
                font-size: 12px;
              }
            }
          }
          .contentR {
            img {
              width: 42px;
              height: 42px;
            }
          }
        }
        .content1 {
          background: linear-gradient(
            -62deg,
            rgba(5, 65, 139, 0.61),
            rgba(0, 184, 184, 0.61)
          );
          margin-top: 20px;
        }
        .content2 {
          background: linear-gradient(
            -62deg,
            rgba(96, 28, 129, 0.61),
            rgba(188, 66, 122, 0.61)
          );
          margin: 10px 0;
        }
        .content3 {
          background: linear-gradient(
            -62deg,
            rgba(79, 21, 120, 0.61),
            rgba(117, 42, 180, 0.61)
          );
        }
      }
    }
  }
}
.dataDescription {
  font-size: 12px;
  color: rgba(66, 60, 155, 1);
  text-align: center;
  margin-top: 10px;
}
.titles {
  color: #fff;
  font-size: 14px;
}
.threeTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 18px;
  margin: 20px 0;
  padding: 0 25px;
  .titleL,
  .titleR {
    flex: 1;
    height: 3px;
  }
  .titleL {
    background: linear-gradient(
      90deg,
      rgba(110, 98, 228, 0),
      rgba(110, 98, 228, 1)
    );
    margin-right: 10px;
  }
  .titleR {
    background: linear-gradient(
      90deg,
      rgba(110, 98, 228, 1),
      rgba(110, 98, 228, 0)
    );
    margin-left: 10px;
  }
}
</style>