//新打开窗口,保留当前页面，跳转到应用内的某个页面。但是不能跳到 tabbar 页面。使用 wx.navigateBack 可以返回到原页面。小程序中页面栈最多十层。
window.NavigateTo = function(routerPath, param) {
    //routerPath是Vuejs路由中设置的Path，不需要第一个“/”
    if (param) {
        param = "&param=" + encodeURIComponent(JSON.stringify(param));
    } else {
        param = "";
    };
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        parent.wx.miniProgram.getEnv(function(res) {
            if (res.miniprogram) {
                console.log(666);
                parent.wx.miniProgram.navigateTo({
                    url: "../NewPage/NewPage?router=" + routerPath + param
                });
            } else {
                // alert("不是微信小程序")
                console.log(7777);
                window.open(window.location.origin + "/#/" + routerPath + "?1" + param);
            };
        });
    } else {
        // alert("不是微信小程序")
        console.log(8888);
        window.open(window.location.origin + "/#/" + routerPath + "?1" + param);
    };
};
//关闭当前页面，跳转到应用内的某个页面。但是不允许跳转到 tabbar 页面。
window.RedirectTo = function(routerPath, param) {
    if (param) {
        param = "&param=" + encodeURIComponent(JSON.stringify(param));
    } else {
        param = "";
    };
    window.router.push("/" + routerPath + "?1" + param);
};
//关闭所有页面，打开到应用内的某个页面
window.ReLaunch = function(routerPath, param) {
    if (param) {
        param = "&param=" + encodeURIComponent(JSON.stringify(param));
    } else {
        param = "";
    };
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        parent.wx.miniProgram.getEnv(function(res) {
            if (res.miniprogram) {
                parent.wx.miniProgram.reLaunch({
                    url: "../NewPage/NewPage?router=" + routerPath + param
                });
            } else {
                // alert("不是微信小程序")
                window.location = window.location.origin + "/#/" + routerPath + "?1" + param;
            };
        });
    } else {
        // alert("不是微信小程序")
        window.location = window.location.origin + "/#/" + routerPath + "?1" + param;
    };
};
//关闭当前页面，返回上一页面或多级页面。可通过 getCurrentPages 获取当前的页面栈，决定需要返回几层。
window.NavigateBack = function() {
    parent.wx.miniProgram.navigateBack();
};
//获取传递的参数
window.GetParam = function(key) {
    var params = window.location.hash.split("=");
    if (params.length == 2) {
        try {
            var param = JSON.parse(decodeURIComponent(params[1]));
            if (key) {
                return param[key] || "";
            } else {
                return param;
            }
        } catch (e) {
            return {};
        };
    } else {
        return {};
    };
};
//获取数组最大值、最小值和分割间隔
window.GetInterval = function(arr, fileds) {
    var res = {
        "min": 0,
        "max": -999999999,
        "interval": 0
    };
    if (fileds && fileds.length > 0) {
        for (var i = 0; i < fileds.length; i++) {
            var max = Math.max.apply(Math, arr.map(item => {
                return item[fileds[i]]
            }));
            if (max > res["max"]) res["max"] = max;
            var min = Math.min.apply(Math, arr.map(item => {
                return item[fileds[i]]
            }));
            if (min < res["min"]) res["min"] = min;
        };
    } else {
        res["max"] = Math.max.apply(Math, arr);
        res["min"] = Math.min.apply(Math, arr);
    };

    if (res["min"] > 0) {
        res["min"] = 0;
    } else {
        if (res["min"] % 5 != 0) res["min"] = parseInt(res["min"]) - (5 - Math.abs(parseInt(res["min"] % 5)));
    };
    if (res["max"] % 5 != 0) res["max"] = parseInt(res["max"]) + (5 - Math.abs(parseInt(res["max"] % 5)));
    res["interval"] = (res["max"] - res["min"]) / 5;
    return res;
};

//清空UserInfo,并返回登录界面
window.ClearUserInfo = function() {
    var userInfo = {
        userId: 0,
        userName: "",
        phone: "",
        token: "",
        expiresIn: 0,
        cityCode: 510100,
        cityName: "成都",
        isVip: false,
        vipOverTime: null,
        cityCenter: [],
        cityZoom: 0,
        cityLimitBounds: []
    };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    window.ReLaunch("index");
};

//获取UserInfo,未登录或者登陆过期将返回空
window.SetUserInfo = function(res) {
    // 初始化本地用户信息，详细用户信息在首页中获取
    var userInfo = {};
    try {
        userInfo = window.GetUserInfo();
        if (!userInfo) {
            userInfo = {
                userId: res["userId"] || 0,
                userName: res["userName"] || "",
                phone: res["phone"] || "",
                meail: res["email"] || "",
                analysisCount: res["analysisCount"] || 0,
                analysisDocCount: res["analysisDocCount"] || 0,
                likedCount: res["likedCount"] || 0,
                token: res["token"] || "",
                expiresIn: res["expiresIn"] || 0,
                cityCode: res["cityCode"] || 510100,
                cityName: res["cityName"] || "成都",
                isVip: res["isVip"] || false,
                vipOverTime: res["vipOverTime"] || null,
                cityCenter: res["cityCenter"] || [],
                cityZoom: res["cityZoom"] || [],
                cityLimitBounds: res["cityLimitBounds"] || []
            };
        } else {
            for (let key in res) {
                userInfo[key] = res[key];
            };
        };
    } catch (e) {
        userInfo = {
            userId: res["userId"] || 0,
            userName: res["userName"] || "",
            phone: res["phone"] || "",
            meail: res["email"] || "",
            analysisCount: res["analysisCount"] || 0,
            analysisDocCount: res["analysisDocCount"] || 0,
            likedCount: res["likedCount"] || 0,
            token: res["token"] || "",
            expiresIn: res["expiresIn"] || 0,
            cityCode: res["cityCode"] || 510100,
            cityName: res["cityName"] || "成都",
            isVip: res["isVip"] || false,
            vipOverTime: res["vipOverTime"] || null,
            cityCenter: res["cityCenter"] || [],
            cityZoom: res["cityZoom"] || [],
            cityLimitBounds: res["cityLimitBounds"] || []
        };
    };
    // 保存token
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
};

//获取UserInfo,未登录或者登陆过期将返回空
window.GetUserInfo = function() {
    try {
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo && userInfo.token && userInfo.expiresIn && (new Date().getTime() < userInfo.expiresIn * 1000)) {
            return userInfo;
        } else {
            return null;
        };
    } catch (e) {
        return null;
    };
};

//提示信息
window.ShowMsg = function(title, type) {
    if (!type) type = "error";
    window.Vue.prototype.$message({
        showClose: true,
        message: title,
        type: type,
        offset: 20,
        center: true,
    });
};

//判断当前用户是否VIP
window.IsVIP = function() {
    var userInfo = window.GetUserInfo();
    if (userInfo && userInfo.isVip && (new Date().getTime() < userInfo.vipOverTime * 1000)) {
        return true;
    } else {
        return false;
    };
};

//设置本案中心点等信息（应该只在目录界面和本案及区位中获取并设置）
window.SetAreaInfo = function(res) {
    if (!res) res = {};
    localStorage.setItem("areaInfo", JSON.stringify(res));
};

//获取本案中心点等信息
window.GetAreaInfo = function(key) {
    try {
        var areaInfo = JSON.parse(localStorage.getItem("areaInfo"));
        if (areaInfo && areaInfo.center && areaInfo.center.length == 2) {
            if (key) {
                return areaInfo[key] || null;
            } else {
                return areaInfo;
            };
        } else {
            return {};
        };
    } catch (e) {
        return {};
    };
};

//深复制对象方法
window.cloneObj = function(obj) {
    var newObj = {};
    if (obj instanceof Array) {
        newObj = [];
    };
    for (var key in obj) {
        var val = obj[key];
        newObj[key] = typeof val === 'object' ? cloneObj(val) : val;
    };
    return newObj;
};

//区域预警（供销比）
window.supplyRateList = [
    { name: "供不应求", min: 0, max: 0.8 },
    { name: "供需均衡", min: 0.8, max: 1.2 },
    { name: "供大于求", min: 1.2, max: 999999 }
];

//区域预警（供销比）
window.stockRateList = [
    { name: "货源短缺期", min: 0, max: 6 },
    { name: "正常去化期", min: 6, max: 12 },
    { name: "去库存期", min: 12, max: 999999 }
];


//js 事件监听开始
/**
 *
 * EventName是事件名称，监听端和发送端相同，字符型。data为传输的参数，可以是字符型或者对象
 * 
 * useage:
 * 1. 注册事件监听
 * window.pageEvent.on(EventName,(data)=>{
 *    这里写你的业务逻辑
 *    debugger
 * });
 
 * 2. 发送事件
 * window.pageEvent.send(EventName,data)
 * 
 */
/**
 * 回调队列
 * 结构:{
 *     eventKey:'xxx',
 *     callback:[]
 * }
 */
let EventListener = function () {
  this.callback = [];
  this.send = function (eventKey, eventValue) {
    /**
     * 同一页面,localStorage.setItem 不触发 storage ,发送时 自动触发
     * uuid 保证变更,都能触发 storage事件
     */
    let newEventKey = 'evt_' + eventKey;
    let uuid = _uuid(8, 16);
    let newEventValue = uuid + '_' + (eventValue == undefined ? '' : eventValue);
    //手动触发本页面事件
    this.triggerCallback(newEventKey, newEventValue);
    localStorage.setItem(newEventKey, newEventValue);

  }
  this.on = function (eventKey, callback) {
    let callbackObject = _findCallbackObject(this, eventKey);
    if (callbackObject == undefined) {
      callbackObject = { eventKey: eventKey, callback: [callback] };
      this.callback.push(callbackObject);
    } else {
      callbackObject.callback.push(callback);
    }
  }
  /**
   * 触发事件对应的回调
   * removeItem setItem 都会触发
   * removeItem eventValue==null 不触发
   */
  this.triggerCallback = function (eventKey, eventValue) {
    if (_isEventKey(eventKey) && _isNotNull(eventValue)) {
      let originalEventKey = _resolveEventKey(eventKey);
      let callbackObject = _findCallbackObject(this, originalEventKey);
      if (callbackObject != undefined) {
        let callbackQueue = callbackObject.callback;
        if (callbackQueue.length > 0) {
          eventValue = _resolveEventValue(eventValue);
          for (let index in callbackQueue) {
            let callback = callbackQueue[index];
            try {
              callback(eventValue);
            } catch (e) {
              console.error("执行事件" + callbackObject.eventKey + "的函数失败", e)
            }
          }
        }
        //触发后删除
        localStorage.removeItem(eventKey);
      }
    }
  }

  /**
   * 查找到事件key对应的监听对象
   */
  function _findCallbackObject(_this, eventKey) {
    if (_this.callback.length > 0) {
      for (let index in _this.callback) {
        let callbackObject = _this.callback[index];
        if (callbackObject.eventKey == eventKey) {
          return callbackObject;
        }
      }
    }
  }

  function _resolveEventKey(eventKey) {
    return eventKey.split('_')[1];

  }

  function _isEventKey(eventKey) {
    if (eventKey.indexOf('evt_') < 0) {
      return false;
    }
    return true;
  }


  function _resolveEventValue(eventValue) {
    let start = eventValue.indexOf('_') + 1;
    let end = eventValue.length;
    return eventValue.substring(start, end);
  }

  function _isNotNull(value) {
    if (value == undefined || value == null) {
      return false;
    }
    return true;
  }


  function _uuid(len, radix) {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    let uuid = [], i;
    radix = radix || chars.length;
    if (len) {
      for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
      let r;
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
      uuid[14] = '4';
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | Math.random() * 16;
          uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
        }
      }
    }
    return uuid.join('');
  };
};

window.pageEvent = new EventListener();
//注册监听,不同页面也可触发监听事件
window.addEventListener('storage', function (event) {
  window.pageEvent.triggerCallback(event.key, event.newValue);
});

//js 事件监听结束





