<template>
  <div class="userReportBox">
    <div class="header">
      <div class="inputBox">
        <input
          type="text"
          @change="inputChange"
          v-model.trim="inputValue"
          placeholder="请输入文档关键字"
        />
        <img src="../assets/document/search.png" />
      </div>
    </div>
    <div class="main">
      <div
        name="contentBox"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="infoBox" @click="goReport(item.analysisId)">
          <div class="title f14 cFFF">{{ item.title }}</div>
          <div class="dateBox f12">
            <span class="overdueT f10" v-if="item.isExpired">有效期</span>
            <span class="overdueF f10" v-else>已过期</span>
            {{ item.expirationTime }}
          </div>
        </div>
        <div
          class="btnBox f12 cFFF"
          v-if="item.isExpired"
          @click="goLodes(item.analysisId)"
        >
          报告下载
        </div>
        <div
          class="btnBox f12 cFFF"
          v-else
          @click="goCatalogue(item.analysisId)"
        >
          重新获取
        </div>
        <div class="deleteBtnBox" @click="deleteContent(item.analysisId)">
          <div class="deleteBtn f16 cFFF">删除</div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="download"
      :append-to-body="true"
      :show-close="false"
      title="下载报告"
    >
      <el-input v-model.trim="email" placeholder="请输入邮箱地址"></el-input>
      <div class="dialog-text">
        <div class="dialog-t1">
          报告下载的链接将发送至您的邮箱，请及时查收。
        </div>
        <div>链接有效期:2010-07-07</div>
      </div>
      <span slot="footer" @click="sendOut">发送</span>
    </el-dialog>
  </div>
</template>
    
<script>
import { post } from "../config/request";
export default {
  data() {
    return {
      inputValue: "",
      overdue: false, //是否过期
      list: [],
      download: false,
      email: "",
    };
  },
  created() {
    document.title = "我的报告";
    this.getList();
  },
  mounted() {},
  methods: {
    getList() {
      post("content/my_report", {
        addr: this.inputValue,
      }).then((res) => {
        this.list = res.data;
        setTimeout(() => {
          this.touchs();
        }, 1000);
        console.log(res);
      });
    },
    inputChange() {
      this.getList();
    },
    goCatalogue(e) {
      window.RedirectTo("Catalogue", { id: e });
    },
    goReport(e) {
      window.RedirectTo("Catalogue", { id: e });
    },
    goLodes() {
      this.download = true;
    },
    sendOut() {
      if (this.email == "") {
        window.ShowMsg("请输入正确的邮箱地址！");
        return;
      }
      var emailPage =
        /^[a-z0-9](\w|\.|-)*@([a-z0-9]+-?[a-z0-9]+\.){1,3}[a-z]{2,4}$/i;
      if (!emailPage.test(this.email))
        return window.ShowMsg("邮箱格式错误，请重新输入！");
      window.ShowMsg("发送成功，请前往邮箱查看！", "success");
      this.download = false;
    },
    deleteContent(e) {
      post("content/delete_report", { analysisId: e }).then((res) => {
        if (res.code == 0) {
          this.list.some((item, i) => {
            if (item.analysisId == e) {
              this.list.splice(i, 1);
              return true;
            }
          });
          var container = document.getElementsByName("contentBox");
          for (var i = 0; i < container.length; i++) {
            container[i].className = "";
          }
          window.ShowMsg("删除成功！", "success");
          window.pageEvent.send("updateUserInfo", "");
        } else {
          window.ShowMsg("删除失败，请检查网络或稍后再试！", "success");
        }
      });
      console.log(e);
    },
    // 侧滑事件
    touchs() {
      //侧滑显示删除按钮
      var expansion = null; //是否存在展开的list
      var container = document.getElementsByName("contentBox");
      for (var i = 0; i < container.length; i++) {
        var x, y, X, Y, swipeX, swipeY;
        container[i].addEventListener("touchstart", function (event) {
          x = event.changedTouches[0].pageX;
          y = event.changedTouches[0].pageY;
          swipeX = true;
          swipeY = true;
        });
        container[i].addEventListener("touchmove", function (event) {
          X = event.changedTouches[0].pageX;
          Y = event.changedTouches[0].pageY;
          if (expansion) {
            //判断是否展开，如果展开则收起
            expansion.className = "";
          }
          // 左右滑动
          if (swipeX && Math.abs(X - x) - Math.abs(Y - y) > 0) {
            // 阻止事件冒泡
            event.stopPropagation();
            if (X - x > 20) {
              //右滑
              event.preventDefault();
              this.className = ""; //右滑收起
            }
            if (x - X > 20) {
              //左滑
              event.preventDefault();
              this.className = "swipeleft"; //左滑展开
              expansion = this;
            }
            swipeY = false;
          }
          // 上下滑动
          if (swipeY && Math.abs(X - x) - Math.abs(Y - y) < 20) {
            swipeX = false;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.userReportBox {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: #15162e;
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    .inputBox {
      position: relative;
      input {
        width: 100%;
        height: 40px;
        background: #1d2341;
        border-radius: 4px;
        outline: none;
        border: 1px solid rgba(68, 102, 142, 1);
        color: rgba(154, 161, 195, 1);
        font-size: 12px;
        text-indent: 44px;
      }
      input::placeholder {
        color: rgba(154, 161, 195, 1);
      }
      img {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 13px;
        left: 19px;
      }
    }
  }
  .main {
    background: #15162e;
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
    [name="contentBox"] {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      .infoBox {
        flex: 1;
        display: flex;
        flex-direction: column;
        .title {
          display: block;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          margin-bottom: 15px;
        }
        .dateBox {
          color: #c2cbff;
          .overdueT,
          .overdueF {
            padding: 0px 7px;
            color: #0f0240;
            border-radius: 4px;
            margin-right: 10px;
          }
          .overdueT {
            background: #c2cbff;
          }
          .overdueF {
            background: #6967a0;
          }
        }
      }
      .btnBox {
        width: 75px;
        height: 31px;
        border-radius: 4px;
        text-align: center;
        line-height: 31px;
        background: linear-gradient(49deg, #3995fc, #00cffd);
      }
      .deleteBtnBox {
        height: 31px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(49deg, #3995fc, #00cffd);
        border-radius: 4px;
        position: absolute;
        top: calc(50% - 15.5px);
        right: -75px;
      }
    }
    .swipeleft {
      transform: translate3d(-60px, 0, 0);
      .deleteBtnBox {
        top: calc(50% - 15.5px);
        right: -65px;
      }
    }
  }
}
</style>