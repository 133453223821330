<template>
  <div class="summary">
    <div class="oneTitleBox">
      <div class="oneTitle">
        <div class="icon">
          <img class="titleImg" src="../assets/icon/customers.png" />
        </div>
        区域小结
      </div>
    </div>
    <div class="brand">
      <div class="titleStyle">品牌开发商</div>
      <div id="brandBox">
        <div class="left">
          <img src="../assets/icon/peripheral/uncultivatedOne.png" />
          <div class="num">{{ this.undevLandCount }}<span> 宗</span></div>
          <div class="bottomText">未开发土地</div>
        </div>
        <div class="right">
          <img src="../assets/icon/peripheral/uncultivatedTwo.png" />
          <div class="num">{{ this.displayProjCount }}<span> 个</span></div>
          <div class="bottomText">累计呈现项目</div>
        </div>
      </div>
      <div class="explainBox" style="text-align: end; margin-right: 5px">
        数据说明：截止{{ this.brandDeveloper.endTime }}
      </div>
    </div>
    <div class="earlyForm">
      <div class="titleStyle">区域预警</div>
      <div>
        <table class="table-form">
          <thead style="background: rgba(59, 33, 131, 0.3)">
            <tr>
              <th>供销比</th>
              <th>存销比（预售）</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ regionalAlert.supplyRate }}</td>
              <td>{{ regionalAlert.stockRate }}月</td>
            </tr>
            <tr>
              <td>{{ regionalAlert.supplyRateName }}</td>
              <td>{{ regionalAlert.stockRateName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="explainBox" style="text-align: end; margin: 10px 5px 0 0">
        数据说明：截止{{ this.regionalAlert.endTime }}
      </div>
    </div>
    <div class="selling">
      <div class="titleStyle">区域畅销产品</div>
      <div class="areaCharts">
        <div id="area" style="width: 100%; height: 118.5px"></div>
      </div>
      <div class="stageCharts">
        <div id="stage" style="width: 100%; height: 118.5px"></div>
      </div>
    </div>
    <div class="saturated">
      <div class="titleStyle">商业饱和度</div>
      <div class="saturated-box">
        <img src="../assets/population/saturated.png" class="saturatedImg" />
        <div class="box-text">
          {{ this.buiSaturation.regiBsiArea }}
          <span style="font-size: 12px">㎡/人</span>
        </div>
        <div class="saturated-circle"></div>
      </div>
      <div class="saturated-text">区域人均商业面积</div>
    </div>
    <div class="volumeCharts">
      <div id="volumes" style="width: 100%; height: 234px"></div>
    </div>
    <div class="driving">
      <div class="titleStyle">价格驱动性</div>
      <div class="driveImg">
        <div class="drive-text">
          <div class="text-one">地价占比</div>
          <div class="text-two">{{ this.priceDriven.priceRate }}%</div>
        </div>
      </div>
      <div class="drive-line">
        <div class="marke"></div>
        <div class="marke2"></div>
        <div class="text texts">50%</div>
        <div class="text2 texts">80%</div>
        <div class="text3 textes">保持平稳</div>
        <div class="text4 textes">上升趋势</div>
        <div class="text5 textes">上涨压力</div>
      </div>
      <div class="explainBox" style="text-align: end; margin: 30px 5px 0 0">
        统计时间：{{ this.priceDriven.startTime }} -
        {{ this.priceDriven.endTime }}
      </div>
    </div>
    <div class="sum-download" @click="showSumDownload">
      <div class="sumload-img"></div>
      <div class="sumLoad-text">报告下载</div>
      <el-dialog
        :visible.sync="sumDownload"
        :append-to-body="true"
        :show-close="false"
        title="下载报告"
      >
        <el-input v-model="email" placeholder="请输入内容"></el-input>
        <div class="dialog-text">
          <div class="dialog-t1">
            报告下载的链接将发送至您的邮箱，请及时查收。
          </div>
          <div>链接有效期:2010-07-07</div>
        </div>
        <span slot="footer" @click="sumMit">发送</span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { color } from "echarts";
import { post } from "../config/request.js";
export default {
  data() {
    return {
      brandDeveloper: {},
      regionalAlert: {},
      areaList: [], //面积段
      totalPriceList: [], //总阶段
      areaListName: [],
      area: [
        { value: 0, itemStyle: { color: "rgba(124, 189, 61, 1)" } },
        { value: 2487, itemStyle: { color: "rgba(216, 160, 43, 1)" } },
        { value: 3205, itemStyle: { color: "rgba(225, 33, 90, 1)" } },
      ],
      totalPrice: [],
      totalPriceListName: [],
      stage: [
        { value: 0, itemStyle: { color: "rgba(124, 189, 61, 1)" } },
        { value: 2487, itemStyle: { color: "rgba(216, 160, 43, 1)" } },
        { value: 3205, itemStyle: { color: "rgba(225, 33, 90, 1)" } },
      ],
      buiSaturation: {}, //饱和度
      canDevelopBuildingArea: {}, //后期可开发体量
      houqi: [
        { value: 125, itemStyle: { color: "#2C33B3" } },
        { value: 3.8, itemStyle: { color: "#972AE3" } },
      ],
      priceDriven: {}, //价格驱动
      undevLandCount: "", //未开发土地宗数
      displayProjCount: "", //累计呈现项目个数
      sumDownload: false,
      email: "",
    };
  },
  mounted() {
    this.analysisId = window.GetAreaInfo("id");
    setTimeout((e) => {
      document.getElementById("summaryBox").style.height = "auto";
    }, 500);
    var that = this;
    post("/content/regional_summary", { analysisId: that.analysisId }).then(
      (res) => {
        console.log(res);
        if (res.code == 0) {
          that.brandDeveloper = res.data.brandDeveloper;
          that.undevLandCount = res.data.brandDeveloper.undevLandCount.count;
          that.displayProjCount =
          res.data.brandDeveloper.displayProjCount.count;
          that.regionalAlert = res.data.regionalAlert;
          window.supplyRateList.map((i) => {
            if (
              that.regionalAlert.supplyRate >= i.min &&
              that.regionalAlert.supplyRate < i.max
            )
              that.regionalAlert["supplyRateName"] = i.name;
          });
          window.stockRateList.map((i) => {
            if (
              that.regionalAlert.stockRate >= i.min &&
              that.regionalAlert.stockRate < i.max
            )
              that.regionalAlert["stockRateName"] = i.name;
          });
          //面积段
          that.areaListName = res.data.areaBestSeller.areaList
            .reverse()
            .map((i) => i.name);
          that.areaList = res.data.areaBestSeller.areaList;
          for (var i = 0; i < that.areaList.length; i++) {
            if (that.areaList[i].saledCount === null) {
              that.areaList[i].saledCount = 0;
            }
            that.area[i].value = that.areaList[i].saledCount;
          }
          //总阶段
          that.totalPriceListName = res.data.areaBestSeller.totalPriceList
            .reverse()
            .map((i) => i.name);
          that.totalPrice = res.data.areaBestSeller.totalPriceList;
          for (var i = 0; i < that.totalPrice.length; i++) {
            if (that.totalPrice[i].saledCount === null) {
              that.totalPrice[i].saledCount = 0;
            }
            that.stage[i].value = that.totalPrice[i].saledCount;
          }
          that.buiSaturation = res.data.buiSaturation; //饱和度
          (that.canDevelopBuildingArea = res.data.canDevelopBuildingArea), //后期可开发体量
            (that.houqi[0].value = that.canDevelopBuildingArea.leftArea);
          that.houqi[1].value = that.canDevelopBuildingArea.canDevBuildingArea;
          that.priceDriven = res.data.priceDriven;
          this.getAreaCharts();
          this.getStageCharts();
          this.getVolumeCharts();
        } else {
          return (res.data = undefined);
        }
      }
    );
  },
  methods: {
    //获取面积段top3图表
    getAreaCharts() {
      var that = this;
      var chartDom = document.getElementById("area");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: "面积段Top3",
          textStyle: {
            //文字颜色
            color: "#fff",
            //字体大小
            fontSize: 12,
          },
          x: "40%",
          y: "10%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "31%",
          left: "0%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: that.areaListName,
          axisLine: {
            lineStyle: {
              color: "#3B2183", //轴颜色
            },
          },
          axisLabel: {
            color: "#423C9B",
          },
        },
        series: [
          {
            name: "2011年",
            type: "bar",
            data: that.area,
            label: {
              show: true,
              position: "right", //柱子上方文字
              color: "rgba(194, 203, 255, 1)",
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
    //获取总阶段top3图表
    getStageCharts() {
      var that = this;
      var chartDom = document.getElementById("stage");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: "总阶段Top3",
          textStyle: {
            //文字颜色
            color: "#fff",
            //字体大小
            fontSize: 12,
          },
          x: "40%",
          y: "10%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "31%",
          left: "0%",
          right: "4%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: that.totalPriceListName,
          axisLine: {
            lineStyle: {
              color: "#3B2183", //轴颜色
            },
          },
          axisLabel: {
            color: "#423C9B",
          },
        },
        series: [
          {
            name: "2011年",
            type: "bar",
            data: that.stage,
            label: {
              show: true,
              position: "right", //柱子上方文字
              color: "rgba(194, 203, 255, 1)",
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
    //获取后期氪开发体量图表
    getVolumeCharts() {
      var that = this;
      var chartDom = document.getElementById("volumes");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: "区域后期可开发体量",
          textStyle: {
            //文字颜色
            color: "#fff",
            //字体大小
            fontSize: 12,
          },
          x: "3%",
          y: "5%",
        },
        grid: {
          bottom: "20%",
          containLable: true,
        },
        xAxis: {
          type: "category",
          data: ["预售存量(万㎡)", "可开发量(万㎡)"],
          axisLine: {
            lineStyle: {
              color: "#3B2183",
            },
          },
          axisLabel: {
            color: "#423C9B", //X轴上字体颜色
          },
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            data: that.houqi,
            type: "bar",
            barWidth: "50",
            label: {
              show: true,
              position: "top", //柱子上方文字
              color: "rgba(194, 203, 255, 1)",
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
    showSumDownload() {
      this.sumDownload = true;
    },
    sumMit() {
      if (this.email == "") {
        window.ShowMsg("请输入正确的邮箱地址！");
        return;
      }
      var emailPage =
        /^[a-z0-9](\w|\.|-)*@([a-z0-9]+-?[a-z0-9]+\.){1,3}[a-z]{2,4}$/i;
      if (!emailPage.test(this.email))
        return window.ShowMsg("邮箱格式错误，请重新输入！");
      window.ShowMsg("发送成功，请前往邮箱查看！", "success");
      this.sumDownload = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #0f0240;
  .oneTitleBox {
    width: 100%;
    height: 80px;
    .oneTitle {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      background-color: #0f0240;
      padding-left: 20px;
      color: #fff;
      font-size: 22px;
      .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: linear-gradient(180deg, #7920da, #0d78fd);
        margin-right: 20px;
        .titleImg {
          margin-left: -15px;
          margin-top: -15px;
        }
      }
    }
  }
}
.brand {
  height: 214px;
  display: flex;
  flex-direction: column;
  margin: 0 15px 15px 15px;
  border-radius: 4px;
  background: rgba(61, 49, 148, 0.4);
  padding: 15px;
  #brandBox {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid rgba(59, 33, 131, 1);
    margin-bottom: 35px;
    .left,
    .right {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        font-size: 12px;
        color: #ffffff;
      }
      .num {
        font-size: 18px;
        color: #fff;
        position: absolute;
        span {
          font-size: 12px;
        }
      }
      .bottomText {
        position: absolute;
      }
    }
    .left {
      width: 47%;
      height: 54px;
      margin-bottom: -2px;
      .num {
        top: 10px;
        left: 37%;
      }
      img {
        width: 100%;
        height: 64px;
      }
      .bottomText {
        bottom: -18px;
      }
    }
    .right {
      width: 66%;
      height: 100px;
      margin-left: -35px;
      margin-bottom: -22px;
      img {
        width: 100%;
        height: 90px;
      }
      .num {
        top: 25px;
        left: 39%;
      }
      .bottomText {
        bottom: 2px;
      }
    }
  }
}
.titleStyle {
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
}
.earlyForm {
  height: 205px;
  display: flex;
  flex-direction: column;
  margin: 0 15px 15px 15px;
  border-radius: 4px;
  background: rgba(61, 49, 148, 0.4);
  padding: 15px;
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
    th,
    td {
      border: 2px solid #3b2183;
      text-align: center;
      color: #ffffff;
      font-size: 15px;
      height: 40px;
      width: 50%;
      position: relative;
      .sanJia {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 15px;
        margin-right: 17px;
        position: absolute;
        top: 14px;
      }
    }
    .th-head {
      display: flex;
      padding-left: 42px;
    }
    .tdBox {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.selling {
  height: 286px;
  display: flex;
  flex-direction: column;
  margin: 0 15px 15px 15px;
  border-radius: 4px;
  background: rgba(61, 49, 148, 0.4);
  padding: 15px;
  .areaCharts {
    width: 100%;
    height: 118.5px;
  }
  .stageCharts {
    width: 100%;
    height: 118.5px;
  }
}
.saturated {
  height: 234px;
  margin: 0 15px 15px 15px;
  border-radius: 4px;
  background: rgba(61, 49, 148, 0.4);
  padding: 15px;
  .saturated-circle {
    width: 264px;
    height: 42px;
    background: #280c44;
    border-radius: 50%;
    margin: auto;
  }
  .saturatedImg {
    width: 214px;
    height: 150px;
    position: absolute;
    left: 50%;
    top: -65%;
    transform: translate(-50%, -65%);
  }
  .saturated-box {
    margin-top: 127px;
    position: relative;
    .box-text {
      width: 100%;
      position: absolute;
      top: -91px;
      left: 0%;
      text-align: center;
      font-size: 18px;
      color: #2a76e0;
    }
  }
  .saturated-text {
    font-size: 12px;
    color: #c2cbff;
    text-align: center;
    margin-top: 5px;
  }
}
.volumeCharts {
  height: 234px;
  margin: 0 15px 15px 15px;
  border-radius: 4px;
  background: rgba(61, 49, 148, 0.4);
}
.driving {
  height: 332px;
  margin: 0 15px 15px 15px;
  border-radius: 4px;
  background: rgba(61, 49, 148, 0.4);
  padding: 15px;
  .driveImg {
    width: 161px;
    height: 169px;
    background-image: url("../assets/population/drive.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
    margin-top: 20px;
    position: relative;
    .drive-text {
      width: 100px;
      text-align: center;
      position: absolute;
      left: 19%;
      top: 17%;
      .text-one {
        font-size: 11px;
        color: #423c9b;
      }
      .text-two {
        font-size: 21px;
        color: #ffffff;
      }
    }
  }
  .drive-line {
    height: 1.5px;
    margin-top: 50px;
    background: linear-gradient(75deg, #096dfd, #7519fc, #bd31a1);
    position: relative;
    .marke {
      width: 2px;
      height: 6px;
      background: #2947f4;
      position: absolute;
      top: -6px;
      left: 29%;
    }
    .marke2 {
      width: 2px;
      height: 6px;
      background: #7519fc;
      position: absolute;
      top: -6px;
      right: 30%;
    }
    .texts {
      font-size: 15px;
      color: #ffffff;
      position: absolute;
    }
    .text {
      top: -26px;
      left: 26%;
    }
    .text2 {
      top: -26px;
      right: 24%;
    }
    .textes {
      font-size: 12px;
      position: absolute;
    }
    .text3 {
      color: #086dfd;
      top: 10px;
      left: 6%;
    }
    .text4 {
      color: #7519fc;
      top: 10px;
      right: 44%;
    }
    .text5 {
      color: #c33499;
      top: 10px;
      right: 7%;
    }
  }
}
.sum-download {
  width: 225px;
  height: 56px;
  background: linear-gradient(49deg, #3995fc, #00cffd);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 23px;
  .sumload-img {
    width: 21px;
    height: 21px;
    background-image: url("../assets/document/download.png");
    background-size: cover;
    margin-right: 15px;
  }
  .sumLoad-text {
    font-size: 18px;
    color: #ffffff;
  }
}
</style>