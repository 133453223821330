<template>
  <div class="loginBox">
    <div class="logoBox">
      <img src="../assets/icon/logoLogin.png" />
    </div>
    <div class="inputBox">
      <div class="phoneBox">
        <input
          class="phone"
          type="tel"
          placeholder=" 输入手机号"
          maxlength="11"
          v-model.trim="phone"
        />
      </div>
      <div class="verificationCode">
        <input
          type="tel"
          placeholder="输入验证码"
          maxlength="6"
          v-model.trim="codeNum"
        />
        <button @click="getCode" :disabled="codeDisabled">
          {{ codeText }}
        </button>
      </div>
      <button
        class="loginBtn loginBtnBg"
        v-show="!loginDisabled"
        @click="login"
      >
        登录
      </button>
      <button class="loginBtn loginBtnDisabledBg" v-show="loginDisabled">
        登录中...
      </button>
    </div>
    <div class="agreementBox" style="display: none">
      <input type="checkbox" v-model="checked" />
      <div>
        登录既代表同意本<span>《用户协议》</span>和<span>《隐私协议》</span>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "../config/request";
export default {
  data() {
    return {
      codeText: "获取验证码",
      phone: "", //用户输入的手机号
      codeDisabled: false,
      loginDisabled: false,
      codeNum: "", //用户输入的验证码
      phonePage: /^1\d{10}$/, //手机号正则表达式
      codePage: /^\d{6}$/, //验证码正则表达式
      checked: true,
    };
  },
  created() {
    document.title = "登录";
    //判断是否登陆，如果已经登陆直接跳转到首页
    if (window.GetUserInfo()) {
      window.RedirectTo("home");
    }
  },
  methods: {
    //获取验证码
    getCode() {
      if (this.codeDisabled) return;
      if (!this.phonePage.test(this.phone))
        return window.ShowMsg("请输入正确的手机号！");
      var count = 30;
      this.codeDisabled = true;
      this.codeText = count + "S后重新获取";
      var ret = setInterval(() => {
        --count;
        if (count == 0) {
          this.codeText = "重新获取";
          this.codeDisabled = false;
          clearInterval(ret);
        } else {
          this.codeText = count + "s后重新获取";
          this.codeDisabled = true;
        }
      }, 1000);
      post("account/login/send_code", { mobile: this.phone }).then((res) => {
        this.codeDisabled = false;
      });
    },
    // 登录
    login() {
      if (!this.phonePage.test(this.phone))
        return window.ShowMsg("请输入正确的手机号！");
      if (!this.codePage.test(this.codeNum))
        return window.ShowMsg("请输入正确的验证码！");
      if (!this.checked) return window.ShowMsg("请阅读并同意用户协议！");
      if (this.loginDisabled) return;
      this.loginDisabled = true;
      post("account/login/mobile", {
        mobile: this.phone,
        code: this.codeNum,
      }).then((res) => {
        if (res.code > 0) {
          this.loginDisabled = false;
          return window.ShowMsg(res.msg);
        } else {
          window.SetUserInfo(res.data);
          window.RedirectTo("home");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.loginBox {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #0f0240;
  align-items: center;
  padding: 15px;
  .logoBox {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: linear-gradient(
      30deg,
      rgba(19, 21, 81, 1),
      rgba(19, 21, 81, 0)
    );
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    img {
      margin-top: 90px;
    }
  }
  .inputBox {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    flex: 1;
    .phoneBox {
      .phone {
        width: 100%;
        height: 50px;
        border-radius: 6px;
        background-color: rgba(242, 242, 242, 0.2);
        outline: none;
        border: 0px;
        color: #d4d4d4;
        font-size: 14px;
        padding: 0 15px;
      }
    }
    .verificationCode {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      input,
      button {
        outline: none;
        height: 50px;
        border: 0;
        border-radius: 6px;
        font-size: 14px;
      }
      input {
        background-color: rgba(242, 242, 242, 0.2);
        flex: 1;
        min-width: 100px;
        padding: 0 15px;
        color: #d4d4d4;
      }
      button {
        background: rgba(0, 247, 254, .7);
        margin-left: 10px;
        color: #fff;
        padding: 0 10px;
        white-space: nowrap;
      }
    }
    .loginBtn {
      width: 100%;
      height: 55px;
      border: 0;
      outline: none;
      color: #fff;
      font-size: 18px;
      margin-top: 70px;
      border-radius: 6px;
    }
    .loginBtnBg {
      background: linear-gradient(141deg, #30abff, #00f7fe);
    }
    .loginBtnDisabledBg {
      background: #cccccc;
    }
  }
  .agreementBox {
    display: flex;
    color: rgba(171, 171, 171, 1);
    font-size: 12px;
    align-items: center;
    margin-top: 20px;
    input {
      width: 12px;
      height: 12px;
      margin-right: 10px;
      border: 1px solid rgba(171, 171, 171, 1);
      background: rgba(255, 255, 255, 0);
    }
    div {
      span {
        color: rgba(81, 81, 81, 1);
      }
    }
  }
}
</style>
