<template>
  <div class="users-vip">
    <div class="interface">
      <div class="user-vip">
        <div class="consumerBox">
          <div class="avatar">
            <div class="avatar-img">
              <img
                src="../assets/user/avatar.png"
                style="width: 100%; height: 100%"
              />
              <div class="crown" v-if="isVip == true">
                <img
                  src="../assets/user/crown.png"
                  style="width: 100%; height: 100%"
                />
              </div>
            </div>
          </div>
          <div>
            <div class="uname">您好，{{ this.userinfo.userName }}</div>
            <div :class="[isVip == true ? 'vip':'normal']" ></div>
          </div>
        </div>
        <div class="arrowIcon" @click="modify">
          <img
            src="../assets/user/arrow.png"
            style="width: 8px; height: 14px"
          />
        </div>
      </div>
      <div class="myBox">
        <div class="panel">
          <div class="item" @click="toAnalysis">
            <div class="item-text">{{ this.userinfo.analysisCount }}</div>
            <div class="item-name">分析历史</div>
          </div>
          <div class="item" @click="toReport">
            <div class="item-text">{{ this.userinfo.analysisDocCount }}</div>
            <div class="item-name">我的报告</div>
          </div>
          <div class="item" @click="toCollection">
            <div class="item-text">{{ this.userinfo.likedCount }}</div>
            <div class="item-name">我的收藏</div>
          </div>
        </div>
      </div>
      <div class="vipBox">
        <div class="member">
          <div class="member-text">VIP会员 .尊享</div>
          <div>
            <div class="icon-box">
              <div class="member-icon">
                <img
                  src="../assets/user/gou.png"
                  style="width: 100%; height: 100%"
                />
              </div>
              <div class="icon-text">详尽数据分析</div>
            </div>
            <div class="icon-box">
              <div class="member-icon">
                <img
                  src="../assets/user/gou.png"
                  style="width: 100%; height: 100%"
                />
              </div>
              <div class="icon-text">专属报告定制</div>
            </div>
          </div>
        </div>
        <div class="timeBox">
          <div class="vipTime">{{ this.isVip == true ? this.vipOverTime + "到期": "" }}</div>
          <div class="dredge" @click="toVipCenter">{{this.isVip == true ? "我要续费":"我要开通"}}</div>
        </div>
      </div>
      <div class="userList">
        <div class="recharge" @click="toRecord">
          <div class="listBox">
            <div class="list-img">
              <img
                src="../assets/user/money.png"
                style="width: 100%; height: 100%"
              />
            </div>
            <div class="list-text">充值记录</div>
          </div>
          <div class="list-icon">
            <img
              src="../assets/user/arrow.png"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
        <div class="recharge" @click="toagreement">
          <div class="listBox">
            <div class="list-img">
              <img
                src="../assets/user/agreement.png"
                style="width: 100%; height: 100%"
              />
            </div>
            <div class="list-text">用户协议</div>
          </div>
          <div class="list-icon">
            <img
              src="../assets/user/arrow.png"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
        <div class="recharge" @click="toprivacy">
          <div class="listBox">
            <div class="list-img">
              <img
                src="../assets/user/privacy.png"
                style="width: 100%; height: 100%"
              />
            </div>
            <div class="list-text">隐私政策</div>
          </div>
          <div class="list-icon">
            <img
              src="../assets/user/arrow.png"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
        <div class="recharge" @click="toUS">
          <div class="listBox">
            <div class="list-img">
              <img
                src="../assets/user/about.png"
                style="width: 100%; height: 100%"
              />
            </div>
            <div class="list-text">关于我们</div>
          </div>
          <div class="list-icon">
            <img
              src="../assets/user/arrow.png"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
        <div class="recharge">
          <div class="listBox">
            <div class="list-img">
              <img
                src="../assets/user/contact.png"
                style="width: 100%; height: 100%"
              />
            </div>
            <div class="list-text">联系我们</div>
          </div>
          <div class="list-icon">
            <img
              src="../assets/user/arrow.png"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="tabkong"></div>
    <tabbar></tabbar>
  </div>
</template>

<script>
import Tabbar from "../components/tabbar.vue";
export default {
  components:{
    Tabbar
  },
  data() {
    return {
      userinfo: {}, //用户信息
      vipOverTime: "",
      isVip: "", //判断是否为vip
    };
  },
  mounted() {
    var that = this;
    that.userinfo = window.GetUserInfo();
    that.isVip = window.IsVIP();
    console.log(that.userinfo);
    this.getVipTime(that.userinfo.vipOverTime);
    window.pageEvent.on("updateUserInfo", (data) => {
      that.userinfo = window.GetUserInfo();
      that.isVip = window.IsVIP();
    });
  },
  methods: {
    //vip到期时间转换
    getVipTime(vt) {
      var vtime = vt * 1000;
      var date = new Date(vtime); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

      let strDate = Y + M + D;
      // console.log(strDate); //2020-05-08 17:44:56
      this.vipOverTime = strDate;
    },
    modify() {
        this.$router.push("/userinfo");
      //window.NavigateTo("userinfo");
    },
    toprivacy() {
        this.$router.push("/privacy");
      //window.NavigateTo("privacy");
    },
    toagreement() {
        this.$router.push("/agreement");
      //window.NavigateTo("agreement");
    },
    toAnalysis() {
        this.$router.push("/userAnalysis");
      //window.NavigateTo("userAnalysis");
    },
    toReport() {
        this.$router.push("/userReport");
      //window.NavigateTo("userReport");
    },
    toCollection() {
        this.$router.push("/userCollection");
      //window.NavigateTo("userCollection");
    },
    toUS() {
        this.$router.push("/aboutus");
      //window.NavigateTo("aboutus");
    },
    toVipCenter(){
      this.$router.push("/vipcenter");
      //window.NavigateTo("vipcenter");
    },
    toRecord(){
      this.$router.push("/record");
      //window.NavigateTo("record");
    }
  },
};
</script>

<style lang="scss" scoped>
.users-vip {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #0f0240;
  align-items: center;
  padding: 0 15px;
  .interface {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #0f0240;
    align-items: center;
  }
  .user-vip {
    width: 100%;
    height: 53px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    .consumerBox {
      display: flex;
      margin-left: 18px;
      .avatar {
        display: flex;
        align-items: center;
        margin-right: 14px;
        .avatar-img {
          width: 53px;
          height: 53px;
          position: relative;
        }
      }
      .crown {
        width: 20px;
        height: 19px;
        position: absolute;
        top: -13px;
        right: 2px;
      }
      .uname {
        font-size: 18px;
        color: #c2cbff;
        margin: 5px 0 6px 0;
      }
      .vip {
        width: 39px;
        height: 13px;
        background-image: url("../assets/user/VIP.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .normal {
        width: 52px;
        height: 13px;
        background-image: url("../assets/user/novip.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .arrowIcon {
      width: 20px;
      height: 100%;
      margin-right: 7px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
    }
  }
  .myBox {
    width: 100%;
    height: 155px;
    background: #1a255a;
    opacity: 0.67;
    border-radius: 3px;
    margin-top: 31px;
    .panel {
      display: flex;
      margin-top: 30px;
      justify-content: space-around;
      .item {
        width: 52px;
        text-align: center;
        .item-text {
          font-size: 21px;
          color: #c2cbff;
        }
        .item-name {
          font-size: 11px;
          color: #5e6493;
        }
      }
    }
  }
  .vipBox {
    width: 85%;
    height: 100px;
    background: url("../assets/user/vipback.png") no-repeat bottom center;
    border-radius: 6px;
    background-size: cover;
    position: absolute;
    top: 240px;
    display: flex;
    justify-content: space-between;
    .member {
      margin-left: 18px;
      .member-text {
        font-size: 12px;
        color: #18171f;
        font-weight: bold;
        margin-top: 12px;
        margin-bottom: 20px;
      }
      .icon-box {
        display: flex;
        margin-bottom: 8px;
        .member-icon {
          width: 12px;
          height: 12px;
          margin-right: 7px;
        }
        .icon-text {
          font-size: 10px;
          color: #2b2a37;
        }
      }
    }
    .timeBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 19px;
      .vipTime {
        min-height: 16px;
        font-size: 9px;
        color: #2b2a37;
        margin-top: 12px;
        margin-bottom: 40px;
      }
      .dredge {
        width: 75px;
        height: 24px;
        background: #2b2a37;
        border-radius: 24px;
        text-align: center;
        color: #efba19;
        font-size: 12px;
        line-height: 24px;
        align-self: flex-end;
      }
    }
  }
  .userList {
    width: 100%;
    background: #1a255a;
    margin-top: 55px;
    padding-top: 30px;
    margin-bottom: 58px;
    .recharge {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .listBox {
        display: flex;
        align-items: center;
        margin-left: 15px;
        .list-img {
          width: 15px;
          height: 15px;
          margin-right: 9px;
        }
        .list-text {
          font-size: 14px;
          color: #c2cbff;
        }
      }
    }
    .list-icon {
      width: 4px;
      height: 7px;
      margin-right: 21px;
    }
  }
}
.tabkong {
  width: 100%;
  height: 1px;
}
</style>