<template>
  <div class="aboutus">
    <div class="aboutus-text">
      <div class="textA">
        <p>
          <span style="font-size: 12px"
            >锐理慧眼，由成都锐理数据处理股份有限公司开发，秉持着“推动房地产事业发展”的理念，始终致力于实现打造以“大数据＋区块链＋新平台
            ”的全新生态平台。公司于2006
            年正式创立，公司业务全面覆盖大数据、新房、二手房、租房、商业地产、海外地产等领域。</span
          >
        </p>
        <p>
          ​<br /><span style="font-size: 12px"
            >锐理慧眼，结合房产、GIS、人口等多维度数据，通过房产相关算法对这些数据进行整合分析，高效快速地让用户了解关注地点周边市场情况，并通过这些信息进行快速决策。</span
          ><br />​<br />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    document.title = "关于我们";
  },
};
</script>

<style lang="scss" scoped>
.aboutus {
  width: 100%;
  height: 100vh;
  background: #0f0240;
  display: flex;
  flex-direction: column;
  padding: 60px 15px 24px 15px;
  .aboutus-text {
    height: 100vh;
    padding: 25px 10px 0 10px;
    background: rgba(23, 69, 115, 0.36);
  }
}

.textA {
  color: #c2cbff;
}
</style>