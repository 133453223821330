<template>
  <div class="containers">
    <div class="threeTitle">
      <div class="titleL"></div>
      办公市场
      <div class="titleR"></div>
    </div>
    <div class="distribution">重点写字楼分布</div>
    <div class="officeMap">
      <div id="office" style="width: 100vw; height: 297px"></div>
    </div>
    <div class="office-body">
      <div class="investments">
        <div class="title">办公投资回报率</div>
        <div class="investment-box">
          <div class="text">
            <div class="text1">区域平均挂牌月租金</div>
            <div>{{this.rentPrice}} 元/㎡</div>
          </div>
          <div class="img">
            <img src="../assets/office/zu.png" class="png" />
          </div>
        </div>
        <div
          class="investment-box"
          style="
            background: linear-gradient(-62deg,rgba(96, 28, 129, 0.61),rgba(188, 66, 122, 0.61));"
        >
          <div class="text">
            <div class="text1">区域平均挂牌售价</div>
            <div>{{this.sellPrice}} 元/㎡</div>
          </div>
          <div class="img">
            <img src="../assets/office/shou.png" class="png" />
          </div>
        </div>
        <div
          class="investment-box"
          style="
            background: linear-gradient(-62deg,rgba(79, 21, 120, 0.61),rgba(117, 42, 180, 0.61));"
        >
          <div class="text">
            <div class="text1">区域平均投资年回报率(静态)</div>
            <div>{{this.roi>0?this.roi:0}} %</div>
          </div>
          <div class="img">
            <img src="../assets/office/huibao.png" class="png" />
          </div>
        </div>
        <div class="text-bottom">统计时间：{{this.sumData}}</div>
      </div>
      <div class="attractive">
        <div id="salary" style="width:100%;height:254px;"></div>
      </div>
      <div class="meter">
        <div id="Dashboard" style="width:100%;height:215px;"></div>
        <div class="meter-text">区域薪资吸引力指数 = 区域平均薪资水平 / 全市平均工资水平</div>
      </div>
    </div>
  </div>
</template>

<script>
import { EyesMap } from "../config/showMap.js"; // 引入地图
import * as echarts from "echarts"; // 引入echarts
import { post } from "../config/request";
export default {
  data() {
    return {
      analysisId:"1",
      roi:"",//投资回报率
      rentPrice:"",//租金价格
      sellPrice:"",//售价
      sumData:"",//统计时间
      roiInfo:{},
      chartData:[],//矩形树状图数据
      index:"",//薪资指数
    };
  },
  mounted() {
    this.analysisId = window.GetAreaInfo("id");
    setTimeout((e) => {
      document.getElementById("officeMarketBox").style.height = "auto";
    }, 500);
    var that = this;
    post('/content/office_market',{"analysisId":that.analysisId}).then(res => {
      console.log(res);
      that.roiInfo = res.data;
      that.roi = (res.data.roiInfo.roi * 100).toFixed(2);
      that.rentPrice = that.isRoiInfo(res.data.roiInfo,1);//1代表挂牌月组件，2代表挂牌售价;3代表统计时间;4代表投资回报率
      that.sellPrice = that.isRoiInfo(res.data.roiInfo,2);
      that.sumData = that.isRoiInfo(res.data.roiInfo,3);
      //处理矩形树状图百分比数据
      var rate = 0;
      for(var i=0;i<res.data.comStatistics.length;i++){
        rate = rate + res.data.comStatistics[i].count;
      }
      //处理矩形树状图数据
      that.chartData = res.data.comStatistics.map((item) =>{
        return{
          name:item.name,
          value:item.count,
          rate:rate
        }
      })
      console.log(that.chartData);
      //区域薪资指数
      that.index = res.data.salaryIndex * 100;
      this.initMap(res.data.officeList);
      that.getSalaryChart();
      that.getMeterChart();
    })
  },
  methods: {
    initMap(data) {
      var that = this;
      var areaInfo = window.GetAreaInfo();
      EyesMap.InitMap("office",(map)=>{
        EyesMap.ShowArea(map,areaInfo);
        for(var i=0;i<data.length;i++){
          data[i]["geom"] = data[i]["point"];
        };
        EyesMap.ShowLabel(map,data);
      });
    },
    //获取区域薪资吸引力指数矩形树状图
    getSalaryChart() {
      var that = this;
      var chartDom = document.getElementById("salary");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: "区域入住企业的类型",
          y:10,
          x:10,
          textStyle:{
            color: "#FEFEFF",
            fontSize:14,
          }
        },
        tooltip:{
          formatter:function(params){
            return params.name +' ' + '占比' + Math.round((params.value/params.data.rate)*1000)/10 +'%';
          }
        },
        series: [
          {
            type: "treemap",
            roam:false,
            nodeClick:false,
            breadcrumb:'false',//取消图表下的黑模块
            top:'17%',
            left:'14',
            right:'14',
            bottom:'15',
            data: that.chartData,
          },
        ],
      };
      option && myChart.setOption(option);
    },
    //仪表盘数据图
    getMeterChart(){
      var that = this;
      var chartDom = document.getElementById('Dashboard');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
          tooltip: {
              formatter: '{a} <br/>{b} : {c}%'
          },
          title:{
            text:"区域薪资吸引力指数",
            y:10,
            x:10,
            textStyle:{
              color: "#FEFEFF",
              fontSize:14,
           }
          },
          series: [{
              name: 'Pressure',
              type: 'gauge',
              startAngle: 200,
              endAngle: -21,
              center: ['50%', '65%'],
              progress: {
                  show: true,
                  width: 18
              },
              axisTick:{
                show:false  
              },
              axisLine: {
                  // show:false,
                  lineStyle: {
                      width: 18,
                  }
              },
              progress:{
                show:true,
                width: 18,
                itemStyle:{
                    color:'#1F36B3',
                    borderWidth:1,
                },
              },
              axisLabel:{
                  show:false,
              },
              splitLine:{
                show:false,  
              },
              pointer:{
                  show:false,
              },
              detail: {
                  formatter: '{value}',
                  color:'#FEFEFF',
                  fontSize:24,
                  offsetCenter: [0, '-10%'],
              },
              data: [{
                  value: that.index,
                  name: '吸引力指数',
              }],
              //仪表盘标题颜色
              title:{
                color:'#423C9B',
                fontSize:14,
              }
          }]
      };

      option && myChart.setOption(option);

    },
    isRoiInfo(data,info){
      if(info == 1){
        if(data.rent.avgPrice != null){
          return data.rent.avgPrice
        }else{
          return 0
        }
      }else if(info == 2){
        if(data.sell.avgPrice != null){
          return data.sell.avgPrice
        }else{
          return 0
        }
      }else if(info == 3){
        if(data.sell.sumDate != null){
          return data.sell.sumDate
        }else{
          return 0
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.containers {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #0f0240;
  .mapBox {
    width: 100%;
    height: 322px;
  }
}
.threeTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 18px;
  margin: 21px 0;
  padding: 0 25px;
  .titleL,
  .titleR {
    flex: 1;
    height: 3px;
  }
  .titleL {
    background: linear-gradient(
      90deg,
      rgba(110, 98, 228, 0),
      rgba(110, 98, 228, 1)
    );
    margin-right: 10px;
  }
  .titleR {
    background: linear-gradient(
      90deg,
      rgba(110, 98, 228, 1),
      rgba(110, 98, 228, 0)
    );
    margin-left: 10px;
  }
}
.distribution {
  font-size: 14px;
  color: #fefeff;
  font-weight: bold;
  margin-left: 30px;
  margin-bottom: 11px;
}
.officeMap {
  width: 100%;
  height: 297px;
}
.office-body {
  width: 100%;
  padding: 19px 15px 0 15px;
}
.investments {
  width: 100%;
  height: 317px;
  background: rgba(61, 49, 148, 0.3);
  padding: 20px 14px 0 14px;
  .title {
    font-size: 14px;
    color: #fefeff;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .investment-box {
    width: 100%;
    height: 67.5px;
    background: linear-gradient(
      -62deg,
      rgba(5, 65, 139, 0.61),
      rgba(0, 184, 184, 0.61)
    );
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .text {
    color: #ffffff;
    font-size: 15px;
    margin-left: 12px;
    .text1 {
      margin-bottom: 10px;
    }
  }
  .img {
    width: 42px;
    height: 41px;
    margin-right: 23px;
    .png {
      width: 100%;
      height: 100%;
    }
  }
  .text-bottom {
    color: #423c9b;
    font-size: 12px;
    text-align: end;
    margin-top: 18px;
  }
}
.attractive {
  width: 100%;
  height: 254px;
  background: rgba(61, 49, 148, 0.3);
  margin-top: 15px;
}
.meter {
  width: 100%;
  background: rgba(61, 49, 148, 0.3);
  margin-top: 15px;
  margin-bottom: 25px;
  padding-bottom: 13px;
  .meter-text{
    font-size: 12px;
    color: #423C9B;
    text-align: center;
  }
}
</style>

