<template>
  <div class="agreement">
    <div class="agreement-text">
      <div class="textA">
        <div style="text-align: center">
          <span style="font-size: 18px">锐理慧眼用户协议</span>
        </div>
        <br />
        <span style="font-size: 16px">总则</span>
        <br />
        <br />
        <span style="font-size: 12px"
          >锐理慧眼的所有权和运营权归锐理数据处理技术股份有限公司所有。</span
        >
        <br />
        <br />
        <span style="font-size: 12px"
          >本《用户协议》（以下简称“本协议”）是您与锐理慧眼产品（以下简称&quot;“锐理慧眼”&quot;）所有者（以下简称为“锐理”）之间就“锐理慧眼”服务等相关事宜所订立的契约，请您仔细阅读本注册协议，并同意遵守本协议后方可成为注册用户，您勾选中“我已阅读并同意锐理慧眼用户协议”后，本协议即构成对双方有约束力的法律文件。</span
        >
        <br />
        <br />
        <span style="font-size: 16px">“锐理慧眼”服务条款的确认和接纳</span>
        <br />
        <br />1、“锐理慧眼”的各项电子服务的所有权和运作权归锐理所有。用户同意所有注册协议条款并完成注册程序，才能成为“锐理慧眼”的正式用户。用户确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。
        <br />
        <br />2、用户点击同意本协议的，即视为用户确认自己能够独立承担法律责任。
        <br />
        <br />3、如果您在18周岁以下，您只能在父母或监护人的监护参与下才能使用“锐理慧眼”。
        <br />
        <br />4、锐理保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。
        <br />
        <br />
        <span style="font-size: 16px">“锐理慧眼”服务</span>
        <br />
        <br />1、锐理通过互联网依法为用户提供互联网信息等服务，用户在完全同意本协议及“锐理慧眼”规定的情况下，方有权使用“锐理慧眼”的相关服务。
        <br />
        <br />2、用户必须自行准备如下设备和承担如下开支：（1）上网设备，包括并不限于手机或者其他上网终端、调制解调器及其他必备的上网装置；（2）上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。
        <br />
        <br />
        <span style="font-size: 16px">用户信息</span>
        <br />
        <br />1、用户应自行诚信向“锐理慧眼”提供注册资料，用户同意其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且锐理保留终止用户使用“锐理慧眼”各项服务的权利。
        <br />
        <br />2、用户在“锐理慧眼”进行浏览、查询、搜索等活动时，涉及用户真实姓名/名称、联系电话、电子邮箱等隐私信息的，“锐理慧眼”将予以严格保密，除非得到用户的授权或法律另有规定，“锐理慧眼”不会向外界披露用户隐私信息。
        <br />
        <br />3、用户注册成功后，将产生用户名等账户信息。用户应谨慎合理的保存、使用其用户名。用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知“锐理慧眼”并向公安机关报案。
        <br />
        <br />4、用户同意，锐理拥有通过邮件、短信电话等形式，向在“锐理慧眼”注册用户，推送“锐理慧眼”相关信息的权利。
        <br />
        <br />5、用户不得将在“锐理慧眼”注册获得的账户借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。
        <br />
        <br />6、用户同意，锐理有权使用用户的注册信息、用户名等信息，登录进入用户的注册账户，进行证据保全，包括但不限于公证、见证等
        <br />
        <br />
        <span style="font-size: 16px">用户依法言行义务</span>
        <br />
        <br />本协议依据国家相关法律法规规章制定，用户同意严格遵守以下义务：
        <br />
        <br />（1）不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；
        <br />
        <br />（2）从中国大陆向境外传输资料信息时必须符合中国有关法规；
        <br />
        <br />（3）不得利用“锐理慧眼”从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；
        <br />
        <br />（4）不得干扰“锐理慧眼”的正常运转，不得侵入“锐理慧眼”及国家计算机信息系统；
        <br />
        <br />（5）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；
        <br />
        <br />（6）不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；
        <br />
        <br />（7）不得教唆他人从事本条所禁止的行为；
        <br />
        <br />（8）不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容；
        <br />
        <br />
        <span style="font-size: 16px">用户跟帖评论规则</span>
        <br />
        <br />1、用户注册后，可以以注册帐号登录“锐理慧眼”并对所阅读的内容发表评论。
        <br />
        <br />2、锐理致力使跟帖评论成为文明、理性、友善、高质量的意见交流。在推动跟帖评论业务发展的同时，不断加强相应的信息安全管理能力，完善跟帖评论自律，切实履行社会责任，遵守国家法律法规，尊重公民合法权益，尊重社会公序良俗。
        <br />
        <br />3、用户应当以真实身份信息注册账号、使用跟帖评论服务。
        <br />
        <br />4、锐理承诺、并诚请所有用户，使用跟帖评论服务将自觉遵守不得逾越法律法规、社会主义制度、国家利益、公民合法权益、社会公共秩序、道德风尚和信息真实性等“七条底线”。
        <br />
        <br />5、用户不得发表下列信息：
        <br />
        <br />（1）反对宪法确定的基本原则的；
        <br />
        <br />（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
        <br />
        <br />（3）损害国家荣誉和利益的；
        <br />
        <br />（4）煽动民族仇恨、民族歧视，破坏民族团结的；
        <br />
        <br />（5）煽动地域歧视、地域仇恨的；
        <br />
        <br />（6）破坏国家宗教政策，宣扬邪教和迷信的；
        <br />
        <br />（7）散布谣言，扰乱社会秩序、破坏社会稳定的；
        <br />
        <br />（8）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
        <br />
        <br />（9）侮辱或者诽谤他人，侵害他人合法权益的；
        <br />
        <br />（10）对他人进行暴力恐吓、威胁，实施人肉搜索的；
        <br />
        <br />（11）未获得未满18周岁未成年人法定监护人的书面同意，传播该未成年人的隐私信息的；
        <br />
        <br />（12）散布污言秽语，损害社会公序良俗的；
        <br />
        <br />（13）侵犯他人知识产权的；
        <br />
        <br />（14）散布商业广告，或类似的商业招揽信息；
        <br />
        <br />（15）使用本网站常用语言文字以外的其他语言文字评论的；
        <br />
        <br />（16）与所评论的信息毫无关系的；
        <br />
        <br />（17）所发表的信息毫无意义的，或刻意使用字符组合以逃避技术审核的；
        <br />
        <br />（18）法律、法规和规章禁止传播的其他信息。
        <br />
        <br />用户应不时关注并遵守“锐理慧眼”不时公布或修改的各类合法规则规定。
        <br />
        <br />“锐理慧眼”保有删除站内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。
        <br />
        <br />若用户未遵守以上规定的，“锐理慧眼”有权作出独立判断并采取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。
        <br />
        <br />
        <span style="font-size: 16px">所有权及知识产权条款</span>
        <br />
        <br />1、用户一旦接受本协议，即表明该用户主动将其在任何时间段在“锐理慧眼”发表的任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等信息内容）的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给锐理所有，用户同意锐理有权就任何主体侵权而单独提起诉讼。
        <br />
        <br />2、本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照2011年版著作权法确定）及相关法律规定的著作财产权等权利转让书面协议，其效力及于用户在“锐理慧眼”上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。
        <br />
        <br />3、用户同意并已充分了解本协议的条款，承诺不将已发表于“锐理慧眼”的信息，以任何形式发布或授权其它主体以任何方式使用（包括但限于在各类网站、媒体上使用）。
        <br />
        <br />4、锐理是“锐理慧眼”的制作者,拥有此网站内容及资源的著作权等合法权利,受国家法律保护,有权不时地对本协议及“锐理慧眼”的内容进行修改，并在“锐理慧眼”张贴，无须另行通知用户。在法律允许的最大限度范围内，锐理对本协议及“锐理慧眼”内容拥有解释权。
        <br />
        <br />5、除法律另有强制性规定外，未经锐理明确的特别书面许可,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用“锐理慧眼”的信息内容，否则，锐理有权追究其法律责任。
        <br />
        <br />6、若用户发现“锐理慧眼”上存在或间接存在（包括但不限于：链接、摘要说明、告知联系方式等）对您个人或公司造成影响（包括但不限于：名声、财务等），请及时告知我们，我们会第一时间协助处理善后事宜。
        <br />
        <br />
        <span style="font-size: 16px">责任限制及不承诺担保</span>
        <br />
        <br />除非另有明确的书面说明,“锐理慧眼”及其所包含的或以其它方式通过“锐理慧眼”提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在&quot;按现状&quot;和&quot;按现有&quot;的基础上提供的。
        <br />
        <br />除非另有明确的书面说明,锐理不对“锐理慧眼”的运营及其包含在本网站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
        <br />
        <br />锐理不担保“锐理慧眼”所包含的或以其它方式通过“锐理慧眼”提供给您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从“锐理慧眼”发出的电子信件、信息没有病毒或其他有害成分。
        <br />
        <br />如因不可抗力或其它“锐理慧眼”无法控制的原因使“锐理慧眼”销售系统崩溃或无法正常使用或丢失有关的信息、记录等，锐理会合理地尽力协助处理善后事宜。
        <br />
        <br />
        <span style="font-size: 16px">协议更新及用户关注义务</span>
        <br />
        <br />根据国家法律法规变化及网站运营需要，锐理有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在“锐理慧眼”上即生效，并代替原来的协议。用户可随时登录查阅最新协议；用户有义务不时关注并阅读最新版的协议及网站公告。如用户不同意更新后的协议，可以且应立即停止接受“锐理慧眼”依据本协议提供的服务；如用户继续使用本网站提供的服务的，即视为同意更新后的协议。锐理建议您在使用“锐理慧眼”之前阅读本协议及“锐理慧眼”的公告。如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
        <br />
        <br />
        <span style="font-size: 16px">法律管辖和适用</span>
        <br />
        <br />本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有管辖权的中华人民共和国大陆地区法院提起诉讼。
        <br />
        <br />
        <span style="font-size: 16px">其他</span>
        <br />
        <br />1、“锐理慧眼”所有者是指在政府部门依法许可或备案的“锐理慧眼”经营主体。
        <br />
        <br />2、锐理尊重用户和消费者的合法权利，本协议及本网站上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为用户和消费者提供服务。“锐理慧眼”欢迎用户和社会各界提出意见和建议，锐理将虚心接受并适时修改本协议及“锐理慧眼”上的各类规则。
        <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    document.title = "用户协议";
  },
};
</script>

<style lang="scss" scoped>
.agreement {
  width: 100%;
  background: #0f0240;
  display: flex;
  flex-direction: column;
  padding: 25px 15px 24px 15px;
  .agreement-text {
    padding: 25px 10px 0 10px;
    background: rgba(23, 69, 115, 0.36);
  }
}

.textA {
  color: #c2cbff;
}
</style>