<template>
  <div class="cantainer">
    <div class="threeTitle">
      <div class="titleL"></div>
      城市政策
      <div class="titleR"></div>
    </div>
    <div class="policyBox">
      <div
        class="policy"
        v-for="(item, index) in this.cantainerArr"
        :key="index"
      >
        <span class="tl"></span>
        <span class="tr"></span>
        <span class="br"></span>
        <span class="bl"></span>
        <span class="policyTitle"> {{ item.description }} </span>
        <div class="content" v-for="(i, j) in item.value" :key="j">
          <div class="policyTwoTitle">
            <div class="yuan"></div>
            {{ i.title }}
          </div>
          <div class="policycantainer">
            {{ i.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post } from "../config/request";
export default {
  data() {
    return {
      id: 0,
      cantainerArr: [],
    };
  },
  mounted() {
    this.id = window.GetAreaInfo("id");
    this.getResource();
  },
  methods: {
    // 获取城市政策
    getResource() {
      var that = this;
      post("content/policy", {
        analysisId: that.id,
      }).then((res) => {
        that.cantainerArr = res.data.map((i) => {
          i.data["description"] = i.description;
          return i.data;
        });
        that.cantainerArr.map((item) => {
          item.value = JSON.parse(item.value);
        });
        console.log(that.cantainerArr);
        setTimeout((e) => {
          document.getElementById("policyBox").style.height = "auto";
        }, 500);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.cantainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #0f0240;
  overflow-y: auto;
  align-items: center;
  .policyBox {
    width: 100%;
    padding: 0 15px;
    .policy {
      width: 100%;
      padding: 20px 15px;
      background: #152b5a;
      border: 1px dashed rgba(41, 182, 180, 1);
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
      .policyTitle {
        min-width: 20px;
        padding: 5px 15px;
        color: #fff;
        font-size: 18px;
        background: #228896;
        border-radius: 4px;
        margin-bottom: 20px;
      }
      .content {
        width: 100%;
        .policyTwoTitle {
          width: 100%;
          font-size: 12px;
          color: #35b0d3;
          display: flex;
          align-items: center;
          .yuan {
            width: 12px;
            height: 12px;
            border: 2px solid #35b0d3;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
        .policycantainer {
          width: 100%;
          color: #fff;
          font-size: 12px;
          margin-top: 10px;
          padding-left: 22px;
          margin-bottom: 20px;
          line-height: 24px;
        }
      }

      .tl,
      .tr,
      .br,
      .bl {
        width: 10px;
        height: 10px;
        position: absolute;
      }
      .tl {
        top: -1px;
        left: -1px;
        border-left: 2px solid rgba(41, 182, 180, 1);
        border-top: 2px solid rgba(41, 182, 180, 1);
      }
      .tr {
        top: -1px;
        right: -1px;
        border-right: 2px solid rgba(41, 182, 180, 1);
        border-top: 2px solid rgba(41, 182, 180, 1);
      }
      .br {
        bottom: -1px;
        right: -1px;
        border-right: 2px solid rgba(41, 182, 180, 1);
        border-bottom: 2px solid rgba(41, 182, 180, 1);
      }
      .bl {
        bottom: -1px;
        left: -1px;
        border-left: 2px solid rgba(41, 182, 180, 1);
        border-bottom: 2px solid rgba(41, 182, 180, 1);
      }
    }
  }
}
.threeTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 18px;
  margin: 20px 0;
  padding: 0 25px;
  .titleL,
  .titleR {
    flex: 1;
    height: 3px;
  }
  .titleL {
    background: linear-gradient(
      90deg,
      rgba(110, 98, 228, 0),
      rgba(110, 98, 228, 1)
    );
    margin-right: 10px;
  }
  .titleR {
    background: linear-gradient(
      90deg,
      rgba(110, 98, 228, 1),
      rgba(110, 98, 228, 0)
    );
    margin-left: 10px;
  }
}
</style>