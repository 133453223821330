<template>
  <div class="userAnalysisBox">
    <div class="header">
      <div class="inputBox">
        <input
          @change="inputChange"
          type="text"
          v-model.trim="inputValue"
          placeholder="请输入文档关键字"
        />
        <img src="../assets/document/search.png" />
      </div>
    </div>
    <div class="main">
      <div name="contentBox" v-for="(item, index) in list" :key="index">
        <div class="infoBox" @click="goRepuest(item.analysisId)">
          <div class="dateBox f12">{{ item.analysisTime }}</div>
          <div class="title f14 cFFF">{{ item.addr }}</div>
        </div>
        <div class="btnBox f16 cFFF">></div>
        <div class="deleteBtnBox" @click="deleteContent(item.analysisId)">
          <div class="deleteBtn f16 cFFF">删除</div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { post } from "../config/request";
export default {
  data() {
    return {
      inputValue: "",
      list: [],
    };
  },
  created() {
    document.title = "分析历史";
    this.getList();
  },
  mounted() {
    window.pageEvent.on("updateUserInfo", (data) => {
      location.reload();
    });
  },
  methods: {
    async getList() {
      let res = await post("content/analysis_history", {
        addr: this.inputValue,
      });
      this.list = res.data;
      console.log(res);
      setTimeout(() => {
        this.touchs();
      }, 1000);
    },
    inputChange() {
      this.getList();
    },
    goRepuest(e) {
      window.RedirectTo("catalogue", { id: e });
    },
    deleteContent(e) {
      post("content/delete_analysis", { analysisId: e }).then((res) => {
        if (res.code == 0) {
          this.list.some((item, i) => {
            if (item.analysisId == e) {
              this.list.splice(i, 1);
              return true;
            }
          });
          var container = document.getElementsByName("contentBox");
          for (var i = 0; i < container.length; i++) {
            container[i].className = "";
          }
          window.ShowMsg("删除成功！", "success");
          window.pageEvent.send("updateUserInfo", "");
        } else {
          window.ShowMsg("删除失败，请检查网络或稍后再试！", "success");
        }
      });
    },
    // 侧滑事件
    touchs() {
      //侧滑显示删除按钮
      var expansion = null; //是否存在展开的list
      var container = document.getElementsByName("contentBox");
      for (var i = 0; i < container.length; i++) {
        var x, y, X, Y, swipeX, swipeY;
        container[i].addEventListener("touchstart", function (event) {
          x = event.changedTouches[0].pageX;
          y = event.changedTouches[0].pageY;
          swipeX = true;
          swipeY = true;
        });
        container[i].addEventListener("touchmove", function (event) {
          X = event.changedTouches[0].pageX;
          Y = event.changedTouches[0].pageY;
          if (expansion) {
            //判断是否展开，如果展开则收起
            expansion.className = "";
          }
          // 左右滑动
          if (swipeX && Math.abs(X - x) - Math.abs(Y - y) > 0) {
            // 阻止事件冒泡
            event.stopPropagation();
            if (X - x > 20) {
              //右滑
              event.preventDefault();
              this.className = ""; //右滑收起
            }
            if (x - X > 20) {
              //左滑
              event.preventDefault();
              this.className = "swipeleft"; //左滑展开
              expansion = this;
            }
            swipeY = false;
          }
          // 上下滑动
          if (swipeY && Math.abs(X - x) - Math.abs(Y - y) < 20) {
            swipeX = false;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.userAnalysisBox {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: #15162e;
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    .inputBox {
      position: relative;
      input {
        width: 100%;
        height: 40px;
        background: #1d2341;
        border-radius: 4px;
        outline: none;
        border: 1px solid rgba(68, 102, 142, 1);
        color: rgba(154, 161, 195, 1);
        font-size: 12px;
        text-indent: 44px;
      }
      input::placeholder {
        color: rgba(154, 161, 195, 1);
      }
      img {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 13px;
        left: 19px;
      }
    }
  }
  .main {
    background: #15162e;
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    [name="contentBox"] {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      position: relative;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      .infoBox {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: 30px;
        .title {
          display: block;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          margin-top: 15px;
        }
        .dateBox {
          color: #c2cbff;
          .overdueT,
          .overdueF {
            padding: 0px 7px;
            color: #0f0240;
            border-radius: 4px;
            margin-right: 10px;
          }
          .overdueT {
            background: #c2cbff;
          }
          .overdueF {
            background: #6967a0;
          }
        }
      }
      .deleteBtnBox {
        height: 100%;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(44, 51, 179, 0.7);
        border-radius: 4px;
        position: absolute;
        top: 0;
        right: -75px;
      }
    }
    .swipeleft {
      transform: translate3d(-60px, 0, 0);
      .deleteBtnBox {
        right: -60px;
      }
    }
  }
}
</style>